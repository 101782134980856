<template>
  <div>
    <v-card>
      <v-card-title>
        Bom
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Nama"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{}">
            <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">BOM</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" lazy-validation class="pl-4 pr-4">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Material BOM"
                        required
                        :rules="currentData.materialBomRules"
                        v-model="currentData.material_bom_nama"
                        readonly
                        @click="openMaterialModal"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Consumption"
                        required
                        v-model="currentData.consumption"
                        :rules="currentData.consumptionRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        label="UOM"
                        clearable
                        dense
                        outlined
                        v-model="currentData.selectedUom"
                        :items="items_uom"
                        item-text="code"
                        :rules="currentData.uomRules"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
    />

    <MaterialSelectComponent
      @pilihMaterial="pilihMaterialTarget"
      ref="modalMaterialTarget"
      v-model="dialogMaterialTarget"
    />
  </div>
</template>

<script>
import BomService from "../services/BomService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import MaterialSelectComponent from "../components/MaterialSelect.vue";

export default {
  props: {
    id: String,
  },
  components: { MaterialSelectComponent },
  data: () => ({
    dialogMaterial: false,
    dialogMaterialTarget: false,
    breadcrumbs: [
      {
        text: "Data Bom",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      {
        text: "Kode Material Bom",
        value: "kode_material_bom",
        sortable: false,
      },
      {
        text: "Material Bom",
        value: "material_bom_nama",
        sortable: false,
      },
      {
        text: "Consumption",
        value: "consumption",
        sortable: false,
      },
      {
        text: "Unit",
        value: "uom",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      code: "",
      value: "",
      material_bom: 0,
      material_bom_nama: "",
      material_target: 0,
      material_target_nama: "",
      versi: "",
      consumption: 0,
      uom: "",
      selectedUom: "",
      materialBomRules: [(v) => !!v || "Material BOM harus diisi"],
      materialTargetRules: [(v) => !!v || "Material Target harus diisi"],
      versiRules: [(v) => !!v || "Versi harus diisi"],
      consumptionRules: [(v) => !!v || "Consumption harus diisi"],
      uomRules: [(v) => !!v || "UOM harus diisi"],
    },
    items_uom: [],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getUom();
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      BomService.bomList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getUom() {
      MasterService.uomList()
        .then((res) => {
          this.items_uom = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.code = "";
      this.currentData.value = "";
      this.currentData.id = null;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });
        // console.log(this.$refs.myModal.scrollTop);
        return;
      }

      this.currentData.wf_id = this.id;
      if (this.currentData.id == null) {
        BomService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah BOM berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah BOM gagal. Coba lagi");
          });
      } else {
        BomService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update BOM berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update BOM gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.versi = this.editedItem.versi;
      this.currentData.consumption = this.editedItem.consumption;
      this.currentData.material_bom = this.editedItem.material_bom;
      this.currentData.material_bom_nama = this.editedItem.material_bom_nama;
      this.currentData.material_target = this.editedItem.material_target;
      this.currentData.material_target_nama =
        this.editedItem.material_target_nama;

      if (this.editedItem.uom) {
        this.items_uom.forEach((entry) => {
          if (entry.code == this.editedItem.uom) {
            this.currentData.selectedUom = entry;
          }
        });
      }

      this.currentData.id = this.editedItem.id;
      console.log("do add");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      BomService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus BOM berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus BOM gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openMaterialModal: async function () {
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    openMaterialTargetModal: async function () {
      this.$refs.modalMaterialTarget.getDataFromApi();
      this.dialogMaterialTarget = true;
    },

    pilihMaterial: async function (item) {
      this.currentData.material_bom = item.id;
      this.currentData.material_bom_nama = item.nama;
    },

    pilihMaterialTarget: async function (item) {
      console.log(item);
      this.currentData.material_target = item.id;
      this.currentData.material_target_nama = item.nama;
    },
  },
};
</script>
