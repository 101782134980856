import http from "../http-common";
/* eslint-disable */
class WfProductionService {
  wfProductionList(page, itemPerPage, keyword) {
    return http.get(
      "/wfproduction/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  wfProductionListByKomoditiId(page, itemPerPage, keyword, komoditiId) {
    return http.get(
      "/wfproduction-bykomoditi/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&komoditi_id=" +
        komoditiId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getWfProduction(id) {
    return http.get(`/wfproduction/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  productionOrderItemProsesList(wfId) {
    return http.get("/wfproduction-proses-master/list?wf_id=" + wfId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, proses) {
    var bodyFormData = new FormData();

    // if (data.selectedComodityType && data.selectedComodityType != undefined) {
    //   bodyFormData.append("char_comodity_type", data.selectedComodityType.code);
    // }

    if (data.selectedUom && data.selectedUom != undefined) {
      bodyFormData.append("target_uom", data.selectedUom.code);
    }

    bodyFormData.append("komoditi_id", data.komoditi_id);
    bodyFormData.append("nama_wf", data.nama_wf);
    bodyFormData.append("target_qty", data.target_qty);

    bodyFormData.append("proses", JSON.stringify(proses));

    return http.post("/wfproduction", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, proses) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    // if (data.selectedComodityType && data.selectedComodityType != undefined) {
    //   bodyFormData.append("char_comodity_type", data.selectedComodityType.code);
    // }

    if (data.selectedUom && data.selectedUom != undefined) {
      bodyFormData.append("target_uom", data.selectedUom.code);
    }

    bodyFormData.append("komoditi_id", data.komoditi_id);
    bodyFormData.append("nama_wf", data.nama_wf);
    bodyFormData.append("target_qty", data.target_qty);

    bodyFormData.append("proses", JSON.stringify(proses));

    return http.put(`/wfproduction/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/wfproduction/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new WfProductionService();
