import http from "../http-common";
/* eslint-disable */
class MaterialService {
  materialList(page, itemPerPage, keyword) {
    return http.get(
      "/material/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  materialWorkflowOnlyList(page, itemPerPage, keyword) {
    return http.get(
      "/material-workflow-only/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  materialFgdList(page, itemPerPage, keyword) {
    return http.get(
      "/material-fgd/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  materialCfruList(page, itemPerPage, keyword) {
    return http.get(
      "/material-cfru/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  materialCfrcList(page, itemPerPage, keyword, id) {
    return http.get(
      "/material-cfrc/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&komoditi_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  materialServiceList(page, itemPerPage, keyword) {
    return http.get(
      "/material-service/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  materialGenList(page, itemPerPage, keyword) {
    return http.get(
      "/material-gen/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  materialGenericList() {
    return http.get("/material-generic/list", {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  materialSOList(page, itemPerPage, keyword, salesOrderId) {
    return http.get(
      "/materialso/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&sales_order_id=" +
        salesOrderId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getMaterialService() {
    return http.get(`/material-service`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("kode", data.kode);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("char_comodity_type", data.char_comodity_type);
    bodyFormData.append("char_comodity_subty", data.char_comodity_subty);
    bodyFormData.append("char_origin", data.char_origin);
    bodyFormData.append("char_variety", data.char_variety);
    bodyFormData.append("char_phase", data.char_phase);
    bodyFormData.append("char_process", data.char_process);
    bodyFormData.append("grade", data.grade);

    if (data.selling_price)
      bodyFormData.append("selling_price", data.selling_price);

    if (data.production_price)
      bodyFormData.append("production_price", data.production_price);

    if (data.selectedMaterialType && data.selectedMaterialType != undefined) {
      bodyFormData.append("material_type", data.selectedMaterialType.code);
    }

    if (data.selectedUom && data.selectedUom != undefined) {
      bodyFormData.append("uom", data.selectedUom.code);
    }

    if (data.selectedGroupMaterial && data.selectedGroupMaterial != undefined) {
      bodyFormData.append("group_material_id", data.selectedGroupMaterial.id);
    }

    if (
      data.selectedGenericComodity &&
      data.selectedGenericComodity != undefined
    ) {
      bodyFormData.append("generic_comodity", data.selectedGenericComodity.id);
    }

    return http.post("/material", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("kode", data.kode);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("char_comodity_type", data.char_comodity_type);
    bodyFormData.append("char_comodity_subty", data.char_comodity_subty);
    bodyFormData.append("char_origin", data.char_origin);
    bodyFormData.append("char_variety", data.char_variety);
    bodyFormData.append("char_phase", data.char_phase);
    bodyFormData.append("char_process", data.char_process);
    bodyFormData.append("grade", data.grade);
    if (data.selling_price);
    bodyFormData.append("selling_price", data.selling_price);

    if (data.production_price)
      bodyFormData.append("production_price", data.production_price);

    if (data.selectedMaterialType && data.selectedMaterialType != undefined) {
      bodyFormData.append("material_type", data.selectedMaterialType.code);
    }

    if (data.selectedUom && data.selectedUom != undefined) {
      bodyFormData.append("uom", data.selectedUom.code);
    }

    if (data.selectedGroupMaterial && data.selectedGroupMaterial != undefined) {
      bodyFormData.append("group_material_id", data.selectedGroupMaterial.id);
    }

    if (
      data.selectedGenericComodity &&
      data.selectedGenericComodity != undefined
    ) {
      bodyFormData.append("generic_comodity", data.selectedGenericComodity.id);
    }

    return http.put(`/material/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/material/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new MaterialService();
