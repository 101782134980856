import http from "../http-common";
/* eslint-disable */
class PermissionRoleValueService {
  permissionValueList(page, itemPerPage, keyword, roleId, permissionId) {
    return http.get(
      "/permission-role-value/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&role_id=" +
        roleId +
        "&permission_id=" +
        permissionId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(roleId, permissionId, kelompokId) {
    var bodyFormData = new FormData();
    bodyFormData.append("role_id", roleId);
    bodyFormData.append("permission_id", permissionId);
    bodyFormData.append("permission_value", kelompokId);

    return http.post("/permission-role-value", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("role_id", data.role_id);
    bodyFormData.append("permission_id", data.permission_id);
    bodyFormData.append("permission_value", data.kelompok_id);

    return http.put(`/permission-role-value/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  delete(id, permissionId, kelompokId) {
    return http.delete(
      `/permission-role-value/${id}/${permissionId}/${kelompokId}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}

export default new PermissionRoleValueService();
