import http from "../http-common";
/* eslint-disable */
class LoanService {
  loanList(page, itemPerPage, keyword) {
    return http.get(
      "/loan/list?page=" + page + "&item_per_page=" + itemPerPage,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  loanDetailList(page, itemPerPage, keyword, id) {
    return http.get(
      "/loan-detail/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&loan_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("kelompok_id", data.kelompok_id);
    bodyFormData.append("user_id", data.user_id);
    bodyFormData.append("deskripsi", data.deskripsi);

    if (data.selectedBank && data.selectedBank != undefined) {
      bodyFormData.append("bank_id", data.selectedBank.id);
    }

    bodyFormData.append("jumlah_pinjaman", data.jumlah_pinjaman);
    bodyFormData.append("tanggal_mulai", data.tanggal_mulai);
    bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    bodyFormData.append("jangka_waktu", data.jangka_waktu);
    bodyFormData.append("interest_percent", data.interest_percent);
    bodyFormData.append(
      "interest_amount",
      ((data.jangka_waktu / 12) *
        data.jumlah_pinjaman *
        data.interest_percent) /
        100
    );
    bodyFormData.append(
      "total",
      parseInt(data.jumlah_pinjaman) +
        parseInt(
          ((data.jangka_waktu / 12) *
            data.jumlah_pinjaman *
            data.interest_percent) /
            100
        )
    );

    return http.post("/loan", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("kelompok_id", data.kelompok_id);
    bodyFormData.append("user_id", data.user_id);
    bodyFormData.append("deskripsi", data.deskripsi);

    if (data.selectedBank && data.selectedBank != undefined) {
      bodyFormData.append("bank_id", data.selectedBank.id);
    }

    bodyFormData.append("jumlah_pinjaman", data.jumlah_pinjaman);
    bodyFormData.append("tanggal_mulai", data.tanggal_mulai);
    bodyFormData.append("tanggal_akhir", data.tanggal_akhir);
    bodyFormData.append("jangka_waktu", data.jangka_waktu);
    bodyFormData.append("interest_percent", data.interest_percent);
    bodyFormData.append(
      "interest_amount",
      ((data.jangka_waktu / 12) *
        data.jumlah_pinjaman *
        data.interest_percent) /
        100
    );
    bodyFormData.append(
      "total",
      parseInt(data.jumlah_pinjaman) +
        parseInt(
          ((data.jangka_waktu / 12) *
            data.jumlah_pinjaman *
            data.interest_percent) /
            100
        )
    );

    return http.put(`/loan/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/bom/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new LoanService();
