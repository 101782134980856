<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-form ref="form" lazy-validation class="pl-4 pr-4">
        <v-card>
          <v-card-title> Good Issue </v-card-title>

          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-combobox
                label="Transaction Type"
                dense
                outlined
                readonly
                v-model="currentData.selectedTransactionType"
                :items="items_transaction_type"
                item-text="MV_DESC"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="GI No"
                outlined
                dense
                required
                readonly
                value="GENERATED"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-text-field
                label="Doc No"
                outlined
                dense
                required
                readonly
                :value="currentData.pro_no"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="currentData.menuTanggal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggal"
                    label="Tanggal Transaksi"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.tanggal"
                  @input="currentData.menuTanggal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Warehouse"
                outlined
                dense
                required
                v-model="currentData.warehouse_nama"
                @click="openWarehouseModal"
                :rules="currentData.warehouseRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-6">
          <v-card-title>
            <v-spacer></v-spacer>

            <!-- <router-link
            to="/news-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="purple" dark class="mb-2"> Tambah </v-btn>
          </router-link> -->
            <v-dialog v-model="dialogConfirm" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Apa anda akan mengkonfirmasi data ini?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeConfirm"
                    >Tidak</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="submitConfirm"
                    >Ya</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="list_data"
            :options.sync="options"
            :server-items-length="totalData"
            :loading="loading"
            class="elevation-1"
            show-select
            v-model="selectedRows"
            @input="openSelectedRow"
          >
            <template v-slot:[`item.qty`]="{ item }">
              <span>{{ numberWithCommas(item.qty) }}</span>
            </template>
            <template v-slot:[`item.qty_outstanding`]="{ item }">
              <span>{{ numberWithCommas(item.qty_outstanding) }}</span>
            </template>
            <template v-slot:[`item.qty_stock`]="{ item }">
              <v-text-field
                outlined
                dense
                required
                :disabled="!item.is_checked"
                :background-color="item.is_checked ? 'white' : '#ddd'"
                :key="item.id"
                v-model="item.qty_gi"
                :rules="[checkRequired(item)]"
              ></v-text-field>
            </template>
          </v-data-table>
          <v-row class="mt-2 mr-2">
            <v-col cols="6" class="d-flex justify-start"> </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-btn
                class="mr-2"
                dark
                type="button"
                color="grey"
                @click="cancelGoodIssue"
                >Batal</v-btn
              >
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-container>

    <WarehouseSelectComponent
      @pilihWarehouse="pilihWarehouse"
      ref="modalWarehouse"
      v-model="dialogWarehouse"
      :id="currentData.kelompok_id"
    />
  </v-main>
</template>

<script>
import PurchaseOrderService from "../services/PurchaseOrderService";
import ProductionOrderService from "../services/ProductionOrderService";
import GoodIssueService from "../services/GoodIssueService";
import MasterService from "../services/MasterService";

import CommonService from "../services/CommonService";
import * as moment from "moment";

import WarehouseSelectComponent from "../components/WarehouseAllSelect.vue";

export default {
  components: { WarehouseSelectComponent },
  props: {
    id: String,
  },
  data: () => ({
    options_money: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    dialogConfirm: false,
    dialogReject: false,
    dialogWarehouse: false,
    breadcrumbs: [
      {
        text: "Data Good Issue",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    selectedRows: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Material", value: "komoditi_nama", sortable: false },
      { text: "Qty Order", value: "qty_raw", sortable: false },
      { text: "Qty Outstanding", value: "qty_outstanding", sortable: false },
      { text: "Qty Stock", value: "qty_stock", sortable: false },
      { text: "UOM", value: "uom", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      pro_no: "",
      tanggal: "",
      menuTanggal: false,
      kelompok_id: 0,
      warehouse_id: "",
      warehouse_nama: "",
      selectedTransactionType: "",
      warehouseRules: [(v) => !!v || "Warehouse harus diisi"],
    },
    items_transaction_type: [],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getGoodMovement();
    this.getProductionOrder();

    this.currentData.tanggal = moment(new Date()).format("YYYY-MM-DD");
  },

  computed: {
    computedTanggal() {
      return this.currentData.tanggal
        ? moment(this.currentData.tanggal).format("DD MMMM YYYY")
        : "";
    },
  },
  methods: {
    checkRequired(item) {
      if (item.is_checked) {
        if (!item.qty_gi) {
          return "Qty harus diisi";
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    openSelectedRow() {
      console.log(this.selectedRows);

      this.list_data.forEach((entry) => {
        entry.is_checked = false;
      });

      this.selectedRows.forEach((entry) => {
        console.log(entry);
        var result = this.list_data.filter((material) => {
          return material.id == entry.id;
        });

        if (result) result[0].is_checked = true;

        console.log(result);
      });
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },
    getKonten(konten) {
      if (konten.length > 100) {
        return konten.substring(0, 100) + "...";
      } else {
        return konten;
      }
    },

    async getGoodMovement() {
      MasterService.goodMovementList()
        .then((res) => {
          this.items_transaction_type = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi2() {
      this.loading = true;
      ProductionOrderService.getProductionOrder(this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;

          // this.list_data.forEach((entry) => {
          //   entry.is_checked = false;
          //   entry.qty_gi = "";
          // });
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getProductionOrder() {
      ProductionOrderService.getProductionOrder(this.id)
        .then((res) => {
          console.log(res);
          this.currentData.pro_no = res.data.productionorder.pro_no;

          this.items_transaction_type.forEach((entry) => {
            if (entry.MV_ID == "GI02") {
              this.currentData.selectedTransactionType = entry;
            }
          });

          console.log(this.currentData.kelompok_id);

          this.getDataFromApi(this.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi(proId) {
      this.loading = true;
      ProductionOrderService.productionOrderItemSavedList(proId)
        .then((res) => {
          console.log("data ini");
          console.log(res.data.list_data);
          // this.list_data = res.data.list_data;
          // this.totalData = res.data.total;
          // this.loading = false;
          // this.isFirst = true;

          // this.list_data.forEach((entry) => {
          //   entry.is_checked = false;
          //   entry.qty_ok = "";
          //   entry.qty_ng = "";
          // });

          if (res.data.list_data.length > 0) {
            this.getDataRawFromApi(res.data.list_data[0].id);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getDataRawFromApi(itemId) {
      this.loading = true;
      ProductionOrderService.productionOrderItemRawList(itemId)
        .then((res) => {
          this.loading = false;
          this.isFirst = true;

          this.list_data = res.data.list_data;
          this.totalData = res.data.total;

          console.log("data raw");
          console.log(this.list_data);

          this.list_data.forEach((entry) => {
            entry.rules = [(v) => !!v || "Qty harus diisi"];
          });

          console.log(this.list_data);
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/news-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    submitConfirm() {
      this.closeConfirm();

      PurchaseOrderService.confirm(this.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("SO Kelompok berhasil di konfirmasi");

          this.getPurchaseOrder();
          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "SO Kelompok gagal dikonfirmasi. Coba lagi"
          );
        });
    },

    closeConfirm() {
      this.dialogConfirm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openConfirm() {
      this.dialogConfirm = true;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openSOKelompok(item) {
      console.log(item);

      this.$router.push("/sokelompok-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    openDelivery(item) {
      console.log(item);
    },

    pilihWarehouse: async function (item) {
      console.log(item);
      this.currentData.warehouse_id = item.id;
      this.currentData.warehouse_nama = item.nama;
    },

    openWarehouseModal: async function () {
      this.dialogWarehouse = true;

      this.$nextTick(() => {
        this.$refs.modalWarehouse.getDataFromApi();
      });
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      this.currentData.production_order_id = this.id;

      //build materials
      var materials = [];
      var isErrorOutstanding = false;
      var currentEntry;
      var BreakException = {};
      var isMaterialExists = 0;

      try {
        this.list_data.forEach((entry) => {
          if (entry.is_checked) {
            if (entry.qty_outstanding < entry.qty_gi) {
              isErrorOutstanding = true;
              currentEntry = entry;
              throw BreakException;
            }

            isMaterialExists++;
            materials.push({
              komoditi_id: entry.komoditi_id,
              so_item_id: entry.so_item_id,
              qty: entry.qty_gi,
              qty_order: entry.qty_raw,
            });
          }
        });
      } catch (e) {
        if (e !== BreakException) {
          console.log(e);
        }
      }

      if (isErrorOutstanding) {
        console.log(currentEntry);
        CommonService.showErrorToast(
          "Material " + currentEntry.komoditi_nama + " melebihi outstanding"
        );
        return;
      }

      if (isMaterialExists == 0) {
        CommonService.showErrorToast("Pilih material untuk menyimpan");
        return;
      }

      GoodIssueService.createProduction(this.currentData, materials)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Good Issue berhasil");

          this.$router.push("/productionorder-edit/" + this.id).catch((err) => {
            console.log(err);
          });
        })
        .catch((err) => {
          console.log("error ke sini");
          console.log(err.response.data.message);
          if (err.response.data.message) {
            CommonService.showErrorToast(err.response.data.message);
          } else {
            CommonService.showErrorToast("Tambah Good Issue gagal. Coba lagi");
          }
        });
    },

    cancelGoodIssue() {
      this.$router.go(-1);
    },

    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        return "";
      }
    },
  },
};
</script>
