import http from "../http-common";
/* eslint-disable */
class QcProcessService {
  goodReceiveList(page, itemPerPage, keyword) {
    return http.get(
      "/goodreceive/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  qcProcessItemList(id, kegiatamItemId) {
    return http.get(
      "/qcprocess-item/list?id=" + id + "&kegiatan_item_id=" + kegiatamItemId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getQcProcess(id) {
    return http.get(`/qcprocess/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  getKegiatanWithQc(id) {
    return http.get(`/kegiatan-qc/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, materials) {
    var bodyFormData = new FormData();

    if (data.selectedDocType && data.selectedDocType != undefined) {
      bodyFormData.append("doc_type", data.selectedDocType.code);
    }

    bodyFormData.append("qty_receive", data.qty_receive);
    bodyFormData.append("kegiatan_id", data.kegiatan_id);
    bodyFormData.append("komoditi_id", data.komoditi_id);
    bodyFormData.append("warehouse_id", data.warehouse_id);
    bodyFormData.append("tanggal", data.tanggal);

    if (
      data.selectedTransactionType &&
      data.selectedTransactionType != undefined
    ) {
      bodyFormData.append(
        "transaction_type",
        data.selectedTransactionType.MV_ID
      );
    }

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.post("/qcprocess", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(data, materials) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", data.qc_id);

    if (data.selectedDocType && data.selectedDocType != undefined) {
      bodyFormData.append("doc_type", data.selectedDocType.code);
    }

    bodyFormData.append("qty_receive", data.qty_receive);
    bodyFormData.append("kegiatan_id", data.kegiatan_id);
    bodyFormData.append("komoditi_id", data.komoditi_id);
    bodyFormData.append("warehouse_id", data.warehouse_id);
    bodyFormData.append("tanggal", data.tanggal);

    if (
      data.selectedTransactionType &&
      data.selectedTransactionType != undefined
    ) {
      bodyFormData.append(
        "transaction_type",
        data.selectedTransactionType.MV_ID
      );
    }

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.put(`/qcprocess/${data.qc_id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  createPo(data, materials) {
    var bodyFormData = new FormData();

    if (data.selectedDocType && data.selectedDocType != undefined) {
      bodyFormData.append("doc_type", data.selectedDocType.code);
    }

    bodyFormData.append("qty_receive", data.qty_receive);
    bodyFormData.append("kegiatan_id", data.kegiatan_id);
    bodyFormData.append("warehouse_id", data.warehouse_id);
    bodyFormData.append("tanggal", data.tanggal);

    if (
      data.selectedTransactionType &&
      data.selectedTransactionType != undefined
    ) {
      bodyFormData.append(
        "transaction_type",
        data.selectedTransactionType.MV_ID
      );
    }

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.post("/qcprocess-po", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/qcprocess/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new QcProcessService();
