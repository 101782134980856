<template>
  <div>
    <v-card>
      <v-card-title>
        Warehouse Item
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Nama"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{}">
            <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Warehouse Item</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Material"
                      outlined
                      dense
                      required
                      v-model="currentData.komoditi_nama"
                      readonly
                      @click="openMaterialModal()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Qty"
                      outlined
                      dense
                      v-model="currentData.qty"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
        :single-select="true"
        show-select
        v-model="selectedRows"
        @input="openSelectedRow"
      >
        <template v-slot:[`item.qty`]="{ item }">
          <span v-if="item.qty">{{ numberWithCommas(item.qty) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-card class="mt-4">
      <BatchItemComponent
        v-if="selectedRows != undefined && selectedRows.length > 0"
        :id="selectedRows[0].id"
        ref="mychild"
        :key="childKey"
      />
    </v-card>
    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
    />
  </div>
</template>

<script>
import WarehouseItemService from "../services/WarehouseItemService";
import CommonService from "../services/CommonService";

import MaterialSelectComponent from "../components/MaterialSelect.vue";

import BatchItemComponent from "./BatchItem.vue";

export default {
  props: {
    id: Number,
  },
  components: { MaterialSelectComponent, BatchItemComponent },
  data: () => ({
    childKey: 0,
    selectedRows: [],
    dialogUser: false,
    dialogMaterial: false,
    breadcrumbs: [
      {
        text: "Data Warehouse Item",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Material", value: "komoditi_nama", sortable: false },
      { text: "Qty", value: "qty", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      komoditi_id: "",
      komoditi_nama: "",
      qty: "",
      warehouse_id: 0,
    },
  }),
  watch: {
    selectedRows: {
      handler() {
        if (this.selectedRows != undefined && this.selectedRows[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    openSelectedRow() {
      console.log(this.selectedRows[0]);
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      WarehouseItemService.warehouseItemList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.komoditi_id = "";
      this.currentData.komoditi_nama = "";
      this.currentData.qty = "";
      this.currentData.id = null;
    },

    doSave: async function () {
      this.currentData.warehouse_id = this.id;
      if (this.currentData.id == null) {
        WarehouseItemService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah warehouse item berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah warehouse item gagal. Coba lagi"
            );
          });
      } else {
        WarehouseItemService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update warehouse item berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update warehouse item gagal. Coba lagi"
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.komoditi_id = this.editedItem.komoditi_id;
      this.currentData.komoditi_nama = this.editedItem.komoditi_nama;
      this.currentData.qty = this.editedItem.qty;

      this.currentData.id = this.editedItem.id;
      console.log("do add");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      WarehouseItemService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus warehouse item berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus warehouse item gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openMaterialModal: async function () {
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    pilihMaterial: async function (item) {
      console.log(item);
      this.currentData.komoditi_id = item.id;
      this.currentData.komoditi_nama = item.nama;
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
