import http from "../http-common";
/* eslint-disable */
class GoodReceiveService {
  goodReceiveList(page, itemPerPage, keyword) {
    return http.get(
      "/goodreceive/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  poAnggotaToGoodReceiveList(kegiatanId) {
    return http.get(
      "/poanggota-to-goodreceive/list?kegiatan_id=" + kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  prodOrderToGoodReceiveList(kegiatanId) {
    return http.get(
      "/prodorder-to-goodreceive/list?production_order_id=" + kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  goodReceiveItemList(id) {
    return http.get("/goodreceive-item/list?id=" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  getGoodReceive(id) {
    return http.get(`/goodreceive/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, materials) {
    var bodyFormData = new FormData();

    if (data.selectedDocType && data.selectedDocType != undefined) {
      bodyFormData.append("doc_type", data.selectedDocType.code);
    }

    if (data.kegiatan_id) bodyFormData.append("kegiatan_id", data.kegiatan_id);
    if (data.production_order_id)
      bodyFormData.append("production_order_id", data.production_order_id);
    bodyFormData.append("warehouse_id", data.warehouse_id);
    bodyFormData.append("tanggal", data.tanggal);

    if (
      data.selectedTransactionType &&
      data.selectedTransactionType != undefined
    ) {
      bodyFormData.append(
        "transaction_type",
        data.selectedTransactionType.MV_ID
      );
    }

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.post("/goodreceive", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data, materials) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    if (data.selectedDocType && data.selectedDocType != undefined) {
      bodyFormData.append("doc_type", data.selectedDocType.code);
    }

    bodyFormData.append("kegiatan_id", data.kegiatan_id);
    bodyFormData.append("warehouse_id", data.warehouse_id);
    bodyFormData.append("tanggal", data.tanggal);

    if (
      data.selectedTransactionType &&
      data.selectedTransactionType != undefined
    ) {
      bodyFormData.append(
        "transaction_type",
        data.selectedTransactionType.MV_ID
      );
    }

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.put(`/goodreceive/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/goodreceive/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new GoodReceiveService();
