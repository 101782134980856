<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Pembayaran
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Pinjaman</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" lazy-validation class="pl-4 pr-4">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Kelompok"
                          required
                          :rules="currentData.kelompokRules"
                          v-model="currentData.kelompok_nama"
                          readonly
                          @click="openKelompokModal"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Anggota"
                          required
                          :rules="currentData.anggotaRules"
                          v-model="currentData.user_nama"
                          readonly
                          @click="openUserModal"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Deskripsi"
                          dense
                          value=""
                          rows="3"
                          v-model="currentData.deskripsi"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          label="Bank"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedBank"
                          :items="items_bank"
                          item-text="nama"
                          :rules="currentData.bankRules"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12">
                        <vuetify-money
                          required
                          v-model="currentData.jumlah_pinjaman"
                          :options="options_money"
                          label="Pokok Pinjaman"
                          :rules="currentData.jumlahPinjamanRules"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-menu
                          v-model="currentData.menuTanggal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          dense
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="computedTanggal"
                              label="Tanggal Mulai"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              :rules="currentData.tanggalMulaiRules"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currentData.tanggal_mulai"
                            @input="currentData.menuTanggal = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-menu
                          v-model="currentData.menuTanggalAkhir"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          dense
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="computedTanggalAkhir"
                              label="Tanggal Akhir"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              :rules="currentData.tanggalAkhirRules"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currentData.tanggal_akhir"
                            @input="currentData.menuTanggalAkhir = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Jangka Waktu (Bulan)"
                          required
                          :rules="currentData.jangkaWaktuRules"
                          v-model="currentData.jangka_waktu"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Bunga (%)"
                          required
                          :rules="currentData.bungaPersenRules"
                          v-model="currentData.interest_percent"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <vuetify-money
                          required
                          :options="options_money"
                          label="Bunga"
                          :value="computedBunga"
                          readonly
                        />
                      </v-col>
                      <v-col cols="12">
                        <vuetify-money
                          required
                          :options="options_money"
                          label="Total"
                          :value="computedTotal"
                          readonly
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          :single-select="true"
          show-select
          v-model="selectedRows"
          @input="openSelectedRow"
        >
          <template v-slot:[`item.payment_date`]="{ item }">
            {{ getCurrentDate(item.payment_date) }}
          </template>
          <template v-slot:[`item.tanggal_mulai`]="{ item }">
            {{ getCurrentDate(item.tanggal_mulai) }}
          </template>
          <template v-slot:[`item.tanggal_akhir`]="{ item }">
            {{ getCurrentDate(item.tanggal_akhir) }}
          </template>
          <template v-slot:[`item.jumlah_pinjaman`]="{ item }">
            <span v-if="item.jumlah_pinjaman">{{
              numberWithCommas(item.jumlah_pinjaman)
            }}</span>
          </template>
          <template v-slot:[`item.sudah_dibayar`]="{ item }">
            <span v-if="item.sudah_dibayar">{{
              numberWithCommas(item.sudah_dibayar)
            }}</span>
          </template>
          <template v-slot:[`item.sisa_pembayaran`]="{ item }">
            <span v-if="item.sisa_pembayaran">{{
              numberWithCommas(item.sisa_pembayaran)
            }}</span>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            <span v-if="item.total">{{ numberWithCommas(item.total) }}</span>
          </template>
          <template v-slot:[`item.interest_amount`]="{ item }">
            <span v-if="item.total">{{
              numberWithCommas(item.interest_amount)
            }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="mt-4">
        <DetailPinjamanComponent
          v-if="selectedRows != undefined && selectedRows.length > 0"
          :id="selectedRows[0].id"
          ref="mychild"
          :key="childKey"
        />
      </v-card>

      <KelompokSelectComponent
        @pilihKelompok="pilihKelompok"
        ref="modalKelompok"
        v-model="dialogKelompok"
      />

      <UserSelectByKelompokParamComponent
        @pilihUser="pilihUser"
        ref="modalUser"
        v-model="dialogUser"
        :id="currentData.kelompok_id"
      />

      <MaterialSelectComponent
        @pilihMaterial="pilihMaterialTarget"
        ref="modalMaterialTarget"
        v-model="dialogMaterialTarget"
      />
    </v-container>
  </v-main>
</template>

<script>
import PaymentService from "../services/PaymentService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import MaterialSelectComponent from "../components/MaterialSelect.vue";
import UserSelectByKelompokParamComponent from "../components/UserSelectByKelompokParam.vue";
import KelompokSelectComponent from "../components/KelompokSelect.vue";
import moment from "moment";
import DetailPinjamanComponent from "./DetailPinjaman.vue";

export default {
  components: {
    MaterialSelectComponent,
    KelompokSelectComponent,
    UserSelectByKelompokParamComponent,
    DetailPinjamanComponent,
  },
  data: () => ({
    dialogMaterial: false,
    dialogMaterialTarget: false,
    dialogKelompok: false,
    dialogUser: false,
    childKey: 0,
    selectedRows: [],
    options_money: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    breadcrumbs: [
      {
        text: "Data Pinjaman",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      {
        text: "Document AP",
        value: "ap_doc",
        sortable: false,
      },
      {
        text: "Tanggal Document AP",
        value: "payment_date",
        sortable: false,
      },
      { text: "No Invoice Customer", value: "deskripsi", sortable: false },
      {
        text: "Vendor",
        value: "vendor_nama",
        sortable: false,
      },
      {
        text: "No PO",
        value: "po_no",
        sortable: false,
      },
      {
        text: "Tipe PO",
        value: "po_type",
        sortable: false,
      },
      {
        text: "Total",
        value: "total",
        sortable: false,
      },
      {
        text: "Tanggal Jatuh Tempo",
        value: "tanggal_jatuh_tempo",
        sortable: false,
      },
      {
        text: "Nomor Dokumen Pembayaran",
        value: "payment_doc",
        sortable: false,
      },
      {
        text: "Payment Method",
        value: "payment_method_value",
        sortable: false,
      },
      {
        text: "Status",
        value: "status_value",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      code: "",
      value: "",
      kelompok_id: -1,
      kelompok_nama: "",
      user_id: "",
      user_nama: "",
      deskripsi: "",
      material_bom: 0,
      material_bom_nama: "",
      material_target: 0,
      material_target_nama: "",
      versi: "",
      consumption: 0,
      uom: "",
      selectedUom: "",
      selectedBank: "",
      jumlah_pinjaman: "",
      menuTanggal: false,
      tanggal_mulai: "",
      menuTanggalAkhir: false,
      tanggal_akhir: "",
      jangka_waktu: "",
      interest_percent: "",
      interest_amount: "",
      total: "",
      materialBomRules: [(v) => !!v || "Material BOM harus diisi"],
      materialTargetRules: [(v) => !!v || "Material Target harus diisi"],
      versiRules: [(v) => !!v || "Versi harus diisi"],
      consumptionRules: [(v) => !!v || "Consumption harus diisi"],
      uomRules: [(v) => !!v || "UOM harus diisi"],
      kelompokRules: [(v) => !!v || "Kelompok harus diisi"],
      anggotaRules: [(v) => !!v || "Anggota harus diisi"],
      bankRules: [(v) => !!v || "Bank harus diisi"],
      jumlahPinjamanRules: [(v) => !!v || "Pokok Pinjaman harus diisi"],
      tanggalMulaiRules: [(v) => !!v || "Tanggal Mulai harus diisi"],
      tanggalAkhirRules: [(v) => !!v || "Tanggal Akhir harus diisi"],
      jangkaWaktuRules: [(v) => !!v || "Jangka Waktu harus diisi"],
      bungaPersenRules: [(v) => !!v || "Persentase Bunga harus diisi"],
    },
    items_uom: [],
    items_bank: [],
  }),
  watch: {
    options: {
      selectedRows: {
        handler() {
          if (this.selectedRows != undefined && this.selectedRows[0]) {
            this.childKey++;
          }
        },
        deep: true,
      },
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getUom();
    this.getBank();
    this.getDataFromApi();
  },
  computed: {
    computedTanggal() {
      return this.currentData.tanggal_mulai
        ? moment(this.currentData.tanggal_mulai).format("DD MMMM YYYY")
        : "";
    },
    computedTanggalAkhir() {
      return this.currentData.tanggal_akhir
        ? moment(this.currentData.tanggal_akhir).format("DD MMMM YYYY")
        : "";
    },
    computedBunga() {
      if (
        this.currentData.jangka_waktu &&
        this.currentData.jumlah_pinjaman &&
        this.currentData.interest_percent
      ) {
        return (
          ((this.currentData.jangka_waktu / 12) *
            this.currentData.jumlah_pinjaman *
            this.currentData.interest_percent) /
          100
        );
      } else {
        return "";
      }
    },
    computedTotal() {
      if (this.currentData.jumlah_pinjaman && this.computedBunga) {
        return (
          parseInt(this.currentData.jumlah_pinjaman) +
          parseInt(this.computedBunga)
        );
      } else {
        return "";
      }
    },
  },
  methods: {
    openSelectedRow() {
      console.log(this.selectedRows[0]);

      this.$nextTick(() => {
        this.$refs.mychild.getDataFromApi();
      });
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PaymentService.paymentList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getUom() {
      MasterService.uomList()
        .then((res) => {
          this.items_uom = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getBank() {
      MasterService.bankList()
        .then((res) => {
          this.items_bank = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.kelompok_id = "";
      this.currentData.kelompok_nama = "";
      this.currentData.user_id = "";
      this.currentData.user_nama = "";
      this.currentData.deskripsi = "";
      this.currentData.bank_id = "";
      this.currentData.bank_nama = "";
      this.currentData.selectedBank = "";
      this.currentData.jumlah_pinjaman = "";
      this.currentData.tanggal_mulai = "";
      this.currentData.tanggal_akhir = "";
      this.currentData.jangka_waktu = "";
      this.currentData.interest_percent = "";
      this.currentData.interest_amount = "";
      this.currentData.total = "";
      this.currentData.id = null;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });
        // console.log(this.$refs.myModal.scrollTop);
        return;
      }

      if (this.currentData.id == null) {
        PaymentService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Pinjaman berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah Pinjaman gagal. Coba lagi");
          });
      } else {
        PaymentService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update Pinjaman berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update Pinjaman gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.kelompok_id = this.editedItem.kelompok_id;
      this.currentData.kelompok_nama = this.editedItem.kelompok_nama;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.user_nama = this.editedItem.user_nama;
      this.currentData.deskripsi = this.editedItem.deskripsi;

      if (this.editedItem.bank_id) {
        this.items_bank.forEach((entry) => {
          if (entry.id == this.editedItem.bank_id) {
            this.currentData.selectedBank = entry;
          }
        });
      }

      this.currentData.jumlah_pinjaman = this.editedItem.jumlah_pinjaman;
      this.currentData.jangka_waktu = this.editedItem.jangka_waktu;
      this.currentData.interest_percent = this.editedItem.interest_percent;

      if (this.editedItem.tanggal_mulai) {
        this.currentData.tanggal_mulai = moment(
          this.editedItem.tanggal_mulai
        ).format("YYYY-MM-DD");
      }

      if (this.editedItem.tanggal_akhir) {
        this.currentData.tanggal_akhir = moment(
          this.editedItem.tanggal_akhir
        ).format("YYYY-MM-DD");
      }

      this.currentData.id = this.editedItem.id;
      console.log("do add");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PaymentService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus Loan berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus Loan gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openKelompokModal: async function () {
      this.$refs.modalKelompok.getDataFromApi();
      this.dialogKelompok = true;
    },

    openMaterialModal: async function () {
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    openUserModal: async function () {
      this.$refs.modalUser.getDataFromApi();
      this.dialogUser = true;
    },

    openMaterialTargetModal: async function () {
      this.$refs.modalMaterialTarget.getDataFromApi();
      this.dialogMaterialTarget = true;
    },

    pilihKelompok: async function (item) {
      this.currentData.kelompok_id = item.id;
      this.currentData.kelompok_nama = item.nama;

      this.currentData.user_id = "";
      this.currentData.user_nama = "";
    },

    pilihUser: async function (item) {
      this.currentData.user_id = item.id;
      this.currentData.user_nama = item.nama;
    },

    pilihMaterial: async function (item) {
      this.currentData.material_bom = item.id;
      this.currentData.material_bom_nama = item.nama;
    },

    pilihMaterialTarget: async function (item) {
      console.log(item);
      this.currentData.material_target = item.id;
      this.currentData.material_target_nama = item.nama;
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
