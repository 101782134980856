import http from "../http-common";
/* eslint-disable */
class PurchaseOrderService {
  purchaseOrderList(page, itemPerPage, keyword) {
    return http.get(
      "/purchaseorder/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  purchaseOrderWithItemList(page, itemPerPage, keyword) {
    return http.get(
      "/purchaseorder-withitem/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  purchaseOrderBySalesOrderIdList(page, itemPerPage, keyword, salesOrderId) {
    return http.get(
      "/purchaseorderbyso/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&sales_order_id=" +
        salesOrderId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getPurchaseOrder(id) {
    return http.get(`/purchaseorder/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, salesOrderKelompok) {
    var bodyFormData = new FormData();
    if (data.sales_order_id)
      bodyFormData.append("sales_order_id", data.sales_order_id);

    bodyFormData.append("currency", data.currency);

    bodyFormData.append(
      "sales_order_kelompok",
      JSON.stringify(salesOrderKelompok)
    );

    return http.post("/purchaseorder", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, salesOrderId, kelompokId, currency, materials) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("sales_order_id", salesOrderId);
    bodyFormData.append("kelompok_id", kelompokId);
    bodyFormData.append("currency", currency);

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.put(`/purchaseorder/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  confirm(id) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    return http.put(`/purchaseorder/${id}/confirm`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/purchaseorder/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new PurchaseOrderService();
