<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Order ke Anggota </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-text-field
                label="Nomor Order"
                outlined
                dense
                required
                readonly
                v-model="currentData.no_kegiatan"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-combobox
                label="Tipe Order"
                dense
                outlined
                v-model="currentData.selectedPOType"
                :items="items_po_type"
                item-text="value"
                readonly
                :rules="currentData.poTypeRules"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="currentData.menuTanggal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
                disabled
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggal"
                    label="Tanggal Transaksi"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.tanggal"
                  @input="currentData.menuTanggal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Currency"
                outlined
                dense
                required
                v-model="currentData.currency"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Anggota"
                outlined
                dense
                required
                v-model="currentData.user_nama"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-combobox
                label="Status Pembayaran"
                dense
                outlined
                v-model="currentData.selectedPaymentStatus"
                :items="items_payment_status"
                item-text="value"
                readonly
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Kelompok"
                outlined
                dense
                required
                v-model="currentData.kelompok_nama"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Status Order"
                outlined
                dense
                required
                v-model="currentData.status_order_value"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" class="d-flex justify-start"> </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-btn
                v-if="currentData.status_transaksi != 5"
                class="mr-2"
                dark
                type="button"
                color="green"
                @click="openQc"
                >Sortir</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" class="d-flex justify-start"> </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-btn
                v-if="currentData.po_type == 2"
                class="mr-2"
                dark
                type="button"
                color="green"
                @click="processFromStock"
                >Penyerahan Barang</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="30%" class="text-left">Material</th>
                      <th width="10%" class="text-left">Qty Order</th>
                      <th width="10%" class="text-left">Satuan</th>
                      <th width="10%" class="text-left">Harga</th>
                      <th width="20%" class="text-left">Total</th>
                      <th width="10%" class="text-left">Qty Diterima</th>
                      <th width="10%" class="text-left">Status</th>
                      <!-- <th width="10%" class="text-left">Action</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in materials" :key="i">
                      <td style="padding: 6px">
                        <v-textarea
                          outlined
                          v-model="item.komoditi_nama"
                          rows="2"
                          readonly
                          row-height="3"
                        ></v-textarea>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.qty"
                          :options="options"
                          readonly
                        />
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.uom"
                          readonly
                        ></v-text-field>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.price"
                          readonly
                          @change="calculateTotal(i)"
                          :options="options"
                        />
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.total"
                          disabled
                          :options="options"
                        />
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.qty_receive"
                          disabled
                          :options="options"
                        />
                      </td>
                      <td>
                        <p>{{ item.status_value }}</p>
                      </td>
                      <!-- <td>
                        <v-icon medium @click="deleteMaterial(i)">
                          mdi-delete
                        </v-icon>
                      </td> -->
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <!-- <td>
                        <v-icon medium @click="addMaterial()">
                          mdi-plus
                        </v-icon>
                      </td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6"> </v-col>
            <v-col>
              <h4 class="v-heading text-h7 mb-2">Total</h4>
            </v-col>
            <v-col>
              <vuetify-money
                outlined
                dense
                required
                v-model="currentData.total"
                readonly
                :options="options"
              />
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row> -->
        </v-form>
      </v-card>
    </v-container>
    <UserSelectComponent
      @pilihUser="pilihAnggota"
      ref="modalCustomer"
      v-model="dialogCustomer"
    />
    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
    />
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import KegiatanService from "../services/KegiatanService";
import MasterService from "../services/MasterService";
import moment from "moment";
// import { format, parseISO } from "date-fns";

import UserSelectComponent from "../components/UserSelectByKelompok.vue";
import MaterialSelectComponent from "../components/MaterialCFRUSelect.vue";

export default {
  name: "OrderToAnggotaEdit",
  components: { UserSelectComponent, MaterialSelectComponent },
  props: {
    id: String,
  },
  data: () => ({
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    dialogCustomer: false,
    dialogMaterial: false,
    breadcrumbs: [
      {
        text: "Data PO",
        disabled: false,
        exact: true,
        to: { name: "OrderToAnggota" },
      },
      { text: "Edit PO", disabled: true },
    ],
    valid: true,
    currentData: {
      no_kegiatan: "",
      menuTanggal: false,
      tanggal: "",
      user_id: "",
      user_nama: "",
      status_transaksi: "",
      total: "",
      currency: "",
      selectedPOType: "",
      selectedPaymentStatus: "",
      kelompok_id: "",
      kelompok_nama: "",
      po_type: "",
      status_order_value: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      poTypeRules: [(v) => !!v || "PO Type harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      anggotaRules: [(v) => !!v || "Anggota harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    items_po_type: [],
    items_payment_status: [],
    materials: [],
    selectedMaterialIndex: 0,
    items_metode_pembayaran: [],
    fotoKey: 0,
  }),
  watch: {
    materials: {
      handler() {
        this.calculateTotalAmount();
      },
      deep: true,
    },
  },
  mounted: async function () {
    this.getPoType();
    this.getPaymentStatus();
    this.getKegiatan();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
    computedTanggal() {
      return this.currentData.tanggal
        ? moment(this.currentData.tanggal).format("DD MMMM YYYY")
        : "";
    },
    computedCRD() {
      return this.currentData.customer_request_date
        ? moment(this.currentData.customer_request_date).format("DD MMMM YYYY")
        : "";
    },
    computedEDD() {
      return this.currentData.expected_delivery_date
        ? moment(this.currentData.expected_delivery_date).format("DD MMMM YYYY")
        : "";
    },
    computedHRD() {
      return this.currentData.expected_ho_received_date
        ? moment(this.currentData.expected_ho_received_date).format(
            "DD MMMM YYYY"
          )
        : "";
    },
  },
  methods: {
    async getPoType() {
      MasterService.poTypeList()
        .then((res) => {
          this.items_po_type = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getPaymentStatus() {
      MasterService.paymentStatusList()
        .then((res) => {
          this.items_payment_status = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMetodePembayaran() {
      MasterService.metodePembayaranList()
        .then((res) => {
          this.items_metode_pembayaran = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getKegiatan() {
      KegiatanService.getKegiatan(this.id)
        .then((res) => {
          console.log(res.data.kegiatan.status_transaksi);
          this.currentData.no_kegiatan = res.data.kegiatan.no_kegiatan;
          this.currentData.tanggal = moment(res.data.kegiatan.tanggal).format(
            "YYYY-MM-DD"
          );

          this.currentData.status_transaksi =
            res.data.kegiatan.status_transaksi;

          this.currentData.user_id = res.data.kegiatan.user_id;
          this.currentData.user_nama = res.data.kegiatan.user_nama;
          this.currentData.currency = res.data.kegiatan.currency;
          this.currentData.kelompok_id = res.data.kegiatan.kelompok_id;
          this.currentData.kelompok_nama = res.data.kegiatan.kelompok_nama;
          this.currentData.po_type = res.data.kegiatan.po_type;
          this.currentData.status_order_value =
            res.data.kegiatan.status_order_value;

          this.items_po_type.forEach((entry) => {
            if (entry.code == res.data.kegiatan.po_type) {
              this.currentData.selectedPOType = entry;
            }
          });

          this.items_payment_status.forEach((entry) => {
            if (entry.code == res.data.kegiatan.payment_status) {
              this.currentData.selectedPaymentStatus = entry;
            }
          });

          console.log(res.data.kegiatan);
          res.data.kegiatan.materials.forEach((entry) => {
            this.materials.push({
              komoditi_id: entry.komoditi_id,
              komoditi_nama: entry.komoditi_nama,
              qty: entry.qty,
              price: entry.price,
              uom: entry.uom,
              qty_receive: entry.qty_receive,
              status_value: entry.status_qc_value,
            });
          });

          this.calculateAll();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.push("/ordertoanggota").catch((err) => {
        console.log(err);
      });
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KegiatanService.update(this.id, this.currentData, this.materials)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Update Order to Anggota berhasil");
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Update Order to Anggota gagal. Coba lagi"
          );
        });
    },

    openCustomerModal: async function () {
      this.$refs.modalCustomer.getDataFromApi();
      this.dialogCustomer = true;
    },

    openMaterialModal: async function (index) {
      this.selectedMaterialIndex = index;
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    pilihAnggota: async function (item) {
      console.log(item);
      this.currentData.user_id = item.id;
      this.currentData.user_nama = item.nama;
    },

    pilihMaterial: async function (item) {
      console.log(item);
      this.materials[this.selectedMaterialIndex].komoditi_id = item.id;
      this.materials[this.selectedMaterialIndex].komoditi_nama = item.nama;
      this.materials[this.selectedMaterialIndex].uom = item.uom;
      this.materials[this.selectedMaterialIndex].price = item.production_price;
    },

    addMaterial: async function () {
      this.materials.push({
        komoditi_id: "",
        komoditi_nama: "",
        qty: "",
        selling_price: "",
        total: "",
        remarks: "",
        uom: "",
      });
    },

    deleteMaterial: async function (index) {
      this.materials.splice(index, 1);
    },

    calculateTotal: async function (index) {
      if (this.materials[index].qty && this.materials[index].price) {
        this.materials[index].total =
          this.materials[index].qty * this.materials[index].price;
      }

      var net = 0;
      for (var i = 0; i < this.materials.length; i++) {
        if (this.materials[i].qty && this.materials[i].price) {
          net += this.materials[i].qty * this.materials[i].price;
        }
      }

      console.log(net);

      this.currentData.total = net;
    },

    calculateAll: async function () {
      var net = 0;
      for (var i = 0; i < this.materials.length; i++) {
        if (this.materials[i].qty && this.materials[i].price) {
          net += this.materials[i].qty * this.materials[i].price;
        }
      }

      this.currentData.total = net;
    },

    calculateTotalAmount: async function () {
      var net = 0;
      for (var i = 0; i < this.materials.length; i++) {
        if (this.materials[i].qty && this.materials[i].price) {
          this.materials[i].total =
            this.materials[i].qty * this.materials[i].price;

          net += this.materials[i].qty * this.materials[i].price;
        }
      }

      this.currentData.total = net;
    },

    processFromStock() {
      this.$router.push("/goodissue-po/" + this.id).catch((err) => {
        console.log(err);
      });
    },

    openQc() {
      this.$router.push("/qualitycontrol/" + this.id).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>

<style scoped>
.small-font {
  font-size: 12px;
}
</style>
