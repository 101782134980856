import http from "../http-common";
/* eslint-disable */
class GoodsMovementService {
  goodsMovementList(
    page,
    itemPerPage,
    keyword,
    kelompokId,
    warehouseId,
    movementType,
    komoditiId,
    tanggal
  ) {
    return http.get(
      "/goodsmovement/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kelompok_id=" +
        kelompokId +
        "&warehouse_id=" +
        warehouseId +
        "&movement_type=" +
        movementType +
        "&komoditi_id=" +
        komoditiId +
        "&tanggal=" +
        tanggal,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }
}

export default new GoodsMovementService();
