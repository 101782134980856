import http from "../http-common";
/* eslint-disable */
class DeliveryService {
  deliveryList(page, itemPerPage, keyword, salesOrderId) {
    return http.get(
      "/delivery/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&purchase_order_id=" +
        salesOrderId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  salesOrderKelompokList(salesOrderId) {
    return http.get(
      "/salesorderkelompok/list?purchase_order_id=" + salesOrderId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getSalesOrder(id) {
    return http.get(`/salesorder/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  getDelivery(id) {
    return http.get(`/delivery/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, materials) {
    var bodyFormData = new FormData();
    if (data.purchase_order_id)
      bodyFormData.append("purchase_order_id", data.purchase_order_id);
    if (data.tanggal) bodyFormData.append("tanggal_transaksi", data.tanggal);
    if (data.warehouse_id)
      bodyFormData.append("warehouse_id", data.warehouse_id);
    if (data.no_resi) bodyFormData.append("no_resi", data.no_resi);

    if (data.selectedKurir && data.selectedKurir != undefined) {
      bodyFormData.append("kurir_id", data.selectedKurir.id);
    }

    if (data.selectedStatus && data.selectedStatus != undefined) {
      bodyFormData.append("status", data.selectedStatus.code);
    }

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.post("/delivery", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    if (data.purchase_order_id)
      bodyFormData.append("purchase_order_id", data.purchase_order_id);
    if (data.tanggal) bodyFormData.append("tanggal_transaksi", data.tanggal);
    if (data.warehouse_id)
      bodyFormData.append("warehouse_id", data.warehouse_id);
    if (data.no_resi) bodyFormData.append("no_resi", data.no_resi);

    if (data.selectedKurir && data.selectedKurir != undefined) {
      bodyFormData.append("kurir_id", data.selectedKurir.id);
    }

    if (data.selectedStatus && data.selectedStatus != undefined) {
      bodyFormData.append("status", data.selectedStatus.code);
    }

    return http.put(`/delivery/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/delivery/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new DeliveryService();
