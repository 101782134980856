import http from "../http-common";
/* eslint-disable */
class UserService {
  userList(page, itemPerPage, keyword) {
    return http.get(
      "/user/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  userByKelompokList(page, itemPerPage, keyword) {
    return http.get(
      "/user-by-kelompok/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  userByKelompokParamList(page, itemPerPage, keyword, id) {
    return http.get(
      "/user-by-kelompok-param/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kelompok_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getUser(id) {
    return http.get(`/user/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("email", data.email);
    bodyFormData.append("password", data.password);
    bodyFormData.append("no_telp", data.no_telp);
    bodyFormData.append("no_ktp", data.no_ktp);
    bodyFormData.append("no_kk", data.no_kk);
    bodyFormData.append("nama_rek", data.nama_rek);
    bodyFormData.append("nama_bank", data.nama_bank);
    bodyFormData.append("no_rek", data.no_rek);
    bodyFormData.append("luas_lahan", data.luas_lahan);
    bodyFormData.append("kapasitas_produksi", data.kapasitas_produksi);
    bodyFormData.append("alamat", data.alamat);
    bodyFormData.append("propinsi_id", data.propinsi_id);
    bodyFormData.append("kabupaten_id", data.kabupaten_id);
    bodyFormData.append("kecamatan", data.kecamatan);
    bodyFormData.append("kode_pos", data.kode_pos);
    bodyFormData.append("negara", data.negara);
    bodyFormData.append("no_npwp", data.no_npwp);
    bodyFormData.append("latitude", data.latitude);
    bodyFormData.append("longitude", data.longitude);

    if (data.selectedStatusAnggota && data.selectedStatusAnggota != undefined) {
      bodyFormData.append("status", data.selectedStatusAnggota.code);
    }

    if (data.selectedJenisKelamin && data.selectedJenisKelamin != undefined) {
      bodyFormData.append("jenis_kelamin", data.selectedJenisKelamin.code);
    }

    if (data.file_user != null) bodyFormData.append("photo", data.file_user);
    if (data.file_ktp != null) bodyFormData.append("photo_ktp", data.file_ktp);
    if (data.file_kk != null) bodyFormData.append("photo_kk", data.file_kk);
    if (data.file_npwp != null)
      bodyFormData.append("photo_npwp", data.file_npwp);

    return http.post("/user", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("email", data.email);
    bodyFormData.append("password", data.password);
    bodyFormData.append("no_telp", data.no_telp);
    bodyFormData.append("no_ktp", data.no_ktp);
    bodyFormData.append("no_kk", data.no_kk);
    bodyFormData.append("nama_rek", data.nama_rek);
    bodyFormData.append("nama_bank", data.nama_bank);
    bodyFormData.append("no_rek", data.no_rek);
    bodyFormData.append("luas_lahan", data.luas_lahan);
    bodyFormData.append("kapasitas_produksi", data.kapasitas_produksi);
    bodyFormData.append("alamat", data.alamat);
    bodyFormData.append("propinsi_id", data.propinsi_id);
    bodyFormData.append("kabupaten_id", data.kabupaten_id);
    bodyFormData.append("kecamatan", data.kecamatan);
    bodyFormData.append("kode_pos", data.kode_pos);
    bodyFormData.append("negara", data.negara);
    bodyFormData.append("no_npwp", data.no_npwp);
    bodyFormData.append("latitude", data.latitude);
    bodyFormData.append("longitude", data.longitude);

    if (data.selectedStatusAnggota && data.selectedStatusAnggota != undefined) {
      bodyFormData.append("status", data.selectedStatusAnggota.code);
    }

    if (data.selectedJenisKelamin && data.selectedJenisKelamin != undefined) {
      bodyFormData.append("jenis_kelamin", data.selectedJenisKelamin.code);
    }

    if (data.file_user != null) bodyFormData.append("photo", data.file_user);
    if (data.file_ktp != null) bodyFormData.append("photo_ktp", data.file_ktp);
    if (data.file_kk != null) bodyFormData.append("photo_kk", data.file_kk);
    if (data.file_npwp != null)
      bodyFormData.append("photo_npwp", data.file_npwp);

    return http.put(`/user/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/user/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new UserService();
