<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Kelompok </v-card-title>
        <v-form ref="form" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    label="Kode"
                    outlined
                    dense
                    v-model="currentData.kode"
                    :rules="currentData.kodeRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    label="Nama"
                    outlined
                    dense
                    v-model="currentData.nama"
                    :rules="currentData.namaRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <vuetify-money
                    label="Luas Lahan (ha)"
                    outlined
                    dense
                    required
                    v-model="currentData.luas_lahan"
                    :options="options"
                  />
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-combobox
                    label="Status"
                    clearable
                    dense
                    outlined
                    v-model="currentData.selectedStatusKelompok"
                    :items="items_status"
                    item-text="value"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-textarea
                    outlined
                    label="Alamat"
                    v-model="currentData.alamat"
                    rows="5"
                    row-height="10"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-textarea
                    outlined
                    label="Deskripsi"
                    v-model="currentData.deskripsi"
                    rows="5"
                    row-height="10"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Propinsi"
                    outlined
                    dense
                    v-model="currentData.propinsi_nama"
                    readonly
                    @click="openPropinsiModal"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Kabupaten/Kota"
                    outlined
                    dense
                    v-model="currentData.kabupaten_nama"
                    readonly
                    @click="openKabupatenModal"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Kecamatan"
                    outlined
                    dense
                    v-model="currentData.kecamatan"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Kode Pos"
                    outlined
                    dense
                    v-model="currentData.kode_pos"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Negara"
                    outlined
                    dense
                    v-model="currentData.negara_nama"
                    readonly
                    @click="openNegaraModal"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Latitude"
                    outlined
                    dense
                    v-model="currentData.latitude"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Longitude"
                    outlined
                    dense
                    v-model="currentData.longitude"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <GmapMap
                ref="mapRef"
                :center="{ lat: 0.7893, lng: 113.9213 }"
                :zoom="5"
                map-type-id="terrain"
                style="width: 100%; height: 500px"
                @click="setNewLocation"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @click="center = m.position"
                />
              </GmapMap>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>

    <PropinsiSelectComponent
      @pilihPropinsi="pilihPropinsi"
      ref="modalPropinsi"
      v-model="dialogPropinsi"
    />

    <KabupatenSelectComponent
      @pilihKabupaten="pilihKabupaten"
      ref="modalKabupaten"
      v-model="dialogKabupaten"
      :propinsi_id="currentData.propinsi_id"
    />

    <NegaraSelectComponent
      @pilihNegara="pilihNegara"
      ref="modalNegara"
      v-model="dialogNegara"
    />
  </v-main>
</template>

<script>
import KelompokService from "../services/KelompokService";
import CommonService from "../services/CommonService";

import PropinsiSelectComponent from "../components/PropinsiSelect.vue";
import KabupatenSelectComponent from "../components/KabupatenSelect.vue";
import NegaraSelectComponent from "../components/NegaraSelect.vue";

export default {
  name: "KelompokAdd",
  components: {
    PropinsiSelectComponent,
    KabupatenSelectComponent,
    NegaraSelectComponent,
  },
  data: () => ({
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    dialogPropinsi: false,
    dialogKabupaten: false,
    dialogNegara: false,
    breadcrumbs: [
      {
        text: "Data Kelompok",
        disabled: false,
        exact: true,
        to: { name: "Kelompok" },
      },
      { text: "Tambah Kelompok", disabled: true },
    ],
    currentData: {
      nama: "",
      luas_lahan: "",
      status_kelompok: "",
      alamat: "",
      deskripsi: "",
      selectedStatusKelompok: "",
      propinsi_id: -1,
      propinsi_nama: "",
      kabupaten_id: "",
      kabupaten_nama: "",
      kecamatan: "",
      kode_pos: "",
      negara: "",
      negara_nama: "",
      latitude: "",
      longitude: "",
      kodeRules: [(v) => !!v || "Kode harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
    },
    items_status: [
      {
        id: 0,
        value: "Tidak Aktif",
      },
      {
        id: 1,
        value: "Aktif",
      },
    ],
    result: {},
    markers: [],
  }),
  mounted: async function () {},
  computed: {},
  methods: {
    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      KelompokService.create(this.currentData)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah kelompok berhasil");

          this.$router
            .push("/kelompok-edit/" + res.data.data.id)
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Tambah kelompok gagal. Coba lagi");
        });
    },

    setNewLocation: function (loc) {
      this.currentData.latitude = loc.latLng.lat();
      this.currentData.longitude = loc.latLng.lng();

      this.markers = [];

      const marker = {
        lat: loc.latLng.lat(),
        lng: loc.latLng.lng(),
      };
      this.markers.push({ position: marker });
    },

    openPropinsiModal: async function () {
      this.$refs.modalPropinsi.getDataFromApi();
      this.dialogPropinsi = true;
    },

    pilihPropinsi: async function (item) {
      console.log(item);
      this.currentData.propinsi_id = item.id;
      this.currentData.propinsi_nama = item.nama;
    },

    openKabupatenModal: async function () {
      this.$refs.modalKabupaten.getDataFromApi();
      this.dialogKabupaten = true;
    },

    pilihKabupaten: async function (item) {
      this.currentData.kabupaten_id = item.id;
      this.currentData.kabupaten_nama = item.nama;
    },

    openNegaraModal: async function () {
      this.$refs.modalNegara.getDataFromApi();
      this.dialogNegara = true;
    },

    pilihNegara: async function (item) {
      this.currentData.negara = item.id;
      this.currentData.negara_nama = item.nama;
    },
  },
};
</script>

<style scoped>
</style>
