<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-card class="mt-4 mb-3">
        <v-card-title>
          Dashboard
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-card class="pt-4 pl-4" style="height: 350px">
            <v-chart class="chart" :option="totalRevenueChart" /> </v-card
        ></v-col>
        <v-col cols="12" sm="12" md="6">
          <v-card class="pt-3 pl-3 pr-3 pb-3" style="height: 100%">
            <h3 class="text-center">Anggota Aktif</h3>

            <div class="row mt-3">
              <div class="col-12 d-flex align-center">
                <h2 style="font-weight: bold">
                  {{ currentAnggotaAktif.totalAnggota }}
                </h2>
                <span class="ml-2" style="font-weight: normal; font-size: 16px">
                  Total</span
                >
              </div>
            </div>
            <v-progress-linear
              class="mt-3"
              v-model="anggotaAktifProgress"
              color="green"
              height="25"
              rounded
            ></v-progress-linear>
            <v-row class="mt-3">
              <v-col cols="12" sm="6" md="6">
                <div class="d-flex flex-column">
                  <h3 style="color: #4caf50">Aktif</h3>
                  <h3>{{ currentAnggotaAktif.totalAnggotaAktif }} Anggota</h3>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <div class="d-flex flex-column">
                  <h3 style="color: #cae7cb">Tidak Aktif</h3>
                  <h3>
                    {{ currentAnggotaAktif.totalAnggotaTidakAktif }} Anggota
                  </h3>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mt-3">
        <v-card-title>
          List Kelompok Tani
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama Kelompok"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1 scroll-to-me"
        >
          <template v-slot:[`item.kelompok_nama`]="{ item }">
            <div>
              <p style="margin-bottom: 0px">{{ item.kelompok_nama }}</p>
              <span style="color: #606f89">{{ item.sk }}</span>
            </div>
          </template>
          <template v-slot:[`item.luas_lahan`]="{ item }">
            {{ item.luas_lahan }} Ha
          </template>
          <template v-slot:[`item.revenue`]="{ item }">
            <span v-if="item.revenue">
              {{ item.revenue }}
            </span>
            <span v-else>0</span>
          </template>
          <template v-slot:[`item.profit`]="{ item }">
            <span v-if="item.profit">
              {{ item.profit }}
            </span>
            <span v-else>0</span>
          </template>
          <template v-slot:[`item.kur`]="{ item }">
            <span v-if="item.kur">
              {{ item.kur }}
            </span>
            <span v-else>0</span>
          </template>
          <template v-slot:[`item.status_kelompok`]="{ item }">
            <v-chip :color="getColor(item.status_kelompok)" dark>
              {{ item.status_kelompok_value }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import KegiatanService from "../services/KegiatanService";
import DashboardService from "../services/DashboardService";
import * as moment from "moment";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data: () => ({
    tab: null,
    breadcrumbs: [
      {
        text: "Data Dashboard",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama Kelompok", value: "nama", sortable: false },
      { text: "Lokasi", value: "kabupaten_nama", sortable: false },
      { text: "Jumlah Anggota", value: "jumlah_anggota", sortable: false },
      { text: "Luas Lahan", value: "luas_lahan", sortable: false },
      { text: "Revenue (IDR)", value: "revenue", sortable: false },
      { text: "Profit", value: "profit", sortable: false },
      { text: "KUR", value: "kur", sortable: false },
      { text: "Status", value: "status_kelompok", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    kategoriKegiatanName: [],
    kategoriKegiatanData: [],
    selectedKategori: "",
    selectedWaktu: "",
    items_kategori: [],
    items_waktu: [
      {
        code: 1,
        value: "Kegiatan Berlangsung",
      },
      {
        code: 2,
        value: "Kegiatan Selesai",
      },
      {
        code: 3,
        value: "Kegiatan akan Datang",
      },
    ],

    currentAnggotaAktif: {
      totalAnggota: 0,
      totalAnggotaAktif: 0,
      totalAnggotaTidakAktif: 0,
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
    this.getDashboard();
  },
  computed: {
    anggotaAktifProgress() {
      return (
        (this.currentAnggotaAktif.totalAnggotaAktif /
          this.currentAnggotaAktif.totalAnggota) *
        100
      );
    },
    totalRevenueChart() {
      return {
        title: {
          text: "Total Revenue",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: this.kategoriKegiatanName,
          formatter: (name) => {
            var value = this.kategoriKegiatanData.filter(
              (row) => row.name === name
            )[0].value;
            return name + " : Rp. " + value;
          },
        },
        series: [
          {
            name: "Total",
            type: "pie",
            radius: [40, 120],
            center: ["50%", "50%"],
            data: this.kategoriKegiatanData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            color: ["#FFDE59", "#B0C24D", "#6CA14C"],
          },
        ],
      };
    },
  },
  methods: {
    getColor(kategori) {
      if (kategori == 1) {
        return "blue";
      } else {
        return "grey";
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },
    getDataFromApi() {
      // this.list_data.push(
      //   {
      //     id: 1,
      //     no: 1,
      //     kelompok_nama: "PT Kelompok Tani Sukses",
      //     sk: "SK PT, 433/E/O/2014",
      //     luas_lahan: "1000 Ha",
      //     revenue: "1.200.000.000",
      //     profit: "1.200.000.000",
      //     kur: "1.200.000.000",
      //     status: 1,
      //     status_value: "Aktif",
      //   },
      // );
      this.loading = false;
      const { page, itemsPerPage } = this.options;
      DashboardService.kelompokList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;

          console.log(this.list_data);
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getDashboard() {
      // const { page, itemsPerPage } = this.options;

      this.kategoriKegiatanName.push("Revenue", "Profit", "Expense");
      this.kategoriKegiatanData.push(
        { value: 100, name: "Revenue" },
        { value: 1000, name: "Profit" },
        { value: 2000, name: "Expense" }
      );

      this.loading = true;
      DashboardService.getDashboard()
        .then((res) => {
          this.currentAnggotaAktif.totalAnggota = res.data.total_anggota;
          this.currentAnggotaAktif.totalAnggotaAktif =
            res.data.total_anggota_aktif;
          this.currentAnggotaAktif.totalAnggotaTidakAktif =
            res.data.total_anggota_tidak_aktif;

          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/dashboard-detail/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      KegiatanService.delete(this.editedItem.kegiatan_id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Hapus kegiatan berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus kegiatan gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
