<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app v-if="$route.meta.isHome === 1">
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-avatar color="background" size="56">
              <v-img :src="currentUser.photo"></v-img>
            </v-avatar>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ currentUser.nama }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ currentUser.email }}</v-list-item-subtitle>
            <v-list-item-subtitle class="mt-2" style="color: black">{{
              currentUser.display_name
            }}</v-list-item-subtitle>
            <v-list-item-subtitle style="color: black">{{
              currentUser.kelompok_nama
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <router-link
          to="/dashboard"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="permissionAllowed('view-sales-order')"
          to="/salesorder"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Order Penjualan</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="permissionAllowed('view-purchase-order')"
          to="/purchaseorder"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-shuffle-disabled</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Distribusi Order</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="permissionAllowed('view-so-kelompok')"
          to="/sokelompok"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-swap-horizontal</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Order Penjualan Kelompok</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="permissionAllowed('view-order-anggota')"
          to="/ordertoanggota"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Order Ke Anggota</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="permissionAllowed('view-good-receipt')"
          to="/goodreceipt"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-call-received</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Penerimaan Barang</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="permissionAllowed('view-production-order')"
          to="/productionorder"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-scale</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Order Produksi</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="permissionAllowed('view-goods-movement')"
          to="/goodsmovement"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Laporan Mutasi Barang</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="permissionAllowed('view-stock-report')"
          to="/stockreport"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-file-document-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Laporan Stok</v-list-item-title>
          </v-list-item>
        </router-link>

        <!-- <router-link
          to="/kegiatan"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pelatihan</v-list-item-title>
          </v-list-item>
        </router-link> -->

        <router-link
          v-if="permissionAllowed('view-loan')"
          to="/loan"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-face-man-shimmer</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pinjaman</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link
          v-if="permissionAllowed('view-payment')"
          to="/payment"
          style="text-decoration: none; color: inherit"
        >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-handshake</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pembayaran</v-list-item-title>
          </v-list-item>
        </router-link>

        <v-list-item
          v-if="permissionAllowed('view-finance')"
          @click="openSalesOrder"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Modul Keuangan</v-list-item-title>
        </v-list-item>

        <v-list-group v-if="permissionAllowed('view-master-data')" no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Master Data</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-if="permissionAllowed('view-authorization')" link>
            <v-list-item-content>
              <router-link
                to="/authorization"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Authorization</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="permissionAllowed('view-jenis-kegiatan')" link>
            <v-list-item-content>
              <router-link
                to="/jenis-kegiatan"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Jenis Kegiatan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="permissionAllowed('view-group-material')" link>
            <v-list-item-content>
              <router-link
                to="/group-material"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Group Material</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="permissionAllowed('view-material-type')" link>
            <v-list-item-content>
              <router-link
                to="/material-type"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Material Type</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="permissionAllowed('view-material')" link>
            <v-list-item-content>
              <router-link
                to="/material"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Material</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/bom"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>BOM</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item v-if="permissionAllowed('view-kelompok')" link>
            <v-list-item-content>
              <router-link
                to="/kelompok"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Kelompok</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="permissionAllowed('view-wf-production')" link>
            <v-list-item-content>
              <router-link
                to="/wf-production"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>WF Production</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="permissionAllowed('view-user')" link>
            <v-list-item-content>
              <router-link
                to="/anggota"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>User</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="permissionAllowed('view-customer')" link>
            <v-list-item-content>
              <router-link
                to="/customer"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Customer</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="permissionAllowed('view-vendor')" link>
            <v-list-item-content>
              <router-link
                to="/vendor"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Vendor</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="permissionAllowed('view-news')" link>
            <v-list-item-content>
              <router-link
                to="/news"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>News</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- <v-list-group no-action>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Web Content</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/sejarah"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Sejarah</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/visi-misi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Visi Misi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/struktur-organisasi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Struktur Organisasi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/fasilitator"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Fasilitator</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/berita"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Berita</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/testimoni"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Testimoni</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/fasilitas"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Fasilitas</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/direksi"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Direksi</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/jadwalpelatihanlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Jadwal Pelatihan</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <router-link
                to="/webcontent/brosurlist"
                style="text-decoration: none; color: inherit"
              >
                <v-list-item-title>Brosur</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->
        <router-link to="/" style="text-decoration: none; color: inherit">
          <v-list-item link @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      color="background"
      dark
      app
      dense
      v-if="$route.meta.isHome === 1"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-badge color="green" dot>
        <v-icon medium> mdi-vuetify </v-icon>
      </v-badge>
    </v-app-bar>

    <router-view></router-view>

    <!-- <v-footer
      padless
      color="purple"
      v-if="$route.meta.isHome === 1"
    >
      <v-col
        class="text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Halo Medika</strong>
      </v-col>
    </v-footer> -->
    <form
      target="_blank"
      ref="formbang"
      action="https://qasfin.phalaid.xyz/login"
      method="post"
      accept-charset="utf-8"
    >
      <input
        ref="identityuser"
        type="text"
        name="identity"
        value=""
        id="identity"
        class="form-control"
        placeholder="Email/Username"
      />
      <input
        ref="passuser"
        type="password"
        name="password"
        value=""
        id="password"
        class="form-control"
        placeholder="Password"
      />
      <input
        ref="redirect"
        type="text"
        name="redirect"
        value=""
        id="redirect"
        class="form-control"
        placeholder="redirect"
      />
    </form>
  </v-app>
</template>

<script>
// import CommonService from "./services/CommonService";
// import AuthService from "./services/AuthService";

import ValidatePermissionService from "./services/ValidatePermissionService"

export default {
  data: () => ({
    identity: "",
    password: "",
    drawer: null,
    admins: [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
    currentUser: {
      nama: "",
      photo: "",
      email: "",
      display_name: "",
    },
  }),

  mounted() {
    var myUser = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_USER_PREFIX)
    )

    console.log(myUser)
    if (myUser) {
      this.currentUser = myUser
    }
  },

  methods: {
    permissionAllowed: function(permissionName) {
      return ValidatePermissionService.isAllowed(permissionName)
    },
    logout: async function() {
      this.$store.dispatch("logout").then(() =>
        this.$router.push("/").catch((err) => {
          console.log(err)
        })
      )
    },

    openSalesOrder: async function() {
      // this.identity = "agus.sulaeman@ptezio.id";
      // this.password = "123";

      // this.$refs.identityuser.value = "agus.sulaeman@ptezio.id";
      // this.$refs.passuser.value = "123";
      // this.$refs.redirect.value = "1";
      // this.$refs.formbang.submit();

      this.identity = "maman@gmail.com"
      this.password = "123"

      this.$refs.identityuser.value = "maman@gmail.com"
      this.$refs.passuser.value = "123"
      this.$refs.redirect.value = "1"
      this.$refs.formbang.submit()

      // AuthService.loginBang({
      //   identity: "agus.sulaeman@ptezio.id",
      //   password: "123",
      //   remember: 1,
      // })
      //   .then((res) => {
      //     console.log(res);
      //     window
      //       .open("https://bangtani.xyz/sales_order/index", "_blank")
      //       .focus();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     CommonService.showSuccessToast("Buka halaman gagal. Coba lagi");
      //   });
    },
  },
}
</script>
