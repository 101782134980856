import http from "../http-common";
/* eslint-disable */
class BomService {
  bomList(page, itemPerPage, keyword, id) {
    return http.get(
      "/bom/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&wf_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  bomByTargetList(page, itemPerPage, keyword, komoditiId, id) {
    return http.get(
      "/bom-by-target/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&komoditi_id=" +
        komoditiId +
        "&wf_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  bomByWfList(id) {
    return http.get("/bom-by-wf/list?" + "wf_id=" + id, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("wf_id", data.wf_id);
    bodyFormData.append("material_bom", data.material_bom);
    bodyFormData.append("consumption", data.consumption);

    if (data.selectedUom && data.selectedUom != undefined) {
      bodyFormData.append("uom", data.selectedUom.code);
    }

    return http.post("/bom", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("material_bom", data.material_bom);
    bodyFormData.append("consumption", data.consumption);

    if (data.selectedUom && data.selectedUom != undefined) {
      bodyFormData.append("uom", data.selectedUom.code);
    }

    return http.put(`/bom/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  delete(id) {
    return http.delete(`/bom/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new BomService();
