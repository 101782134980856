import http from "../http-common";
/* eslint-disable */
class GoodIssueService {
  goodIssueList(page, itemPerPage, keyword) {
    return http.get(
      "/goodissue/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  goodIssuePoList(page, itemPerPage, keyword, id) {
    return http.get(
      "/goodissue-bypo/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&purchase_order_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  goodIssueItem(page, itemPerPage, keyword, id) {
    return http.get(
      "/goodissueitem/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&good_issue_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  goodIssueItemToDeliveryList(keyword, id) {
    return http.get(
      "/goodissueitem-to-delivery/list?" +
        "keyword=" +
        keyword +
        "&purchase_order_id=" +
        id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  deliveryItemlist(keyword, id) {
    return http.get(
      "/deliveryitem/list?" + "keyword=" + keyword + "&delivery_id=" + id,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getGoodIssue(id) {
    return http.get(`/goodissue/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, materials) {
    var bodyFormData = new FormData();

    if (
      data.selectedTransactionType &&
      data.selectedTransactionType != undefined
    ) {
      bodyFormData.append(
        "transaction_type",
        data.selectedTransactionType.MV_ID
      );
    }

    if (data.kegiatan_id) bodyFormData.append("kegiatan_id", data.kegiatan_id);
    if (data.tanggal) bodyFormData.append("tanggal", data.tanggal);
    if (data.warehouse_id)
      bodyFormData.append("warehouse_id", data.warehouse_id);

    console.log(JSON.stringify(materials));
    bodyFormData.append("materials", JSON.stringify(materials));

    return http.post("/goodissue-po", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  createDelivery(data, materials) {
    var bodyFormData = new FormData();

    if (
      data.selectedTransactionType &&
      data.selectedTransactionType != undefined
    ) {
      bodyFormData.append(
        "transaction_type",
        data.selectedTransactionType.MV_ID
      );
    }

    if (data.purchase_order_id)
      bodyFormData.append("purchase_order_id", data.purchase_order_id);
    if (data.tanggal) bodyFormData.append("tanggal", data.tanggal);
    if (data.warehouse_id)
      bodyFormData.append("warehouse_id", data.warehouse_id);

    console.log(JSON.stringify(materials));
    bodyFormData.append("materials", JSON.stringify(materials));

    return http.post("/goodissue", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data, materials) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    if (
      data.selectedTransactionType &&
      data.selectedTransactionType != undefined
    ) {
      bodyFormData.append(
        "transaction_type",
        data.selectedTransactionType.MV_ID
      );
    }

    if (data.purchase_order_id)
      bodyFormData.append("purchase_order_id", data.purchase_order_id);
    if (data.tanggal) bodyFormData.append("tanggal", data.tanggal);
    if (data.warehouse_id)
      bodyFormData.append("warehouse_id", data.warehouse_id);

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.put(`/goodissue/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  createProduction(data, materials) {
    var bodyFormData = new FormData();

    if (
      data.selectedTransactionType &&
      data.selectedTransactionType != undefined
    ) {
      bodyFormData.append(
        "transaction_type",
        data.selectedTransactionType.MV_ID
      );
    }

    if (data.production_order_id)
      bodyFormData.append("production_order_id", data.production_order_id);
    if (data.tanggal) bodyFormData.append("tanggal", data.tanggal);
    if (data.warehouse_id)
      bodyFormData.append("warehouse_id", data.warehouse_id);

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.post("/goodissue-production", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/goodissue/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new GoodIssueService();
