import http from "../http-common";
/* eslint-disable */
class JenisKegiatanService {
  jenisKegiatanList(page, itemPerPage, keyword) {
    return http.get(
      "/jenis-kegiatan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("type", data.type);

    return http.post("/jenis-kegiatan", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("type", data.type);

    return http.put(`/jenis-kegiatan/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  delete(id) {
    return http.delete(`/jenis-kegiatan/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new JenisKegiatanService();
