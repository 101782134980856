import http from "../http-common";
/* eslint-disable */
class CustomerService {
  customerList(page, itemPerPage, keyword) {
    return http.get(
      "/customer/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getCustomer(id) {
    return http.get(`/customer/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();

    bodyFormData.append("name", data.name);
    bodyFormData.append("email", data.email);
    bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("vat_number", data.vat_number);
    bodyFormData.append("address", data.address);
    bodyFormData.append("address_dua", data.address_dua);
    bodyFormData.append("postal_code", data.postal_code);
    bodyFormData.append("propinsi_id", data.propinsi_id);
    bodyFormData.append("kabupaten_id", data.kabupaten_id);
    bodyFormData.append("negara", data.negara);
    bodyFormData.append("kecamatan", data.kecamatan);
    bodyFormData.append("nama_bank", data.nama_bank);
    bodyFormData.append("nama_rekening_bank", data.nama_rekening_bank);
    bodyFormData.append("nomor_rekening", data.nomor_rekening);
    bodyFormData.append("no_telepon_satu", data.no_telepon_satu);
    bodyFormData.append("kontak_person_satu", data.kontak_person_satu);

    bodyFormData.append("no_telepon_dua", data.no_telepon_dua);
    bodyFormData.append("kontak_person_dua", data.kontak_person_dua);
    bodyFormData.append("no_telepon_tiga", data.no_telepon_tiga);
    bodyFormData.append("kontak_person_tiga", data.kontak_person_tiga);

    return http.post("/customer", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    bodyFormData.append("name", data.name);
    bodyFormData.append("email", data.email);
    bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("vat_number", data.vat_number);
    bodyFormData.append("address", data.address);
    bodyFormData.append("address_dua", data.address_dua);
    bodyFormData.append("postal_code", data.postal_code);
    bodyFormData.append("propinsi_id", data.propinsi_id);
    bodyFormData.append("kabupaten_id", data.kabupaten_id);
    bodyFormData.append("negara", data.negara);
    bodyFormData.append("kecamatan", data.kecamatan);
    bodyFormData.append("nama_bank", data.nama_bank);
    bodyFormData.append("nama_rekening_bank", data.nama_rekening_bank);
    bodyFormData.append("nomor_rekening", data.nomor_rekening);
    bodyFormData.append("no_telepon_satu", data.no_telepon_satu);
    bodyFormData.append("kontak_person_satu", data.kontak_person_satu);

    bodyFormData.append("no_telepon_dua", data.no_telepon_dua);
    bodyFormData.append("kontak_person_dua", data.kontak_person_dua);
    bodyFormData.append("no_telepon_tiga", data.no_telepon_tiga);
    bodyFormData.append("kontak_person_tiga", data.kontak_person_tiga);

    return http.put(`/customer/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/customer/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new CustomerService();
