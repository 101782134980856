import http from "../http-common";
/* eslint-disable */
class InvoiceService {
  invoiceList(page, itemPerPage, keyword) {
    return http.get(
      "/invoice/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getInvoiceService() {
    return http.get(`/material-service`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("jumlah_tagihan", data.jumlah_tagihan);

    bodyFormData.append(
      "tanggal_jatuh_tempo_pembayaran",
      data.tanggalJatuhTempo
    );

    bodyFormData.append("doc_id", data.sales_order_id);

    if (data.diskon) bodyFormData.append("diskon", data.diskon);

    if (data.selectedTipeTagihan && data.selectedTipeTagihan != undefined) {
      bodyFormData.append("tipe_tagihan", data.selectedTipeTagihan.code);
    }

    return http.post("/invoice", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("kode", data.kode);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("char_comodity_type", data.char_comodity_type);
    bodyFormData.append("char_comodity_subty", data.char_comodity_subty);
    bodyFormData.append("char_origin", data.char_origin);
    bodyFormData.append("char_variety", data.char_variety);
    bodyFormData.append("char_phase", data.char_phase);
    bodyFormData.append("char_process", data.char_process);
    bodyFormData.append("grade", data.grade);
    if (data.selling_price);
    bodyFormData.append("selling_price", data.selling_price);

    if (data.production_price)
      bodyFormData.append("production_price", data.production_price);

    if (data.selectedMaterialType && data.selectedMaterialType != undefined) {
      bodyFormData.append("material_type", data.selectedMaterialType.code);
    }

    if (data.selectedUom && data.selectedUom != undefined) {
      bodyFormData.append("uom", data.selectedUom.code);
    }

    if (data.selectedGroupMaterial && data.selectedGroupMaterial != undefined) {
      bodyFormData.append("group_material_id", data.selectedGroupMaterial.id);
    }

    if (
      data.selectedGenericComodity &&
      data.selectedGenericComodity != undefined
    ) {
      bodyFormData.append("generic_comodity", data.selectedGenericComodity.id);
    }

    return http.put(`/invoice/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/invoice/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new InvoiceService();
