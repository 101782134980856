import http from "../http-common";
/* eslint-disable */
class KelompokService {
  kelompokList(page, itemPerPage, keyword) {
    return http.get(
      "/kelompok/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  kelompokPermissionValueList(
    page,
    itemPerPage,
    keyword,
    roleId,
    permissionId
  ) {
    return http.get(
      "/kelompok-permission-value/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&role_id=" +
        roleId +
        "&permission_id=" +
        permissionId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getKelompok(id) {
    return http.get(`/kelompok/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("kode", data.kode);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("alamat", data.alamat);
    bodyFormData.append("luas_lahan", data.luas_lahan);

    bodyFormData.append("propinsi_id", data.propinsi_id);
    bodyFormData.append("kabupaten_id", data.kabupaten_id);
    bodyFormData.append("kecamatan", data.kecamatan);
    bodyFormData.append("kode_pos", data.kode_pos);
    bodyFormData.append("negara", data.negara);
    bodyFormData.append("latitude", data.latitude);
    bodyFormData.append("longitude", data.longitude);

    if (
      data.selectedStatusKelompok &&
      data.selectedStatusKelompok != undefined
    ) {
      bodyFormData.append("status_kelompok", data.selectedStatusKelompok.id);
    }

    return http.post("/kelompok", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("kode", data.kode);
    bodyFormData.append("nama", data.nama);
    bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("alamat", data.alamat);
    bodyFormData.append("luas_lahan", data.luas_lahan);

    bodyFormData.append("propinsi_id", data.propinsi_id);
    bodyFormData.append("kabupaten_id", data.kabupaten_id);
    bodyFormData.append("kecamatan", data.kecamatan);
    bodyFormData.append("kode_pos", data.kode_pos);
    bodyFormData.append("negara", data.negara);
    bodyFormData.append("latitude", data.latitude);
    bodyFormData.append("longitude", data.longitude);

    if (
      data.selectedStatusKelompok &&
      data.selectedStatusKelompok != undefined
    ) {
      bodyFormData.append("status_kelompok", data.selectedStatusKelompok.id);
    }
    return http.put(`/kelompok/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  delete(id) {
    return http.delete(`/kelompok/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new KelompokService();
