import http from "../http-common";
/* eslint-disable */
class WarehouseItemService {
  warehouseItemList(page, itemPerPage, keyword, warehouseId) {
    return http.get(
      "/warehouse-item/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&warehouse_id=" +
        warehouseId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("komoditi_id", data.komoditi_id);
    bodyFormData.append("qty", data.qty);
    bodyFormData.append("warehouse_id", data.warehouse_id);

    return http.post("/warehouse-item", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("komoditi_id", data.komoditi_id);
    bodyFormData.append("qty", data.qty);
    bodyFormData.append("warehouse_id", data.warehouse_id);

    return http.put(`/warehouse-item/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  delete(id) {
    return http.delete(`/warehouse-item/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new WarehouseItemService();
