<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> User </v-card-title>
        <v-form ref="form" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Nama"
                    outlined
                    dense
                    v-model="currentData.nama"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    v-model="currentData.email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Password (isi untuk mengganti)"
                    outlined
                    dense
                    v-model="currentData.password"
                    :rules="[rules.counter]"
                    type="password"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="No Telp"
                    outlined
                    dense
                    v-model="currentData.no_telp"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Status Anggota"
                    clearable
                    dense
                    outlined
                    v-model="currentData.selectedStatusAnggota"
                    :items="items_status_anggota"
                    item-text="value"
                  ></v-combobox>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="No KTP"
                    outlined
                    dense
                    v-model="currentData.no_ktp"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="No KK"
                    outlined
                    dense
                    v-model="currentData.no_kk"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="Nama Rekening"
                    outlined
                    dense
                    v-model="currentData.nama_rek"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="Nama Bank"
                    outlined
                    dense
                    v-model="currentData.nama_bank"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="No Rekening"
                    outlined
                    dense
                    v-model="currentData.no_rek"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <vuetify-money
                    label="Luas Lahan"
                    outlined
                    dense
                    required
                    v-model="currentData.luas_lahan"
                    :options="options"
                  />
                </v-col>
                <!-- <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="Kapasitas Produksi (Kg)"
                    outlined
                    dense
                    v-model="currentData.kapasitas_produksi"
                  ></v-text-field>
                </v-col> -->
                <v-col v-if="isAnggota" cols="12" sm="4" md="4" class="">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Alamat"
                    dense
                    value=""
                    rows="3"
                    v-model="currentData.alamat"
                  ></v-textarea>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="Propinsi"
                    outlined
                    dense
                    v-model="currentData.propinsi_nama"
                    readonly
                    @click="openPropinsiModal"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="Kabupaten/Kota"
                    outlined
                    dense
                    v-model="currentData.kabupaten_nama"
                    readonly
                    @click="openKabupatenModal"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="Kecamatan"
                    outlined
                    dense
                    v-model="currentData.kecamatan"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="Kode Pos"
                    outlined
                    dense
                    v-model="currentData.kode_pos"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="Negara"
                    outlined
                    dense
                    v-model="currentData.negara_nama"
                    readonly
                    @click="openNegaraModal"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Jenis Kelamin"
                    clearable
                    dense
                    outlined
                    v-model="currentData.selectedJenisKelamin"
                    :items="items_jenis_kelamin"
                    item-text="value"
                  ></v-combobox>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="No NPWP"
                    outlined
                    dense
                    v-model="currentData.no_npwp"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4"> </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4"> </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-sheet color="white" elevation="2">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img
                        :key="fotoKey"
                        :src="currentData.foto_user"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-sheet>
                  <v-file-input
                    @change="loadImage"
                    accept="image/*"
                    v-model="currentData.file_user"
                    prepend-icon="mdi-image"
                    show-size
                    label="Foto Profil"
                  />
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-sheet color="white" elevation="2">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img
                        :key="fotoKtpKey"
                        :src="currentData.foto_ktp"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-sheet>
                  <v-file-input
                    @change="loadImageKtp"
                    accept="image/*"
                    v-model="currentData.file_ktp"
                    prepend-icon="mdi-image"
                    show-size
                    label="Foto KTP"
                  />
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-sheet color="white" elevation="2">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img
                        :key="fotoKkKey"
                        :src="currentData.foto_kk"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-sheet>
                  <v-file-input
                    @change="loadImageKk"
                    accept="image/*"
                    v-model="currentData.file_kk"
                    prepend-icon="mdi-image"
                    show-size
                    label="Foto KK"
                  />
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-sheet color="white" elevation="2">
                    <v-responsive :aspect-ratio="16 / 9">
                      <v-img
                        :key="fotoNpwpKey"
                        :src="currentData.foto_npwp"
                        contain
                      ></v-img>
                    </v-responsive>
                  </v-sheet>
                  <v-file-input
                    @change="loadImageNpwp"
                    accept="image/*"
                    v-model="currentData.file_npwp"
                    prepend-icon="mdi-image"
                    show-size
                    label="Foto NPWP"
                  />
                </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>
                <v-col cols="12" sm="4" md="4"> </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="Latitude"
                    outlined
                    dense
                    v-model="currentData.latitude"
                  ></v-text-field>
                </v-col>
                <v-col v-if="isAnggota" cols="12" sm="4" md="4">
                  <v-text-field
                    label="Longitude"
                    outlined
                    dense
                    v-model="currentData.longitude"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="isAnggota">
            <v-col cols="12" sm="12" md="12">
              <GmapMap
                ref="mapRef"
                :center="{ lat: 10, lng: 10 }"
                :zoom="14"
                map-type-id="terrain"
                style="width: 100%; height: 500px"
                @click="setNewLocation"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @click="center = m.position"
                />
              </GmapMap>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
    <v-container v-if="isAnggota" fluid>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab>Material</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <AnggotaKomoditiesComponent :id="id" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>

    <PropinsiSelectComponent
      @pilihPropinsi="pilihPropinsi"
      ref="modalPropinsi"
      v-model="dialogPropinsi"
    />

    <KabupatenSelectComponent
      @pilihKabupaten="pilihKabupaten"
      ref="modalKabupaten"
      v-model="dialogKabupaten"
      :propinsi_id="currentData.propinsi_id"
    />

    <NegaraSelectComponent
      @pilihNegara="pilihNegara"
      ref="modalNegara"
      v-model="dialogNegara"
    />
  </v-main>
</template>

<script>
import UserService from "../services/UserService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";

import PropinsiSelectComponent from "../components/PropinsiSelect.vue";
import KabupatenSelectComponent from "../components/KabupatenSelect.vue";
import NegaraSelectComponent from "../components/NegaraSelect.vue";

import AnggotaKomoditiesComponent from "./AnggotaKomodities.vue";

export default {
  name: "AnggotaEdit",
  components: {
    PropinsiSelectComponent,
    KabupatenSelectComponent,
    NegaraSelectComponent,
    AnggotaKomoditiesComponent,
  },
  props: {
    id: String,
  },
  data: () => ({
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    dialogPropinsi: false,
    dialogKabupaten: false,
    dialogNegara: false,
    tab: null,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data User",
        disabled: false,
        exact: true,
        to: { name: "Anggota" },
      },
      { text: "Edit User", disabled: true },
    ],
    currentData: {
      id: null,
      nama: "",
      email: "",
      password: "",
      no_telp: "",
      selectedStatusAnggota: "",
      selectedJenisKelamin: "",
      no_ktp: "",
      no_kk: "",
      nama_rek: "",
      nama_bank: "",
      no_rek: "",
      luas_lahan: "",
      kapasitas_produksi: "",
      alamat: "",
      propinsi_id: -1,
      propinsi_nama: "",
      kabupaten_id: "",
      kabupaten_nama: "",
      kecamatan: "",
      kode_pos: "",
      negara: "",
      negara_nama: "",
      jenis_kelamin: "",
      no_npwp: "",
      foto_npwp: "",
      file_npwp: null,
      foto_user: "",
      file_user: null,
      foto_ktp: "",
      file_ktp: null,
      foto_kk: "",
      file_kk: null,
      namaRules: [(v) => !!v || "Nama harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      passwordRules: [(v) => !!v || "Password harus diisi"],
      statusRules: [(v) => !!v || "Status harus diisi"],
    },
    rules: {
      required: (value) => !!value || "Field harus diisi",
      counter: (value) => {
        if (value.length > 0) {
          if (value.length < 10) {
            return "Panjang minimal 10 karakter";
          }

          const pattern =
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/;
          return (
            pattern.test(value) ||
            "Password harus terdiri angka, huruf besar, huruf kecil, special character"
          );
        } else {
          return true;
        }
      },
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      passchar: (value) => {
        const pattern =
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/;
        return (
          pattern.test(value) ||
          "Password harus terdiri angka, huruf besar, huruf kecil, special character"
        );
      },
    },
    fotoKey: 0,
    fotoKtpKey: 0,
    fotoKkKey: 0,
    fotoNpwpKey: 0,
    items_status_anggota: [],
    items_jenis_kelamin: [],
    result: {},
    markers: [],
  }),
  mounted: async function () {
    this.getStatusAnggota();
    this.getJenisKelamin();
    this.getUser();
  },
  computed: {
    isAnggota() {
      if (
        this.currentData.selectedStatusAnggota &&
        this.currentData.selectedStatusAnggota.code == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.foto_user = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.foto_user = "";
        this.currentData.file_user = null;
      }
    },

    loadImageKtp: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.foto_ktp = e.target.result;
          this.fotoKtpKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.foto_ktp = "";
        this.currentData.file_ktp = null;
      }
    },

    loadImageKk: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.foto_kk = e.target.result;
          this.fotoKkKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.foto_kk = "";
        this.currentData.file_kk = null;
      }
    },

    loadImageNpwp: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.foto_npwp = e.target.result;
          this.fotoNpwpKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.foto_npwp = "";
        this.currentData.file_npwp = null;
      }
    },

    async getStatusAnggota() {
      MasterService.statusAnggotaList()
        .then((res) => {
          this.items_status_anggota = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getJenisKelamin() {
      MasterService.jenisKelaminList()
        .then((res) => {
          this.items_jenis_kelamin = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeEditable: function () {
      this.isEditable = 1;
    },

    setNewLocation: function (loc) {
      console.log(loc.latLng.lat());
      console.log(loc.latLng.lng());

      this.currentData.latitude = loc.latLng.lat();
      this.currentData.longitude = loc.latLng.lng();

      this.markers = [];

      const marker = {
        lat: loc.latLng.lat(),
        lng: loc.latLng.lng(),
      };
      this.markers.push({ position: marker });
    },

    getUser() {
      UserService.getUser(this.id)
        .then((res) => {
          this.currentData.nama = res.data.user.nama;
          this.currentData.email = res.data.user.email;
          this.currentData.no_telp = res.data.user.no_telp;
          this.currentData.no_ktp = res.data.user.no_ktp;
          this.currentData.no_kk = res.data.user.no_kk;
          this.currentData.nama_rek = res.data.user.nama_rek;
          this.currentData.nama_bank = res.data.user.nama_bank;
          this.currentData.no_rek = res.data.user.no_rek;
          this.currentData.luas_lahan = res.data.user.luas_lahan;
          this.currentData.kapasitas_produksi =
            res.data.user.kapasitas_produksi;

          this.currentData.alamat = res.data.user.alamat;
          this.currentData.propinsi_id = res.data.user.propinsi_id;
          this.currentData.propinsi_nama = res.data.user.propinsi_nama;
          this.currentData.kabupaten_id = res.data.user.kabupaten_id;
          this.currentData.kabupaten_nama = res.data.user.kabupaten_nama;
          this.currentData.kecamatan = res.data.user.kecamatan;
          this.currentData.kode_pos = res.data.user.kode_pos;

          this.currentData.negara = res.data.user.negara;
          this.currentData.negara_nama = res.data.user.negara_nama;
          this.currentData.no_npwp = res.data.user.no_npwp;
          this.currentData.latitude = res.data.user.latitude;
          this.currentData.longitude = res.data.user.longitude;

          this.currentData.foto_user = res.data.user.photo;
          this.currentData.foto_kk = res.data.user.photo_kk;
          this.currentData.foto_ktp = res.data.user.photo_ktp;
          this.currentData.foto_npwp = res.data.user.photo_npwp;

          if (res.data.user.status != null) {
            this.items_status_anggota.forEach((entry) => {
              if (entry.code == res.data.user.status) {
                this.currentData.selectedStatusAnggota = entry;
              }
            });
          }

          if (res.data.user.jenis_kelamin != null) {
            this.items_jenis_kelamin.forEach((entry) => {
              if (entry.code == res.data.user.jenis_kelamin) {
                this.currentData.selectedJenisKelamin = entry;
              }
            });
          }

          if (res.data.user.status == 1) {
            if (this.currentData.latitude && this.currentData.longitude) {
              const marker = {
                lat: this.currentData.latitude,
                lng: this.currentData.longitude,
              };
              this.markers.push({ position: marker });

              this.$nextTick(() => {
                this.$refs.mapRef.$mapPromise.then((map) => {
                  map.panTo({
                    lat: this.currentData.latitude,
                    lng: this.currentData.longitude,
                  });
                });
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      UserService.update(this.id, this.currentData)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Update anggota berhasil");
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Update anggota gagal. Coba lagi");
        });
    },

    openPropinsiModal: async function () {
      this.$refs.modalPropinsi.getDataFromApi();
      this.dialogPropinsi = true;
    },

    pilihPropinsi: async function (item) {
      console.log(item);
      this.currentData.propinsi_id = item.id;
      this.currentData.propinsi_nama = item.nama;
    },

    openKabupatenModal: async function () {
      this.$refs.modalKabupaten.getDataFromApi();
      this.dialogKabupaten = true;
    },

    pilihKabupaten: async function (item) {
      this.currentData.kabupaten_id = item.id;
      this.currentData.kabupaten_nama = item.nama;
    },

    openNegaraModal: async function () {
      this.$refs.modalNegara.getDataFromApi();
      this.dialogNegara = true;
    },

    pilihNegara: async function (item) {
      this.currentData.negara = item.id;
      this.currentData.negara_nama = item.nama;
    },
  },
};
</script>

<style scoped>
</style>
