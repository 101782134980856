import http from "../http-common";
/* eslint-disable */
class KegiatanService {
  kegiatanList(page, itemPerPage, keyword) {
    return http.get(
      "/kegiatan/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  kegiatanListByKategori(page, itemPerPage, keyword, kategori, waktu) {
    return http.get(
      "/kegiatan/list/bycategory?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kategori=" +
        kategori +
        "&waktu=" +
        waktu,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  kegiatanItemToGoodIssueList(kegiatanId) {
    return http.get(
      "/kegiatanitem-to-goodissue/list?kegiatan_id=" + kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getKegiatan(id) {
    return http.get(`/kegiatan/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, materials) {
    var bodyFormData = new FormData();
    if (data.tanggal) bodyFormData.append("tanggal_kegiatan", data.tanggal);
    if (data.user_id) bodyFormData.append("user_id", data.user_id);
    if (data.currency) bodyFormData.append("currency", data.currency);
    if (data.is_auto) bodyFormData.append("is_auto", data.is_auto);
    if (data.warehouse_id)
      bodyFormData.append("warehouse_id", data.warehouse_id);

    if (data.selectedPOType && data.selectedPOType != undefined) {
      bodyFormData.append("po_type", data.selectedPOType.code);
    }

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.post("/kegiatan", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data, materials) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    if (data.tanggal) bodyFormData.append("tanggal_kegiatan", data.tanggal);
    if (data.user_id) bodyFormData.append("user_id", data.user_id);
    if (data.currency) bodyFormData.append("currency", data.currency);

    if (data.selectedPOType && data.selectedPOType != undefined) {
      bodyFormData.append("po_type", data.selectedPOType.code);
    }

    if (data.selectedPaymentStatus && data.selectedPaymentStatus != undefined) {
      bodyFormData.append("payment_status", data.selectedPaymentStatus.code);
    }

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.put(`/kegiatan/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/kegiatan/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new KegiatanService();
