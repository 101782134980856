<template>
  <div>
    <v-card>
      <v-card-title>
        Detail Pinjaman
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari Nama"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Apa anda akan menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Tidak</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ya</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.transaction_date`]="{ item }">
          {{ getCurrentDate(item.transaction_date) }}
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <span v-if="item.amount">{{ numberWithCommas(item.amount) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import LoanService from "../services/LoanService";
import CommonService from "../services/CommonService";
import moment from "moment";

export default {
  props: {
    id: Number,
  },
  components: {},
  data: () => ({
    childKey: 0,
    selectedRows: [],
    dialogUser: false,
    dialogMaterial: false,
    breadcrumbs: [
      {
        text: "Data Warehouse Item",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Transaction Date", value: "transaction_date", sortable: false },
      {
        text: "Transaction Type",
        value: "transaction_type_value",
        sortable: false,
      },
      { text: "Amount", value: "amount", sortable: false },
      { text: "Status", value: "status_value", sortable: false },
      {
        text: "Accounting Document",
        value: "accounting_document",
        sortable: false,
      },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      komoditi_id: "",
      komoditi_nama: "",
      qty: "",
      warehouse_id: 0,
    },
  }),
  watch: {
    selectedRows: {
      handler() {
        if (this.selectedRows != undefined && this.selectedRows[0]) {
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },
    openSelectedRow() {
      console.log(this.selectedRows[0]);
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      LoanService.loanDetailList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.komoditi_id = "";
      this.currentData.komoditi_nama = "";
      this.currentData.qty = "";
      this.currentData.id = null;
    },

    doSave: async function () {
      this.currentData.warehouse_id = this.id;
      if (this.currentData.id == null) {
        LoanService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah warehouse item berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah warehouse item gagal. Coba lagi"
            );
          });
      } else {
        LoanService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update warehouse item berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update warehouse item gagal. Coba lagi"
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.komoditi_id = this.editedItem.komoditi_id;
      this.currentData.komoditi_nama = this.editedItem.komoditi_nama;
      this.currentData.qty = this.editedItem.qty;

      this.currentData.id = this.editedItem.id;
      console.log("do add");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      LoanService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus warehouse item berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus warehouse item gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openMaterialModal: async function () {
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    pilihMaterial: async function (item) {
      console.log(item);
      this.currentData.komoditi_id = item.id;
      this.currentData.komoditi_nama = item.nama;
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
