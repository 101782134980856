<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Penerimaan Barang
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari DOC No"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <router-link
            to="/goodreceipt-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="purple" dark class="mb-2"> Tambah GR</v-btn>
          </router-link>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.foto`]="{ item }">
            <v-img height="auto" width="100" :src="item.foto"></v-img>
          </template>
          <template v-slot:[`item.type`]="">
            <p>Good Receipt from PO</p>
          </template>
          <template v-slot:[`item.konten`]="{ item }">
            <p>{{ getKonten(item.konten) }}</p>
          </template>
          <template v-slot:[`item.tanggal`]="{ item }">
            {{ getCurrentDate(item.tanggal) }}
          </template>
          <template v-slot:[`item.qty_order`]=""> 100 </template>
          <template v-slot:[`item.qty_receive`]=""> 0 </template>
          <template v-slot:[`item.status_kelompok`]="{ item }">
            <p v-if="item.status_kelompok == 0">Tidak Aktif</p>
            <p v-else>Aktif</p>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu style="margin-left: 20px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium v-bind="attrs" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="openSOKelompok(item)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import GoodReceiveService from "../services/GoodReceiveService";
import CommonService from "../services/CommonService";
import * as moment from "moment";

export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data List GR",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Type", value: "transaction_type_value", sortable: false },
      { text: "Doc No", value: "good_receive_no", sortable: false },
      { text: "Tanggal", value: "tanggal", sortable: false },
      { text: "PO", value: "no_kegiatan", sortable: false },
      { text: "Warehouse", value: "warehouse_nama", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },
    getKonten(konten) {
      if (konten.length > 100) {
        return konten.substring(0, 100) + "...";
      } else {
        return konten;
      }
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      GoodReceiveService.goodReceiveList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/goodreceive-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      GoodReceiveService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus news berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus news gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openSOKelompok(item) {
      console.log(item);

      this.$router.push("/goodreceipt-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    openDelivery(item) {
      console.log(item);
      this.$router.push("/delivery/" + item.id).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>
