<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> WF Production </v-card-title>
        <form class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Nama"
                    outlined
                    dense
                    v-model="currentData.nama_wf"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Material"
                    outlined
                    dense
                    v-model="currentData.komoditi_nama"
                    readonly
                    @click="openMaterialModal()"
                  ></v-text-field>
                  <!-- <v-combobox
                    label="Tipe Komodity"
                    clearable
                    dense
                    outlined
                    v-model="currentData.selectedComodityType"
                    :items="items_comodity_type"
                    item-text="value"
                  ></v-combobox> -->
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <vuetify-money
                    label="Target Qty"
                    outlined
                    dense
                    required
                    v-model="currentData.target_qty"
                    :options="options"
                  />
                </v-col>

                <v-col cols="12" sm="4" md="4">
                  <v-combobox
                    label="Target UOM"
                    clearable
                    dense
                    outlined
                    v-model="currentData.selectedUom"
                    :items="items_uom"
                    item-text="code"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h3>List Proses</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20%" class="text-left">No Urut</th>
                          <th width="40%" class="text-left">Nama Proses</th>
                          <th width="20%" class="text-left">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in prosess" :key="i">
                          <td style="padding: 6px">
                            <v-text-field
                              outlined
                              dense
                              required
                              v-model="item.seq"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              outlined
                              dense
                              required
                              v-model="item.nama_proses"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-icon medium @click="deleteProses(i)">
                              mdi-delete
                            </v-icon>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>
                            <v-icon medium @click="addProses()">
                              mdi-plus
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </form>
      </v-card>
    </v-container>

    <v-container fluid>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab>BOM</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <BomComponent :id="id" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>

    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
    />
  </v-main>
</template>

<script>
import WfProductionService from "../services/WfProductionService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import MaterialSelectComponent from "../components/MaterialSelect.vue";

import BomComponent from "./Bom.vue";

export default {
  name: "WFProductionEdit",
  props: {
    id: String,
  },
  components: {
    MaterialSelectComponent,
    BomComponent,
  },
  data: () => ({
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    tab: null,
    dialogMaterial: false,
    breadcrumbs: [
      {
        text: "Data WF Production",
        disabled: false,
        exact: true,
        to: { name: "WFProduction" },
      },
      { text: "WF Production", disabled: true },
    ],
    currentData: {
      nama_wf: "",
      komoditi_id: "",
      komoditi_nama: "",
      selectedComodityType: "",
      target_qty: "",
      selectedUom: null,
    },
    prosess: [
      {
        nama_proses: "",
        seq: "",
      },
    ],
    result: {},
    items_comodity_type: [],
    items_uom: [],
  }),
  mounted: async function () {
    this.getComodityType();
    this.getUom();
    this.getWFProduction();
  },
  computed: {},
  methods: {
    async getUom() {
      MasterService.uomList()
        .then((res) => {
          this.items_uom = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getComodityType() {
      MasterService.charComodityTypeList()
        .then((res) => {
          this.items_comodity_type = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getWFProduction() {
      WfProductionService.getWfProduction(this.id)
        .then((res) => {
          this.currentData.nama_wf = res.data.data.nama_wf;
          this.currentData.komoditi_id = res.data.data.komoditi_id;
          this.currentData.komoditi_nama = res.data.data.komoditi_nama;
          this.currentData.target_qty = res.data.data.target_qty;

          if (res.data.data.char_comodity_type) {
            this.items_comodity_type.forEach((entry) => {
              if (entry.code == res.data.data.char_comodity_type) {
                this.currentData.selectedComodityType = entry;
              }
            });
          }

          if (res.data.data.target_uom) {
            this.items_uom.forEach((entry) => {
              if (entry.code == res.data.data.target_uom) {
                this.currentData.selectedUom = entry;
              }
            });
          }

          this.$nextTick(() => {
            this.getProses();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getProses() {
      WfProductionService.productionOrderItemProsesList(this.id)
        .then((res) => {
          this.prosess = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      WfProductionService.update(this.id, this.currentData, this.prosess)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Update WF Production berhasil");
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Update WF Production gagal. Coba lagi");
        });
    },

    openMaterialModal: async function () {
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    pilihMaterial: async function (item) {
      console.log(item);
      this.currentData.komoditi_id = item.id;
      this.currentData.komoditi_nama = item.nama;
    },

    addProses: async function () {
      this.prosess.push({
        seq: "",
        nama_proses: "",
      });
    },

    deleteProses: async function (index) {
      this.prosess.splice(index, 1);
    },
  },
};
</script>

<style scoped>
</style>
