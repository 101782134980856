<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Laporan Mutasi Barang
          <v-spacer></v-spacer>

          <!-- <router-link
            to="/productionorder-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="purple" dark class="mb-2"> Tambah PRO</v-btn>
          </router-link> -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-row dense class="pl-4 pr-4">
          <v-col cols="12" sm="4" md="4" class="pr-4">
            <v-text-field
              clearable
              label="Kelompok"
              outlined
              dense
              required
              v-model="currentData.kelompok_nama"
              readonly
              @click="openKelompokModal()"
              @click:clear="changeKelompok"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              clearable
              label="Warehouse"
              outlined
              dense
              required
              v-model="currentData.warehouse_nama"
              @click="openWarehouseModal"
              @click:clear="changeWarehouse"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-combobox
              label="Movement Type"
              clearable
              dense
              outlined
              v-model="currentData.selectedMovementType"
              :items="items_goods_movement"
              item-text="MV_DESC"
              v-on:change="changeMovementType"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row dense class="pl-4 pr-4">
          <v-col cols="12" sm="4" md="4" class="pr-4">
            <v-menu
              v-model="currentData.menuTanggal"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              dense
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedTanggal"
                  label="Transaction Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  dense
                  @click:clear="clearTanggal"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="currentData.tanggal"
                @input="changeTanggal"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              clearable
              label="Material"
              outlined
              dense
              required
              v-model="currentData.komoditi_nama"
              readonly
              @click="openMaterialModal"
              @click:clear="changeMaterial"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.foto`]="{ item }">
            <v-img height="auto" width="100" :src="item.foto"></v-img>
          </template>
          <template v-slot:[`item.qty`]="{ item }">
            <p v-if="item.type == 1">{{ item.qty }}</p>
            <p v-else>{{ "-" + item.qty }}</p>
          </template>
          <template v-slot:[`item.tanggal`]="{ item }">
            {{ getCurrentDate(item.tanggal) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu style="margin-left: 20px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium v-bind="attrs" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="openSOKelompok(item)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openWF(item)">
                  <v-list-item-title>View WF</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <KelompokSelectComponent
      @pilihKelompok="pilihKelompok"
      ref="modalKelompok"
      v-model="dialogKelompok"
    />

    <WarehouseSelectComponent
      @pilihWarehouse="pilihWarehouse"
      ref="modalWarehouse"
      v-model="dialogWarehouse"
      :id="currentData.kelompok_id"
    />

    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
    />
  </v-main>
</template>

<script>
import GoodsMovementService from "../services/GoodsMovementService";
import CommonService from "../services/CommonService";
import * as moment from "moment";
import KelompokSelectComponent from "../components/KelompokSelect.vue";
import WarehouseSelectComponent from "../components/WarehouseSelect.vue";
import MaterialSelectComponent from "../components/MaterialSelect.vue";
import MasterService from "../services/MasterService";

export default {
  components: {
    KelompokSelectComponent,
    WarehouseSelectComponent,
    MaterialSelectComponent,
  },
  data: () => ({
    dialogKelompok: false,
    dialogWarehouse: false,
    dialogMaterial: false,
    breadcrumbs: [
      {
        text: "Data Goods Movement",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Document No", value: "doc_no", sortable: false },
      { text: "Kelompok", value: "kelompok_nama", sortable: false },
      { text: "Warehouse", value: "warehouse_nama", sortable: false },
      { text: "Material", value: "komoditi_nama", sortable: false },
      { text: "Movement Type", value: "MV_DESC", sortable: false },
      { text: "Qty", value: "qty", sortable: false },
      { text: "UOM", value: "uom", sortable: false },
      { text: "Tanggal", value: "tanggal", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      kelompok_id: "",
      kelompok_nama: "",
      warehouse_id: "",
      warehouse_nama: "",
      selectedMovementType: "",
      komoditi_id: "",
      komoditi_nama: "",
      tanggal: "",
    },
    items_goods_movement: [],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  computed: {
    computedTanggal() {
      return this.currentData.tanggal
        ? moment(this.currentData.tanggal).format("DD MMMM YYYY")
        : "";
    },
  },
  mounted() {
    this.getGoodsMovement();
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },

    async getGoodsMovement() {
      MasterService.goodMovementList()
        .then((res) => {
          this.items_goods_movement = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getKonten(konten) {
      if (konten.length > 100) {
        return konten.substring(0, 100) + "...";
      } else {
        return konten;
      }
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;

      var movement_type = "";

      if (this.currentData.selectedMovementType) {
        movement_type = this.currentData.selectedMovementType.MV_ID;
      }

      GoodsMovementService.goodsMovementList(
        page,
        itemsPerPage,
        this.search,
        this.currentData.kelompok_id,
        this.currentData.warehouse_id,
        movement_type,
        this.currentData.komoditi_id,
        this.currentData.tanggal
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/productionorder-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      GoodsMovementService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus Production Order berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Hapus Production Order gagal. Coba lagi"
          );
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openSOKelompok(item) {
      console.log(item);

      this.$router.push("/productionorder-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    openDelivery(item) {
      console.log(item);
      this.$router.push("/delivery/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    openWF(item) {
      this.$router.push("/productionorder-wf/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    openKelompokModal: async function () {
      this.$refs.modalKelompok.getDataFromApi();
      this.dialogKelompok = true;
    },

    pilihKelompok: async function (item) {
      this.currentData.kelompok_id = item.id;
      this.currentData.kelompok_nama = item.nama;

      this.currentData.warehouse_id = "";
      this.currentData.warehouse_nama = "";

      this.getDataFromApi();
    },

    changeKelompok(a) {
      console.log(a);
      this.currentData.kelompok_id = "";
      this.currentData.warehouse_id = "";
      this.currentData.warehouse_nama = "";
      this.getDataFromApi();
    },

    openWarehouseModal: async function () {
      this.$refs.modalWarehouse.getDataFromApi();
      this.dialogWarehouse = true;
    },

    pilihWarehouse: async function (item) {
      this.currentData.warehouse_id = item.id;
      this.currentData.warehouse_nama = item.nama;

      this.getDataFromApi();
    },

    changeWarehouse(a) {
      console.log(a);
      this.currentData.warehouse_id = "";
      this.getDataFromApi();
    },

    openMaterialModal: async function () {
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    pilihMaterial: async function (item) {
      this.currentData.komoditi_id = item.id;
      this.currentData.komoditi_nama = item.nama;

      this.getDataFromApi();
    },

    changeMaterial(a) {
      console.log(a);
      this.currentData.komoditi_id = "";
      this.getDataFromApi();
    },

    changeMovementType(a) {
      console.log(a);
      this.getDataFromApi();
    },

    changeTanggal(a) {
      console.log(a);

      this.currentData.menuTanggal = false;
      this.$nextTick(() => {
        this.getDataFromApi();
      });
    },

    clearTanggal(a) {
      console.log(a);
      this.currentData.tanggal = "";
      this.getDataFromApi();
    },
  },
};
</script>
