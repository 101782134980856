<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Material
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-dialog
            id="modalMaterial"
            ref="myModal"
            v-model="dialog"
            persistent
            max-width="600px"
            scrollable
          >
            <template v-slot:activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Material</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" lazy-validation class="pl-4 pr-4">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Kode"
                          required
                          v-model="currentData.kode"
                          :rules="currentData.kodeRules"
                        ></v-text-field>
                        <v-text-field
                          label="Nama"
                          required
                          v-model="currentData.nama"
                          :rules="currentData.namaRules"
                        ></v-text-field>
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Deskripsi"
                          dense
                          value=""
                          rows="3"
                          v-model="currentData.deskripsi"
                        ></v-textarea>
                        <v-text-field
                          label="Char Comodity Type"
                          required
                          v-model="currentData.char_comodity_type"
                        ></v-text-field>
                        <v-text-field
                          label="Char Comodity Subty"
                          required
                          v-model="currentData.char_comodity_subty"
                        ></v-text-field>
                        <v-text-field
                          label="Char Origin"
                          required
                          v-model="currentData.char_origin"
                        ></v-text-field>
                        <v-text-field
                          label="Char Variety"
                          required
                          v-model="currentData.char_variety"
                        ></v-text-field>
                        <v-text-field
                          label="Char Phase"
                          required
                          v-model="currentData.char_phase"
                        ></v-text-field>
                        <v-text-field
                          label="Char Process"
                          required
                          v-model="currentData.char_process"
                        ></v-text-field>
                        <v-text-field
                          label="Grade"
                          required
                          v-model="currentData.grade"
                        ></v-text-field>
                        <vuetify-money
                          required
                          v-model="currentData.selling_price"
                          :options="options_money"
                          label="Selling Price"
                        />
                        <vuetify-money
                          required
                          v-model="currentData.production_price"
                          :options="options_money"
                          label="Production Price"
                        />
                        <v-combobox
                          label="Material Type"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedMaterialType"
                          :items="items_materialtype"
                          item-text="value"
                          :rules="currentData.materialTypeRules"
                        ></v-combobox>
                        <v-combobox
                          label="UOM"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedUom"
                          :items="items_uom"
                          item-text="code"
                          :rules="currentData.uomRules"
                        ></v-combobox>
                        <v-combobox
                          label="Group Material"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedGroupMaterial"
                          :items="items_group_material"
                          item-text="nama"
                          :rules="currentData.materialGroupRules"
                        ></v-combobox>
                        <v-combobox
                          label="Generic Comodity"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedGenericComodity"
                          :items="items_generic_comodity"
                          item-text="nama"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          show-expand
        >
          <template v-slot:[`item.selling_price`]="{ item }">
            <span v-if="item.selling_price">{{
              numberWithCommas(item.selling_price)
            }}</span>
          </template>
          <template v-slot:[`item.production_price`]="{ item }">
            <span v-if="item.production_price">{{
              numberWithCommas(item.production_price)
            }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length">
              <table>
                <tr>
                  <th width="40%" style="text-align: left">Deskripsi</th>
                  <td>{{ item.deskripsi }}</td>
                </tr>
                <tr>
                  <th style="text-align: left">Char Comodity Type</th>
                  <td>{{ item.char_comodity_type }}</td>
                </tr>
                <tr>
                  <th style="text-align: left">Char Comodity Subty</th>
                  <td>{{ item.char_comodity_subty }}</td>
                </tr>
                <tr>
                  <th style="text-align: left">Char Origin</th>
                  <td>{{ item.char_origin }}</td>
                </tr>
                <tr>
                  <th style="text-align: left">Char Variety</th>
                  <td>{{ item.char_variety }}</td>
                </tr>
                <tr>
                  <th style="text-align: left">Char Phase</th>
                  <td>{{ item.char_phase }}</td>
                </tr>
                <tr>
                  <th style="text-align: left">Char Process</th>
                  <td>{{ item.char_process }}</td>
                </tr>
                <tr>
                  <th style="text-align: left">Grade</th>
                  <td>{{ item.grade }}</td>
                </tr>
              </table>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import MaterialService from "../services/MaterialService";
import MasterService from "../services/MasterService";
import CommonService from "../services/CommonService";
import GroupMaterialService from "../services/GroupMaterialService";

export default {
  data: () => ({
    options_money: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    singleExpand: true,
    expanded: [],
    breadcrumbs: [
      {
        text: "Data Material",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Kode", value: "kode", sortable: false },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Selling Price", value: "selling_price", sortable: false },
      { text: "Production Price", value: "production_price", sortable: false },
      { text: "Tipe", value: "material_type_value", sortable: false },
      { text: "UOM", value: "uom", sortable: false },
      { text: "Group Material", value: "group_material_nama", sortable: false },
      {
        text: "Generic Comodity",
        value: "generic_comodity_value",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      kode: "",
      nama: "",
      deskripsi: "",
      char_comodity_type: "",
      char_comodity_subty: "",
      char_origin: "",
      char_variety: "",
      char_phase: "",
      char_process: "",
      grade: "",
      selling_price: "",
      production_price: "",
      material_type: "",
      uom: "",
      group_material_id: "",
      generic_comodity: "",
      selectedMaterialType: "",
      selectedUom: "",
      selectedGroupMaterial: "",
      selectedGenericComodity: "",
      selectedCharComodityType: "",
      selectedCharComoditySubty: "",
      kodeRules: [(v) => !!v || "Kode harus diisi"],
      namaRules: [(v) => !!v || "Nama harus diisi"],
      materialTypeRules: [(v) => !!v || "Material Type harus diisi"],
      materialGroupRules: [(v) => !!v || "Material Group harus diisi"],
      uomRules: [(v) => !!v || "UOM harus diisi"],
    },
    items_materialtype: [],
    items_uom: [],
    items_group_material: [],
    items_generic_comodity: [],
    items_char_comodity_type: [],
    items_char_comodity_subty: [],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getMaterialType();
    this.getUom();
    this.getGroupMaterial();
    this.getGenericComodity();
    this.getCharComodityType();
    this.getCharComoditySubty();
    this.getDataFromApi();
  },
  methods: {
    async getMaterialType() {
      MasterService.materialTypeList()
        .then((res) => {
          this.items_materialtype = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getCharComodityType() {
      MasterService.charComodityTypeList()
        .then((res) => {
          this.items_char_comodity_type = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getCharComoditySubty() {
      MasterService.charComoditySubtyList()
        .then((res) => {
          this.items_char_comodity_subty = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getUom() {
      MasterService.uomList()
        .then((res) => {
          this.items_uom = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getGenericComodity() {
      MaterialService.materialGenericList()
        .then((res) => {
          this.items_generic_comodity = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getGroupMaterial() {
      GroupMaterialService.groupMaterialAll()
        .then((res) => {
          this.items_group_material = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      MaterialService.materialList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;

      this.currentData.kode = "";
      this.currentData.nama = "";
      this.currentData.deskripsi = "";
      this.currentData.char_comodity_type = "";
      this.currentData.char_comodity_subty = "";
      this.currentData.char_origin = "";
      this.currentData.char_variety = "";
      this.currentData.char_phase = "";
      this.currentData.char_process = "";
      this.currentData.grade = "";
      this.currentData.selling_price = "";
      this.currentData.production_price = "";

      this.currentData.selectedMaterialType = "";
      this.currentData.selectedUom = "";
      this.currentData.selectedGroupMaterial = "";
      this.currentData.selectedGenericComodity = "";

      this.currentData.id = null;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });
        // console.log(this.$refs.myModal.scrollTop);
        return;
      }

      if (this.currentData.id == null) {
        MaterialService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah material berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah material gagal. Coba lagi");
          });
      } else {
        MaterialService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update material berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update material gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.kode = this.editedItem.kode;
      this.currentData.nama = this.editedItem.nama;
      this.currentData.deskripsi = this.editedItem.deskripsi;
      this.currentData.char_comodity_type = this.editedItem.char_comodity_type;
      this.currentData.char_comodity_subty =
        this.editedItem.char_comodity_subty;
      this.currentData.char_origin = this.editedItem.char_origin;
      this.currentData.char_variety = this.editedItem.char_variety;
      this.currentData.char_phase = this.editedItem.char_phase;
      this.currentData.char_process = this.editedItem.char_process;
      this.currentData.grade = this.editedItem.grade;
      this.currentData.selling_price = this.editedItem.selling_price;
      this.currentData.production_price = this.editedItem.production_price;

      if (this.editedItem.material_type) {
        this.items_materialtype.forEach((entry) => {
          if (entry.code == this.editedItem.material_type) {
            this.currentData.selectedMaterialType = entry;
          }
        });
      }

      if (this.editedItem.uom) {
        this.items_uom.forEach((entry) => {
          if (entry.code == this.editedItem.uom) {
            this.currentData.selectedUom = entry;
          }
        });
      }

      if (this.editedItem.group_material_id) {
        this.items_group_material.forEach((entry) => {
          if (entry.id == this.editedItem.group_material_id) {
            this.currentData.selectedGroupMaterial = entry;
          }
        });
      }

      this.currentData.selectedGenericComodity = null;
      if (this.editedItem.generic_comodity) {
        this.items_generic_comodity.forEach((entry) => {
          if (entry.id == this.editedItem.generic_comodity) {
            this.currentData.selectedGenericComodity = entry;
          }
        });
      }

      this.currentData.id = this.editedItem.id;
      console.log("do add");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      MaterialService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus material berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus material gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style type="text/css">
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 0.87rem;
  height: 48px;
}
</style>