import http from "../http-common";
/* eslint-disable */
class StockReportService {
  stockReportList(
    page,
    itemPerPage,
    keyword,
    kelompokId,
    warehouseId,
    materialType,
    komoditiId
  ) {
    return http.get(
      "/stockreport/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kelompok_id=" +
        kelompokId +
        "&warehouse_id=" +
        warehouseId +
        "&material_type=" +
        materialType +
        "&komoditi_id=" +
        komoditiId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }
}

export default new StockReportService();
