import http from "../http-common";
/* eslint-disable */
class ProductionOrderService {
  productionOrderList(page, itemPerPage, keyword) {
    return http.get(
      "/productionorder/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  poAnggotaToGoodReceiveList(kegiatanId) {
    return http.get(
      "/poanggota-to-goodreceive/list?kegiatan_id=" + kegiatanId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  productionOrderItemList(purchaseOrderId) {
    return http.get(
      "/productionorder-item/list?purchase_order_id=" + purchaseOrderId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  productionOrderItemSavedList(proId) {
    return http.get("/productionorder-item-saved/list?pro_id=" + proId, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  productionOrderItemRawList(itemId) {
    return http.get(
      "/productionorder-item-raw/list?production_order_item_id=" + itemId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  productionOrderItemProsesList(itemId, wfProdId) {
    return http.get(
      "/wfproduction-proses/list?id=" + itemId + "&wf_prod_id=" + wfProdId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getProductionOrder(id) {
    return http.get(`/productionorder/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, materials, materials_raw) {
    var bodyFormData = new FormData();

    bodyFormData.append("purchase_order_id", data.purchase_order_id);
    bodyFormData.append("tanggal", data.tanggal);
    bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("harus_selesai_sebelum", data.harus_selesai_sebelum);
    bodyFormData.append(
      "perkiraan_akhir_produksi",
      data.perkiraan_akhir_produksi
    );

    if (data.selectedProdType && data.selectedProdType != undefined) {
      bodyFormData.append("prod_type", data.selectedProdType.code);
    }

    bodyFormData.append("materials", JSON.stringify(materials));
    bodyFormData.append("materials_raw", JSON.stringify(materials_raw));

    return http.post("/productionorder", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  update(id, data, materials, materials_raw) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    bodyFormData.append("purchase_order_id", data.purchase_order_id);
    bodyFormData.append("tanggal", data.tanggal);

    bodyFormData.append("deskripsi", data.deskripsi);
    bodyFormData.append("harus_selesai_sebelum", data.harus_selesai_sebelum);
    bodyFormData.append(
      "perkiraan_akhir_produksi",
      data.perkiraan_akhir_produksi
    );

    if (data.selectedProdType && data.selectedProdType != undefined) {
      bodyFormData.append("prod_type", data.selectedProdType.code);
    }

    bodyFormData.append("materials", JSON.stringify(materials));
    bodyFormData.append("materials_raw", JSON.stringify(materials_raw));

    return http.put(`/productionorder/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  createWf(data) {
    var bodyFormData = new FormData();

    bodyFormData.append(
      "production_order_item_id",
      data.production_order_item_id
    );
    bodyFormData.append("wf_detail_id", data.wf_detail_id);
    bodyFormData.append("qty_ok", data.qty_ok);
    bodyFormData.append("qty_ng", data.qty_ng);
    bodyFormData.append("qty_order", data.qty_order);
    bodyFormData.append("status", data.selectedProdStatus.code);

    return http.post("/productionorderwf", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  updateWf(id, data) {
    var bodyFormData = new FormData();

    bodyFormData.append("id", id);

    bodyFormData.append(
      "production_order_item_id",
      data.production_order_item_id
    );
    bodyFormData.append("wf_detail_id", data.wf_detail_id);
    bodyFormData.append("qty_ok", data.qty_ok);
    bodyFormData.append("qty_ng", data.qty_ng);
    bodyFormData.append("qty_order", data.qty_order);
    bodyFormData.append("status", data.selectedProdStatus.code);

    return http.put("/productionorderwf/" + id, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  delete(id) {
    return http.delete(`/productionorder/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new ProductionOrderService();
