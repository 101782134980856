import AuthService from "../services/AuthService";
import Vue from "vue";
import Vuex from "vuex";
import http from "../http-common";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX) || "",
    user: {},
    chat_list_user_name: [],
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { token, user }) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.user = {};
    },
    set_chat_username(state, { id, nama }) {
      state.chat_list_user_name.push({ id: id, nama: nama });
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        AuthService.login(user)
          .then((resp) => {
            const token = resp.data.token.accessToken;
            const user = resp.data.user;
            const permission = resp.data.list_permission;
            localStorage.setItem(process.env.VUE_APP_TOKEN_PREFIX, token);
            localStorage.setItem(
              process.env.VUE_APP_USER_PREFIX,
              JSON.stringify(user)
            );
            localStorage.setItem(
              process.env.VUE_APP_USER_PERMISSION,
              JSON.stringify(permission)
            );
            // Add the following line:
            http.defaults.headers.common["Authorization"] = token;
            commit("auth_success", { token, user });
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem(process.env.VUE_APP_TOKEN_PREFIX);
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem(process.env.VUE_APP_TOKEN_PREFIX);
        delete http.defaults.headers.common["Authorization"];
        resolve(null);
      });
    },
    setChatUsername({ commit }, { id, nama }) {
      return new Promise((resolve) => {
        commit("set_chat_username", { id, nama });
        resolve(null);
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    getUser: (state) => state.user,
    getUserNameChat: (state) => (id) =>
      state.chat_list_user_name.filter((user) => user.id == id),
  },
  modules: {},
});
