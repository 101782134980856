<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Order ke Anggota </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-text-field
                label="Nomor Order"
                outlined
                dense
                required
                readonly
                value="GENERATED"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-combobox
                label="Tipe Order"
                dense
                outlined
                v-model="currentData.selectedPOType"
                :items="items_po_type"
                item-text="value"
                :rules="currentData.poTypeRules"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="currentData.menuTanggal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggal"
                    label="Tanggal Transaksi"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.tanggalRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.tanggal"
                  @input="currentData.menuTanggal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Currency"
                outlined
                dense
                required
                v-model="currentData.currency"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Anggota"
                outlined
                dense
                required
                v-model="currentData.user_nama"
                readonly
                @click="openCustomerModal"
                :rules="currentData.anggotaRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-checkbox
                v-if="
                  currentData.selectedPOType &&
                  currentData.selectedPOType.code == 1
                "
                v-model="currentData.is_auto"
                label="Lakukan penerimaan barang secara otomatis"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="
                  currentData.selectedPOType &&
                  currentData.selectedPOType.code == 1 &&
                  currentData.is_auto == 1
                "
                label="Warehouse"
                outlined
                dense
                required
                v-model="currentData.warehouse_nama"
                @click="openWarehouseModal"
                :rules="currentData.warehouseRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="30%" class="text-left">Material</th>
                      <th width="10%" class="text-left">Qty Order</th>
                      <th width="10%" class="text-left">Satuan</th>
                      <th width="20%" class="text-left">Harga</th>
                      <th width="20%" class="text-left">Total</th>
                      <!-- <th width="10%" class="text-left">Qty Receive</th> -->
                      <!-- <th width="10%" class="text-left">Status</th> -->
                      <th width="20%" class="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in materials" :key="i">
                      <td style="padding: 6px">
                        <v-textarea
                          outlined
                          v-model="item.komoditi_nama"
                          rows="2"
                          readonly
                          row-height="3"
                          @click="openMaterialModal(i)"
                          :rules="item.materialRules"
                        ></v-textarea>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.qty"
                          :options="options"
                          :rules="item.qtyRules"
                        />
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.uom"
                          readonly
                        ></v-text-field>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.price"
                          readonly
                          @change="calculateTotal(i)"
                          :options="options"
                          :rules="item.priceRules"
                        />
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.total"
                          disabled
                          :options="options"
                        />
                      </td>
                      <!-- <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.qty_receive"
                          disabled
                          :options="options"
                        />
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.status"
                          disabled
                        ></v-text-field>
                      </td> -->
                      <td>
                        <v-icon medium @click="deleteMaterial(i)">
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <v-icon medium @click="addMaterial()">
                          mdi-plus
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6"> </v-col>
            <v-col>
              <h4 class="v-heading text-h7 mb-2">Total</h4>
            </v-col>
            <v-col>
              <vuetify-money
                outlined
                dense
                required
                v-model="currentData.total"
                readonly
                :options="options"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
    <UserSelectComponent
      @pilihUser="pilihAnggota"
      ref="modalCustomer"
      v-model="dialogCustomer"
    />
    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
    />

    <MaterialServiceSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalServiceMaterial"
      v-model="dialogServiceMaterial"
    />
    <WarehouseSelectComponent
      @pilihWarehouse="pilihWarehouse"
      ref="modalWarehouse"
      v-model="dialogWarehouse"
      :id="currentData.kelompok_id"
    />

    <v-dialog v-model="dialogSave" max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          Anda yakin mau menyimpan dokumen ini?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialogSave = false">
            Batal
          </v-btn>

          <v-btn color="blue darken-1" text @click="doSave"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import KegiatanService from "../services/KegiatanService";
import MasterService from "../services/MasterService";
import MaterialService from "../services/MaterialService";
import moment from "moment";
// import { format, parseISO } from "date-fns";

import UserSelectComponent from "../components/UserSelectByKelompok.vue";
import MaterialSelectComponent from "../components/MaterialCFRUSelect.vue";
import MaterialServiceSelectComponent from "../components/MaterialServiceSelect.vue";
import WarehouseSelectComponent from "../components/WarehouseAllSelect.vue";

export default {
  name: "OrderToAnggotaAdd",
  components: {
    UserSelectComponent,
    MaterialSelectComponent,
    WarehouseSelectComponent,
    MaterialServiceSelectComponent,
  },
  data: () => ({
    dialogSave: false,
    dialogWarehouse: false,
    dialogServiceMaterial: false,
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    dialogCustomer: false,
    dialogMaterial: false,
    breadcrumbs: [
      {
        text: "Data PO",
        disabled: false,
        exact: true,
        to: { name: "OrderToAnggota" },
      },
      { text: "Tambah PO", disabled: true },
    ],
    valid: true,
    currentData: {
      is_auto: 1,
      no_kegiatan: "",
      menuTanggal: false,
      tanggal: "",
      user_id: "",
      user_nama: "",
      status_transaksi: "",
      total: "",
      currency: "",
      selectedPOType: "",
      warehouse_id: "",
      warehouse_nama: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      poTypeRules: [(v) => !!v || "PO Type harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal harus diisi"],
      anggotaRules: [(v) => !!v || "Anggota harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
      warehouseRules: [(v) => !!v || "Warehouse harus diisi"],
    },
    items_po_type: [],
    materials: [
      {
        komoditi_id: "",
        komoditi_nama: "",
        qty: "",
        price: "",
        uom: "",
        total: "",
        materialRules: [(v) => !!v || "Material harus diisi"],
        qtyRules: [(v) => !!v || "Qty harus diisi"],
        priceRules: [(v) => !!v || "Price harus diisi"],
      },
    ],
    material_service: "",
    selectedMaterialIndex: 0,
    items_metode_pembayaran: [],
    fotoKey: 0,
  }),
  watch: {
    materials: {
      handler() {
        this.calculateTotalAmount();
      },
      deep: true,
    },
  },
  mounted: async function () {
    this.getMetodePembayaran();
    this.getPoType();
    this.getMaterialService();
    this.currentData.currency = "IDR";
    this.currentData.tanggal = moment(new Date()).format("YYYY-MM-DD");
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
    computedTanggal() {
      return this.currentData.tanggal
        ? moment(this.currentData.tanggal).format("DD MMMM YYYY")
        : "";
    },
    computedCRD() {
      return this.currentData.customer_request_date
        ? moment(this.currentData.customer_request_date).format("DD MMMM YYYY")
        : "";
    },
    computedEDD() {
      return this.currentData.expected_delivery_date
        ? moment(this.currentData.expected_delivery_date).format("DD MMMM YYYY")
        : "";
    },
    computedHRD() {
      return this.currentData.expected_ho_received_date
        ? moment(this.currentData.expected_ho_received_date).format(
            "DD MMMM YYYY"
          )
        : "";
    },
  },
  methods: {
    async getPoType() {
      MasterService.poTypeList()
        .then((res) => {
          this.items_po_type = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMetodePembayaran() {
      MasterService.metodePembayaranList()
        .then((res) => {
          this.items_metode_pembayaran = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMaterialService() {
      MaterialService.getMaterialService()
        .then((res) => {
          this.material_service = res.data.material_service;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      this.dialogSave = true;
    },

    doSave: async function () {
      KegiatanService.create(this.currentData, this.materials)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Order to Anggota berhasil");

          this.$router
            .push("/ordertoanggota-edit/" + res.data.kegiatan.id)
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Tambah Order to Anggota gagal. Coba lagi"
          );
        });
    },

    openCustomerModal: async function () {
      this.$refs.modalCustomer.getDataFromApi();
      this.dialogCustomer = true;
    },

    openMaterialModal: async function (index) {
      this.selectedMaterialIndex = index;

      if (
        this.currentData.selectedPOType &&
        this.currentData.selectedPOType.code == 1
      ) {
        this.$refs.modalMaterial.getDataFromApi();
        this.dialogMaterial = true;
      } else {
        this.$refs.modalServiceMaterial.getDataFromApi();
        this.dialogServiceMaterial = true;
      }
    },

    pilihAnggota: async function (item) {
      console.log(item);
      this.currentData.user_id = item.id;
      this.currentData.user_nama = item.nama;
    },

    pilihMaterial: async function (item) {
      console.log(item);
      this.materials[this.selectedMaterialIndex].komoditi_id = item.id;
      this.materials[this.selectedMaterialIndex].komoditi_nama = item.nama;
      this.materials[this.selectedMaterialIndex].uom = item.uom;

      if (
        this.currentData.selectedPOType &&
        this.currentData.selectedPOType.code == 1
      ) {
        this.materials[this.selectedMaterialIndex].price =
          item.production_price;
      } else {
        console.log(this.material_service);
        this.materials[this.selectedMaterialIndex].price =
          this.material_service.production_price;
      }
      this.calculateTotal(this.selectedMaterialIndex);
    },

    addMaterial: async function () {
      this.materials.push({
        komoditi_id: "",
        komoditi_nama: "",
        qty: "",
        price: "",
        uom: "",
        total: "",
        materialRules: [(v) => !!v || "Material harus diisi"],
        qtyRules: [(v) => !!v || "Qty harus diisi"],
        priceRules: [(v) => !!v || "Price harus diisi"],
      });
    },

    deleteMaterial: async function (index) {
      this.materials.splice(index, 1);
    },

    calculateTotal: async function (index) {
      if (this.materials[index].qty && this.materials[index].price) {
        this.materials[index].total =
          this.materials[index].qty * this.materials[index].price;
      }

      var net = 0;
      for (var i = 0; i < this.materials.length; i++) {
        if (this.materials[i].qty && this.materials[i].price) {
          net += this.materials[i].qty * this.materials[i].price;
        }
      }

      console.log(net);

      this.currentData.total = net;
    },

    calculateTotalAmount: async function () {
      var net = 0;
      for (var i = 0; i < this.materials.length; i++) {
        if (this.materials[i].qty && this.materials[i].price) {
          this.materials[i].total =
            this.materials[i].qty * this.materials[i].price;

          net += this.materials[i].qty * this.materials[i].price;
        }
      }

      this.currentData.total = net;
    },

    openWarehouseModal: async function () {
      this.$refs.modalWarehouse.getDataFromApi();
      this.dialogWarehouse = true;
    },

    pilihWarehouse: async function (item) {
      console.log(item);
      this.currentData.warehouse_id = item.id;
      this.currentData.warehouse_nama = item.nama;
    },
  },
};
</script>

<style scoped>
.small-font {
  font-size: 12px;
}
</style>
