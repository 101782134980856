<template>
  <div>
    <v-card>
      <v-card-title>
        Order Pembelian ke Kelompok
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :key="table_sales_order_id"
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
        :single-select="true"
        show-select
        v-model="selectedRows"
        @input="openSelectedRow"
      >
        <template v-slot:[`item.po_date`]="{ item }">
          {{ getCurrentDate(item.po_date) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="ma-2" color="primary" dark @click.stop="pilih(item)">
            Pilih
            <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import PurchaseOrderService from "../../services/PurchaseOrderService";
import CommonService from "../../services/CommonService";
import * as moment from "moment";

export default {
  name: "POKelompokTable",
  props: {
    id: String,
    po_id: String,
  },
  data: () => ({
    table_sales_order_id: 0,
    dialogCustomer: false,
    search: "",
    selectedRows: [],
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Kelompok", value: "kelompok_nama", sortable: false },
      { text: "No Order Pembelian", value: "po_no", sortable: false },
      { text: "Tanggal Order", value: "po_date", sortable: false },
      {
        text: "Order Penjualan Kelompok",
        value: "so_no_kelompok",
        sortable: false,
      },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 5,
    },
    materials: [],
    selectedIndex: 0,
  }),
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    openSelectedRow() {
      console.log(this.selectedRows[0]);
      this.$emit("searchSalesOrderKelompok", this.selectedRows[0]);
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },
    updateQtyTerpenuhi(materials, selectedIndex) {
      this.materials = materials;
      this.selectedIndex = selectedIndex;
      this.getDataFromApi();
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PurchaseOrderService.purchaseOrderBySalesOrderIdList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;

          var index = 0;

          if (this.po_id == 0) {
            this.selectedRows.push(this.list_data[0]);
          } else {
            this.list_data.forEach((entry) => {
              console.log(entry.id + " - " + this.po_id);

              if (entry.id == this.po_id) {
                this.selectedRows.push(entry);
              }

              var result = this.materials.filter((material) => {
                return parseInt(material.komoditi_id) == entry.komoditi_id;
              });

              if (result.length > 0) {
                if (result[0].qty) {
                  entry.qty_terpenuhi_lokal = result[0].qty;

                  if (
                    result[0].qty > entry.qty &&
                    this.selectedIndex == index
                  ) {
                    CommonService.showErrorToast(
                      "Qty assign melebihi Qty Order"
                    );
                  }
                }
              } else {
                entry.qty_terpenuhi_lokal = 0;
              }

              index++;
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
