<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>404 Page </v-card-title>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data: () => ({
    breadcrumbs: [
      {
        text: "Data 404",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
  }),
  mounted() {},
  methods: {},
};
</script>
