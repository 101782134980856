<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Invoice
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-dialog
            id="modalMaterial"
            ref="myModal"
            v-model="dialog"
            persistent
            max-width="600px"
            scrollable
          >
            <template v-slot:activator="{}">
              <v-btn
                style="margin-left: 16px"
                color="primary"
                dark
                @click="doInvoice"
              >
                Generate Invoice
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Invoice</span>
              </v-card-title>
              <v-card-text>
                <v-form ref="form" lazy-validation class="pl-4 pr-4">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Order Penjualan"
                          outlined
                          dense
                          required
                          v-model="currentData.sales_order_no"
                          readonly
                          @click="openSOModal"
                          :rules="currentData.orderPenjualanRules"
                        ></v-text-field>
                        <vuetify-money
                          required
                          v-model="currentData.jumlah_full_tagihan"
                          :options="options_money"
                          label="Jumlah Full Tagihan"
                          readonly
                        />
                        <vuetify-money
                          required
                          v-model="currentData.sisa_tagihan"
                          :options="options_money"
                          label="Sisa belum ditagih"
                          readonly
                        />
                        <v-text-field
                          label="Nomor Invoice"
                          required
                          :rules="currentData.invoiceNoRules"
                          value="GENERATED"
                          readonly
                        ></v-text-field>
                        <v-combobox
                          label="Tipe Tagihan"
                          clearable
                          dense
                          outlined
                          v-model="currentData.selectedTipeTagihan"
                          :items="items_tipetagihan"
                          item-text="value"
                          :rules="currentData.tipeTagihanRules"
                          @change="changeTipeTagihan"
                        ></v-combobox>
                        <vuetify-money
                          required
                          v-model="currentData.jumlah_tagihan"
                          :options="options_money"
                          label="Jumlah Penagihan"
                          :readonly="
                            currentData.selectedTipeTagihan != null &&
                            currentData.selectedTipeTagihan.code == 2
                          "
                          :rules="currentData.jumlahTagihanRules"
                        />
                        <vuetify-money
                          required
                          v-model="currentData.diskon"
                          :options="options_money"
                          label="Diskon"
                        />
                        <v-menu
                          v-model="currentData.menuTanggalJatuhTempo"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          dense
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="computedTanggalJatuhTempo"
                              label="Tanggal Jatuh Tempo Pembayaran"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              :rules="currentData.tanggalJatuhTempoRules"
                              style="margin-top: 16px"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="currentData.tanggalJatuhTempo"
                            @input="currentData.menuTanggalJatuhTempo = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.jumlah_tagihan`]="{ item }">
            <span v-if="item.jumlah_tagihan">{{
              numberWithCommas(item.jumlah_tagihan)
            }}</span>
          </template>
          <template v-slot:[`item.total_bayar`]="{ item }">
            <span>{{
              numberWithCommas(item.jumlah_tagihan - item.diskon)
            }}</span>
          </template>
          <template v-slot:[`item.tanggal_invoice`]="{ item }">
            {{ getCurrentDate(item.tanggal_invoice) }}
          </template>
          <template v-slot:[`item.tanggal_jatuh_tempo_pembayaran`]="{ item }">
            {{ getCurrentDate(item.tanggal_jatuh_tempo_pembayaran) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="row">
              <v-menu style="margin-right: 20px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon medium v-bind="attrs" v-on="on">
                    mdi-dots-vertical
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item @click="downloadInvoice(item)">
                    <v-list-item-title>Download Invoice</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="downloadSuratJalan(item)">
                    <v-list-item-title>Download Surat Jalan</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-icon
                style="margin-left: 15px"
                medium
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <SalesOrderSelectComponent
      @pilihSO="pilihSO"
      ref="modalSO"
      v-model="dialogSO"
    />
  </v-main>
</template>

<script>
import InvoiceService from "../services/InvoiceService";
import ReportService from "../services/ReportService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import moment from "moment";
import SalesOrderSelectComponent from "../components/SalesOrderSelect.vue";
import SalesOrderService from "../services/SalesOrderService";

export default {
  components: {
    SalesOrderSelectComponent,
    // SalesOrderItemTableComponent,
  },
  data: () => ({
    dialogSO: false,
    options_money: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    breadcrumbs: [
      {
        text: "Data Invoice",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nomor Invoice", value: "invoice_no", sortable: false },
      { text: "Order No", value: "order_no", sortable: false },
      { text: "Jumlah Tagihan", value: "jumlah_tagihan", sortable: false },
      { text: "Diskon", value: "diskon", sortable: false },
      { text: "Total Bayar", value: "total_bayar", sortable: false },
      { text: "Tipe Tagihan", value: "tipe_tagihan_value", sortable: false },
      { text: "Tanggal Invoice", value: "tanggal_invoice", sortable: false },
      {
        text: "Status Invoice",
        value: "status_invoice_value",
        sortable: false,
      },
      {
        text: "Tanggal Jatuh Tempo Pembayaran",
        value: "tanggal_jatuh_tempo_pembayaran",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 10,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      id: null,
      sales_order_id: "",
      sales_order_no: "",
      jumlah_full_tagihan: "",
      sisa_tagihan: "",
      invoice_no: "",
      jumlah_tagihan: "",
      diskon: "",
      selectedTipeTagihan: "",
      tanggalInvoice: "",
      menuTanggalInvoice: false,
      tanggalJatuhTempo: "",
      menuTanggalJatuhTempo: false,
      selectedStatusInvoiceTagihan: "",
      orderPenjualanRules: [(v) => !!v || "Order Penjualan harus diisi"],
      invoiceNoRules: [(v) => !!v || "Nomor Invoice harus diisi"],
      jumlahTagihanRules: [(v) => !!v || "Jumlah Tagihan harus diisi"],
      tipeTagihanRules: [(v) => !!v || "Tipe Tagihan harus diisi"],
      tanggalInvoiceRules: [(v) => !!v || "Tanggal Invoice harus diisi"],
      tanggalJatuhTempoRules: [
        (v) => !!v || "Tanggal Jatuh Tempo Pembayaran harus diisi",
      ],
      statusInvoiceTagihanRules: [(v) => !!v || "Status Invoice harus diisi"],
    },

    items_tipetagihan: [],
    items_statusinvoicetagihan: [],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getTipeTagihan();
    this.getStatusInvoiceTagihan();
    this.getDataFromApi();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
    computedTanggalInvoice() {
      return this.currentData.tanggalInvoice
        ? moment(this.currentData.tanggalInvoice).format("DD MMMM YYYY")
        : "";
    },
    computedTanggalJatuhTempo() {
      return this.currentData.tanggalJatuhTempo
        ? moment(this.currentData.tanggalJatuhTempo).format("DD MMMM YYYY")
        : "";
    },
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },
    async getTipeTagihan() {
      MasterService.tipeTagihanList()
        .then((res) => {
          this.items_tipetagihan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getStatusInvoiceTagihan() {
      MasterService.statusInvoiceTagihanList()
        .then((res) => {
          this.items_statusinvoicetagihan = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      InvoiceService.invoiceList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getSalesOrderTagihan(soID) {
      SalesOrderService.getSalesOrderTagihan(soID)
        .then((res) => {
          this.currentData.jumlah_full_tagihan =
            res.data.salesorder.total_tagihan;
          this.currentData.sisa_tagihan = res.data.salesorder.sisa_tagihan;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doInvoice: async function () {
      this.dialog = true;

      this.currentData.sales_order_id = "";
      this.currentData.sales_order_no = "";
      this.currentData.jumlah_full_tagihan = "";
      this.currentData.sisa_tagihan = "";
      this.currentData.invoice_no = "";
      this.currentData.jumlah_tagihan = "";
      this.currentData.selectedTipeTagihan = "";
      this.currentData.tanggalInvoice = "";
      this.currentData.menuTanggalInvoice = false;
      this.currentData.tanggalJatuhTempo = "";
      this.currentData.menuTanggalJatuhTempo = false;
      this.currentData.selectedStatusInvoiceTagihan = "";

      this.currentData.id = null;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      //check jika jumlah tagihan tidak melebih sisa
      if (this.currentData.selectedTipeTagihan.code == 1) {
        if (this.currentData.jumlah_tagihan > this.currentData.sisa_tagihan) {
          CommonService.showErrorToast(
            "Jumlah yg ditagihkan tidak bisa lebih besar dari sisa belum ditagih"
          );
          return;
        }
      }

      if (this.currentData.id == null) {
        InvoiceService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah Invoice berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah Invoice gagal. Coba lagi");
          });
      } else {
        InvoiceService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update Invoice berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update Invoice gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;

      this.currentData.invoice_no = this.editedItem.invoice_no;
      this.currentData.jumlah_tagihan = this.editedItem.jumlah_tagihan;
      this.currentData.tanggalInvoice = this.editedItem.tanggal_invoice;
      this.currentData.tanggalJatuhTempo =
        this.editedItem.tanggal_jatuh_tempo_pembayaran;

      if (this.editedItem.tipe_tagihan) {
        this.items_tipetagihan.forEach((entry) => {
          if (entry.code == this.editedItem.tipe_tagihan) {
            this.currentData.selectedTipeTagihan = entry;
          }
        });
      }

      if (this.editedItem.status_invoice) {
        this.items_statusinvoicetagihan.forEach((entry) => {
          if (entry.code == this.editedItem.status_invoice) {
            this.currentData.selectedStatusInvoiceTagihan = entry;
          }
        });
      }

      this.currentData.id = this.editedItem.id;
      console.log("do add");
    },

    downloadInvoice(item) {
      // this.isProgressBarShow = true;
      ReportService.downloadInvoice(item.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          // this.isProgressBarShow = false;
        })
        .catch((err) => {
          // this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    downloadSuratJalan(item) {
      // this.isProgressBarShow = true;
      ReportService.downloadSuratJalan(item.id)
        .then((res) => {
          window.open(res.data.file, "_blank");
          // this.isProgressBarShow = false;
        })
        .catch((err) => {
          // this.isProgressBarShow = false;
          CommonService.showErrorToast("Gagal download report. Coba lagi");
          console.log(err);
        });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      InvoiceService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus Invoice berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus Invoice gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openSOModal: async function () {
      this.dialogSO = true;

      this.$nextTick(() => {
        this.$refs.modalSO.getDataFromApi();
      });
    },

    pilihSO: async function (item) {
      this.currentData.sales_order_id = item.id;
      this.currentData.sales_order_no = item.order_no;

      this.getSalesOrderTagihan(item.id);
    },

    changeTipeTagihan: async function () {
      console.log("change tipe");
      console.log(this.currentData.selectedTipeTagihan);
      if (
        this.currentData.selectedTipeTagihan &&
        this.currentData.selectedTipeTagihan != null
      ) {
        console.log(this.currentData.selectedTipeTagihan.code);
        if (this.currentData.selectedTipeTagihan.code == 2) {
          this.currentData.jumlah_tagihan = this.currentData.sisa_tagihan;
        } else {
          this.currentData.jumlah_tagihan = "";
        }
      }
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
