<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Sales Order </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-text-field
                label="Order No"
                outlined
                dense
                required
                readonly
                v-model="currentData.order_no"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="currentData.menuTanggal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggal"
                    label="Tanggal Order"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.tanggalOrderRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.tanggal"
                  @input="currentData.menuTanggal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Currency"
                outlined
                dense
                required
                readonly
                v-model="currentData.currency"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Customer"
                outlined
                dense
                required
                v-model="currentData.customer_nama"
                readonly
                @click="openCustomerModal"
                :rules="currentData.customerRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="PO No Customer"
                outlined
                dense
                required
                v-model="currentData.po_no_cust"
                :rules="currentData.poNoCustRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4"></v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="currentData.menuCustomerRequestDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedCRD"
                    label="Customer Request Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.custRequestDateRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.customer_request_date"
                  @input="currentData.menuCustomerRequestDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="currentData.menuExpectedDeliveryDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedEDD"
                    label="Expected Delivery Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.expectedDeliveryDateRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.expected_delivery_date"
                  @input="currentData.menuExpectedDeliveryDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="currentData.menuExpectedHOReceivedDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedHRD"
                    label="Expected HO Received Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.expectedHOReceivedDateRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.expected_ho_received_date"
                  @input="currentData.menuExpectedHOReceivedDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-3">
              <v-text-field
                label="Alamat Pengiriman"
                outlined
                dense
                required
                v-model="currentData.alamat_pengiriman"
                :rules="currentData.alamatPengirimanRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-3">
              <v-text-field
                label="Propinsi"
                outlined
                dense
                v-model="currentData.propinsi_nama"
                readonly
                @click="openPropinsiModal"
                :rules="currentData.propinsiRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-3">
              <v-text-field
                label="Kabupaten/Kota"
                outlined
                dense
                v-model="currentData.kabupaten_nama"
                readonly
                @click="openKabupatenModal"
                :rules="currentData.kabupatenRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-3">
              <v-text-field
                label="Kecamatan"
                outlined
                dense
                v-model="currentData.kecamatan"
                :rules="currentData.kecamatanRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-3">
              <v-text-field
                label="Negara"
                outlined
                dense
                v-model="currentData.negara_nama"
                readonly
                @click="openNegaraModal"
                :rules="currentData.negaraRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-3"></v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Sales Person / PIC"
                outlined
                dense
                required
                v-model="currentData.sales_person"
                :rules="currentData.salesPersonRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Shipping"
                outlined
                dense
                required
                v-model="currentData.shipping"
                :rules="currentData.shippingRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-combobox
                label="Metode Pembayaran"
                clearable
                dense
                outlined
                v-model="currentData.selectedMetodePembayaran"
                :items="items_metode_pembayaran"
                item-text="value"
                :rules="currentData.metodePembayaranRules"
              ></v-combobox>
            </v-col>

            <v-col cols="12" sm="8" md="8" class="">
              <v-textarea
                outlined
                name="input-7-4"
                label="Packaging Information"
                dense
                value=""
                rows="3"
                v-model="currentData.packaging_information"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-textarea
                outlined
                name="input-7-4"
                label="Notes"
                dense
                value=""
                rows="3"
                v-model="currentData.notes"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="">
              <v-text-field
                label="Created By"
                outlined
                dense
                required
                readonly
                v-model="currentData.createdBy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="">
              <v-text-field
                label="Created At"
                outlined
                dense
                required
                readonly
                v-model="currentData.createdAt"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="">
              <v-text-field
                label="Updated By"
                outlined
                dense
                required
                readonly
                v-model="currentData.updatedBy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="">
              <v-text-field
                label="Updated at"
                outlined
                dense
                required
                readonly
                v-model="currentData.updatedAt"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20%" class="text-left">Material</th>
                      <th class="text-left">Qty Order</th>
                      <th class="text-left">UOM</th>
                      <th class="text-left">Selling Price</th>
                      <th class="text-left">Total</th>
                      <th class="text-left">Item Number</th>
                      <th class="text-left">Remarks</th>

                      <th class="text-left">Qty Terpenuhi</th>
                      <th class="text-left">Status</th>
                      <th class="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in materials" :key="i">
                      <td style="padding: 6px">
                        <!-- <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.komoditi_nama"
                          readonly
                          @click="openMaterialModal(i)"
                          :rules="item.komodotiRules"
                        ></v-text-field> -->
                        <v-textarea
                          outlined
                          name="input-7-4"
                          dense
                          value=""
                          rows="3"
                          required
                          v-model="item.komoditi_nama"
                          readonly
                          @click="openMaterialModal(i)"
                          :rules="item.komodotiRules"
                          :disabled="item.is_disabled"
                        ></v-textarea>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.qty"
                          :rules="item.qtyRules"
                          :options="options"
                          @change="calculateTotal(i)"
                        />
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.uom"
                          disabled
                        ></v-text-field>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.selling_price"
                          @change="calculateTotal(i)"
                          :rules="item.sellingPriceRules"
                          :options="options"
                          :disabled="item.is_disabled"
                        />
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.total"
                          disabled
                          :options="options"
                        />
                      </td>
                      <td>
                        <v-text-field
                          class="small-font"
                          outlined
                          dense
                          required
                          disabled
                          v-model="item.item_number"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.remarks"
                        ></v-text-field>
                      </td>

                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.qty_terpenuhi"
                          disabled
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.status"
                          disabled
                        ></v-text-field>
                      </td>
                      <td>
                        <v-icon medium @click="deleteMaterial(i, item)">
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <v-icon medium @click="addMaterial()">
                          mdi-plus
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6"> </v-col>
            <v-col style="vertical-align: center">
              <h4 class="v-heading text-h7 mb-2">Net Total</h4>
            </v-col>
            <v-col>
              <vuetify-money
                outlined
                dense
                required
                v-model="currentData.net"
                readonly
                :options="options"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6"> </v-col>
            <v-col>
              <h4 class="v-heading text-h7 mb-2">Vat Total (%)</h4>
            </v-col>
            <v-col
              ><v-text-field
                outlined
                dense
                required
                v-model="currentData.tax"
                @change="calculateTotalAmount"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6"> </v-col>
            <v-col>
              <h4 class="v-heading text-h7 mb-2">Total Discount (%)</h4>
            </v-col>
            <v-col
              ><v-text-field
                outlined
                dense
                required
                v-model="currentData.discount"
                @change="calculateTotalAmount"
              ></v-text-field
            ></v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6"> </v-col>
            <v-col>
              <h4 class="v-heading text-h7 mb-2">Gross Total</h4>
            </v-col>
            <v-col>
              <vuetify-money
                outlined
                dense
                required
                v-model="currentData.gross"
                readonly
                :options="options"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6"> </v-col>
            <v-col>
              <h4 class="v-heading text-h7 mb-2">Shipment Price</h4>
            </v-col>
            <v-col>
              <vuetify-money
                outlined
                dense
                required
                v-model="shipment_price"
                :options="options"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6"> </v-col>
            <v-col>
              <h4 class="v-heading text-h7 mb-2">
                Total Amount after Discount
              </h4>
            </v-col>
            <v-col>
              <vuetify-money
                outlined
                dense
                required
                v-model="currentData.total_amount_after"
                readonly
                :options="options"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Apa anda akan menghapus data ini?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Tidak</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >Ya</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CustomerSelectComponent
      @pilihCustomer="pilihCustomer"
      ref="modalCustomer"
      v-model="dialogCustomer"
    />
    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
    />
    <PropinsiSelectComponent
      @pilihPropinsi="pilihPropinsi"
      ref="modalPropinsi"
      v-model="dialogPropinsi"
    />

    <KabupatenSelectComponent
      @pilihKabupaten="pilihKabupaten"
      ref="modalKabupaten"
      v-model="dialogKabupaten"
      :propinsi_id="currentData.propinsi_id"
    />

    <NegaraSelectComponent
      @pilihNegara="pilihNegara"
      ref="modalNegara"
      v-model="dialogNegara"
    />
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import SalesOrderService from "../services/SalesOrderService";
import MasterService from "../services/MasterService";
import moment from "moment";
// import { format, parseISO } from "date-fns";

import CustomerSelectComponent from "../components/CustomerSelect.vue";
import MaterialSelectComponent from "../components/MaterialFgdSelect.vue";

import PropinsiSelectComponent from "../components/PropinsiSelect.vue";
import KabupatenSelectComponent from "../components/KabupatenSelect.vue";
import NegaraSelectComponent from "../components/NegaraSelect.vue";

export default {
  name: "KegiatanAdd",
  components: {
    CustomerSelectComponent,
    MaterialSelectComponent,
    PropinsiSelectComponent,
    KabupatenSelectComponent,
    NegaraSelectComponent,
  },
  props: {
    id: String,
  },
  data: () => ({
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    dialogDelete: false,
    dialogCustomer: false,
    dialogMaterial: false,
    dialogPropinsi: false,
    dialogKabupaten: false,
    dialogNegara: false,
    breadcrumbs: [
      {
        text: "Data Sales Order",
        disabled: false,
        exact: true,
        to: { name: "SalesOrder" },
      },
      { text: "Edit Sales Order", disabled: true },
    ],
    valid: true,
    currentData: {
      order_no: "",
      po_no_cust: "",
      menuTanggal: false,
      tanggal: "",
      menuCustomerRequestDate: false,
      customer_request_date: "",
      menuExpectedDeliveryDate: false,
      expected_delivery_date: "",
      menuExpectedHOReceivedDate: false,
      expected_ho_received_date: "",
      alamat_pengiriman: "",
      shipping: "",
      sales_person: "",
      notes: "",
      selectedMetodePembayaran: "",
      customer_id: "",
      customer_nama: "",
      packaging_information: "",
      net: "",
      tax: "",
      discount: "",
      gross: "",
      shipment_price: "",
      total_amount_after: "",
      propinsi_id: -1,
      propinsi_nama: "",
      kabupaten_id: "",
      kabupaten_nama: "",
      kecamatan: "",
      negara: "",
      createdBy: "",
      createdAt: "",
      updatedAt: "",
      updatedBy: "",
      currency: "",
      tanggalOrderRules: [(v) => !!v || "Tanggal Order harus diisi"],
      poNoCustRules: [(v) => !!v || "PO No Customer harus diisi"],
      custRequestDateRules: [(v) => !!v || "Customer Request Date harus diisi"],
      expectedDeliveryDateRules: [
        (v) => !!v || "Expected Delivery Date harus diisi",
      ],
      expectedHOReceivedDateRules: [
        (v) => !!v || "Expected HO Received Date harus diisi",
      ],
      alamatPengirimanRules: [(v) => !!v || "Alamat Pengiriman harus diisi"],
      shippingRules: [(v) => !!v || "Shipping harus diisi"],
      salesPersonRules: [(v) => !!v || "Sales Person/PIC harus diisi"],
      metodePembayaranRules: [(v) => !!v || "Metode Pembayaran harus diisi"],
      customerRules: [(v) => !!v || "Customer harus diisi"],
      propinsiRules: [(v) => !!v || "Propinsi harus diisi"],
      kabupatenRules: [(v) => !!v || "Kabupaten/Kota harus diisi"],
      kecamatanRules: [(v) => !!v || "Kecamatan harus diisi"],
      negaraRules: [(v) => !!v || "Negara harus diisi"],
    },
    materials: [],
    selectedMaterialIndex: 0,
    items_metode_pembayaran: [],
    fotoKey: 0,
    editedIndex: -1,
    editedItem: {},
    shipment_price: "",
  }),
  watch: {
    materials: {
      handler() {
        this.calculateTotalAmount();
      },
      deep: true,
    },
    shipment_price: {
      handler() {
        this.calculateTotalAmount();
      },
      deep: true,
    },
  },
  mounted: async function () {
    this.getMetodePembayaran();
    this.getSalesOrder();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
    computedTanggal() {
      return this.currentData.tanggal
        ? moment(this.currentData.tanggal).format("DD MMMM YYYY")
        : "";
    },
    computedCRD() {
      return this.currentData.customer_request_date
        ? moment(this.currentData.customer_request_date).format("DD MMMM YYYY")
        : "";
    },
    computedEDD() {
      return this.currentData.expected_delivery_date
        ? moment(this.currentData.expected_delivery_date).format("DD MMMM YYYY")
        : "";
    },
    computedHRD() {
      return this.currentData.expected_ho_received_date
        ? moment(this.currentData.expected_ho_received_date).format(
            "DD MMMM YYYY"
          )
        : "";
    },
  },
  methods: {
    async getSalesOrder() {
      SalesOrderService.getSalesOrder(this.id)
        .then((res) => {
          this.currentData.order_no = res.data.salesorder.order_no;
          this.currentData.po_no_cust = res.data.salesorder.po_no_cust;
          this.currentData.createdBy = res.data.salesorder.created_by_nama;
          this.currentData.updatedBy = res.data.salesorder.updated_by_nama;
          this.currentData.currency = res.data.salesorder.currency;

          if (res.data.salesorder.created_at) {
            this.currentData.createdAt = moment(
              res.data.salesorder.created_at
            ).format("YYYY-MM-DD HH:mm:ss");
          }

          if (res.data.salesorder.updated_at) {
            this.currentData.updatedAt = moment(
              res.data.salesorder.updated_at
            ).format("YYYY-MM-DD HH:mm:ss");
          }

          if (res.data.salesorder.tanggal) {
            this.currentData.tanggal = moment(
              res.data.salesorder.tanggal
            ).format("YYYY-MM-DD");
          }

          if (res.data.salesorder.customer_request_date) {
            this.currentData.customer_request_date = moment(
              res.data.salesorder.customer_request_date
            ).format("YYYY-MM-DD");
          }

          if (res.data.salesorder.expected_delivery_date) {
            this.currentData.expected_delivery_date = moment(
              res.data.salesorder.expected_delivery_date
            ).format("YYYY-MM-DD");
          }

          if (res.data.salesorder.expected_ho_received_date) {
            this.currentData.expected_ho_received_date = moment(
              res.data.salesorder.expected_ho_received_date
            ).format("YYYY-MM-DD");
          }

          this.currentData.alamat_pengiriman =
            res.data.salesorder.alamat_pengiriman;
          this.currentData.shipping = res.data.salesorder.shipping;
          this.currentData.sales_person = res.data.salesorder.sales_person;
          this.currentData.notes = res.data.salesorder.notes;
          this.currentData.customer_id = res.data.salesorder.customer_id;
          this.currentData.customer_nama = res.data.salesorder.customer_nama;
          this.currentData.packaging_information =
            res.data.salesorder.packaging_information;

          this.currentData.propinsi_id = res.data.salesorder.propinsi_id;
          this.currentData.propinsi_nama = res.data.salesorder.propinsi_nama;
          this.currentData.kabupaten_id = res.data.salesorder.kabupaten_id;
          this.currentData.kabupaten_nama = res.data.salesorder.kabupaten_nama;
          this.currentData.kecamatan = res.data.salesorder.kecamatan;
          this.currentData.negara = res.data.salesorder.negara;
          this.currentData.negara_nama = res.data.salesorder.negara_nama;

          if (res.data.salesorder.metode_pembayaran) {
            this.items_metode_pembayaran.forEach((entry) => {
              if (entry.code == res.data.salesorder.metode_pembayaran) {
                this.currentData.selectedMetodePembayaran = entry;
              }
            });
          }

          this.currentData.net = res.data.salesorder.net;

          this.currentData.tax = res.data.salesorder.tax;

          this.currentData.discount = res.data.salesorder.discount;
          this.shipment_price = res.data.salesorder.shipment_price;

          res.data.salesorder.materials.forEach((element) => {
            this.materials.push({
              id: element.id,
              is_disabled: element.is_disabled,
              komoditi_id: element.komoditi_id,
              komoditi_nama: element.komoditi_nama,
              qty: element.qty,
              selling_price: element.selling_price,
              total: element.total,
              item_number: element.item_number,
              remarks: element.remarks,
              uom: element.uom,
              qty_terpenuhi: element.qty_terpenuhi,
              status: element.soitem_status_value,
              komodotiRules: [(v) => !!v || "Material harus diisi"],
              qtyRules: [(v) => !!v || "Qty harus diisi"],
              sellingPriceRules: [(v) => !!v || "Selling Price harus diisi"],
            });
          });
          this.calculateTotalAmount();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMetodePembayaran() {
      MasterService.metodePembayaranList()
        .then((res) => {
          this.items_metode_pembayaran = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      this.currentData.shipment_price = this.shipment_price;
      SalesOrderService.update(this.id, this.currentData, this.materials)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Update Sales Order berhasil");
        })
        .catch((err) => {
          console.log(err);

          if (err.response.data.message) {
            CommonService.showErrorToast(err.response.data.message);
          } else {
            CommonService.showErrorToast("Update Sales Order gagal. Coba lagi");
          }
        });
    },

    openCustomerModal: async function () {
      this.$refs.modalCustomer.getDataFromApi();
      this.dialogCustomer = true;
    },

    openMaterialModal: async function (index) {
      this.selectedMaterialIndex = index;
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    pilihCustomer: async function (item) {
      console.log(item);
      this.currentData.customer_id = item.id;
      this.currentData.customer_nama = item.name;
    },

    pilihMaterial: async function (item) {
      console.log(item);
      this.materials[this.selectedMaterialIndex].komoditi_id = item.id;
      this.materials[this.selectedMaterialIndex].komoditi_nama = item.nama;
      this.materials[this.selectedMaterialIndex].uom = item.uom;
      this.materials[this.selectedMaterialIndex].selling_price =
        item.selling_price;
    },

    addMaterial: async function () {
      this.materials.push({
        komoditi_id: "",
        komoditi_nama: "",
        qty: "",
        selling_price: "",
        remarks: "",
        uom: "",
        komodotiRules: [(v) => !!v || "Material harus diisi"],
        qtyRules: [(v) => !!v || "Qty harus diisi"],
        sellingPriceRules: [(v) => !!v || "Selling Price harus diisi"],
      });
    },

    deleteMaterial: async function (index, item) {
      // this.materials.splice(index, 1);
      console.log(item);
      this.editedIndex = index;
      this.editedItem = item;
      this.dialogDelete = true;
    },

    calculateTotal: async function (index) {
      if (this.materials[index].qty && this.materials[index].selling_price) {
        this.materials[index].total =
          this.materials[index].qty * this.materials[index].selling_price;
      }

      var net = 0;
      for (var i = 0; i < this.materials.length; i++) {
        if (this.materials[i].qty && this.materials[i].selling_price) {
          net += this.materials[i].qty * this.materials[i].selling_price;
        }
      }

      this.currentData.net = net;
      var totalAfterTax = 0;

      if (this.currentData.tax) {
        totalAfterTax = net + (net * this.currentData.tax) / 100;
      } else {
        totalAfterTax = net;
      }

      this.currentData.gross = totalAfterTax;

      var totalAfterDiscount = 0;

      if (this.currentData.discount) {
        totalAfterDiscount =
          this.currentData.gross -
          (this.currentData.gross * this.currentData.discount) / 100;
      } else {
        totalAfterDiscount = totalAfterTax;
      }
      this.currentData.total_amount_after = totalAfterDiscount;

      if (this.currentData.shipment_price) {
        this.currentData.total_amount_after += parseInt(
          this.currentData.shipment_price
        );
      }
    },

    calculateTotalAmount: async function () {
      var net = 0;
      for (var i = 0; i < this.materials.length; i++) {
        if (this.materials[i].qty && this.materials[i].selling_price) {
          this.materials[i].total =
            this.materials[i].qty * this.materials[i].selling_price;

          net += this.materials[i].qty * this.materials[i].selling_price;
        }
      }

      this.currentData.net = net;
      var totalAfterTax = 0;

      if (this.currentData.tax) {
        totalAfterTax = net + (net * this.currentData.tax) / 100;
      } else {
        totalAfterTax = net;
      }

      this.currentData.gross = totalAfterTax;

      var totalAfterDiscount = 0;

      if (this.currentData.discount) {
        totalAfterDiscount =
          this.currentData.gross -
          (this.currentData.gross * this.currentData.discount) / 100;
      } else {
        totalAfterDiscount = totalAfterTax;
      }
      this.currentData.total_amount_after = totalAfterDiscount;

      if (this.shipment_price) {
        this.currentData.total_amount_after += parseInt(this.shipment_price);
      }
    },

    openPropinsiModal: async function () {
      this.$refs.modalPropinsi.getDataFromApi();
      this.dialogPropinsi = true;
    },

    pilihPropinsi: async function (item) {
      console.log(item);
      this.currentData.propinsi_id = item.id;
      this.currentData.propinsi_nama = item.nama;
    },

    openKabupatenModal: async function () {
      this.$refs.modalKabupaten.getDataFromApi();
      this.dialogKabupaten = true;
    },

    pilihKabupaten: async function (item) {
      this.currentData.kabupaten_id = item.id;
      this.currentData.kabupaten_nama = item.nama;
    },

    openNegaraModal: async function () {
      this.$refs.modalNegara.getDataFromApi();
      this.dialogNegara = true;
    },

    pilihNegara: async function (item) {
      this.currentData.negara = item.id;
      this.currentData.negara_nama = item.nama;
    },

    deleteItemConfirm() {
      this.closeDelete();

      SalesOrderService.deleteItem(this.editedItem.id)
        .then((res) => {
          console.log(res);
          this.materials.splice(this.editedIndex, 1);
        })
        .catch((err) => {
          console.log(err);

          if (err.response.data.message) {
            CommonService.showErrorToast(err.response.data.message);
          } else {
            CommonService.showErrorToast("Hapus Item gagal. Coba lagi");
          }
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.small-font {
  font-size: 12px;
}
</style>
