<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-card class="mt-4 mb-3">
        <v-card-title>
          {{ currentData.kelompok_nama }}
          <v-spacer></v-spacer>
          <v-icon medium class="mr-3" @click="editKelompok(item)">
            mdi-pencil
          </v-icon>
        </v-card-title>
      </v-card>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-card class="pt-4 pl-4" style="height: 350px">
            <v-chart class="chart" :option="totalRevenueChart" /> </v-card
        ></v-col>
        <v-col cols="12" sm="12" md="6">
          <v-card class="pt-3 pl-3 pr-3 pb-3" style="height: 100%">
            <div class="d-flex">
              <div style="width: 200px">
                <p class="font-weight-bold">Alamat</p>
              </div>
              <p class="font-weight-bold">{{ currentData.alamat }}</p>
            </div>
            <div class="d-flex">
              <div style="width: 200px">
                <p class="font-weight-bold">Jumlah Anggota</p>
              </div>
              <p class="font-weight-bold">
                {{ currentData.jumlah_anggota }} Anggota
              </p>
            </div>
            <div class="d-flex">
              <div style="width: 200px">
                <p class="font-weight-bold">Luas Lahan</p>
              </div>
              <p class="font-weight-bold">{{ currentData.luas_lahan }} Ha</p>
            </div>
            <div class="d-flex">
              <div style="width: 200px">
                <p class="font-weight-bold">KUR</p>
              </div>
              <p class="font-weight-bold">Rp. {{ currentData.kur }}</p>
            </div>
            <div class="d-flex">
              <div style="width: 200px">
                <p class="font-weight-bold">Asset</p>
              </div>
              <p class="font-weight-bold">Rp. {{ currentData.asset }}</p>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <GmapMap
            ref="mapRef"
            :center="{ lat: 0.7893, lng: 113.9213 }"
            :zoom="10"
            map-type-id="terrain"
            style="width: 100%; height: 500px"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="true"
              @click="center = m.position"
            />
          </GmapMap>
        </v-col>
      </v-row>
      <v-container fluid>
        <v-card>
          <v-tabs v-model="tab">
            <v-tab>List Anggota</v-tab>
            <v-tab>List Order</v-tab>
            <v-tab>Pengurus</v-tab>
            <v-tab>Material</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card class="mt-3">
                <v-card-title>
                  List Anggota
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Cari Nama"
                    single-line
                    hide-details
                    v-on:keyup.enter="getDataFromApi"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="list_data"
                  :options.sync="options"
                  :server-items-length="totalData"
                  :loading="loading"
                  class="elevation-1 scroll-to-me"
                >
                  <template v-slot:[`item.kelompok_nama`]="{ item }">
                    <div>
                      <p style="margin-bottom: 0px">{{ item.kelompok_nama }}</p>
                      <span style="color: #606f89">{{ item.sk }}</span>
                    </div>
                  </template>
                  <template v-slot:[`item.saham`]="{ item }">
                    {{ item.saham }} %
                  </template>
                  <template v-slot:[`item.luas_lahan`]="{ item }">
                    {{ item.luas_lahan }} Ha
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="getColor(item.status)" dark>
                      {{ item.status_value }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon medium class="mr-3" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <PurchaseOrderDashboardComponent :id="id" />
            </v-tab-item>
            <v-tab-item>
              <PengurusKelompokComponent :id="id" />
            </v-tab-item>
            <v-tab-item> <KelompokKomoditiesComponent :id="id" /> </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-container>
    </v-container>
  </v-main>
</template>

<script>
import KegiatanService from "../services/KegiatanService";
import DashboardService from "../services/DashboardService";
import * as moment from "moment";

import PurchaseOrderDashboardComponent from "./SalesOrderDashboard.vue";

import PengurusKelompokComponent from "./PengurusKelompok.vue";
import KelompokKomoditiesComponent from "./KelompokKomodities.vue";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  components: {
    VChart,
    PurchaseOrderDashboardComponent,
    PengurusKelompokComponent,
    KelompokKomoditiesComponent,
  },
  props: {
    id: String,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  data: () => ({
    tab: null,
    anggotaAktifProgress: 60,
    breadcrumbs: [
      {
        text: "Data Dashboard",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama Anggota", value: "user_nama", sortable: false },
      { text: "Luas Lahan", value: "luas_lahan", sortable: false },
      { text: "Hasil Panen/Tahun", value: "hasil_panen", sortable: false },
      { text: "Profit/Tahun", value: "profit", sortable: false },
      { text: "Saham", value: "saham", sortable: false },
      { text: "Status", value: "status", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    kategoriKegiatanName: [],
    kategoriKegiatanData: [],
    selectedKategori: "",
    selectedWaktu: "",
    items_kategori: [],
    currentData: {
      kelompok_nama: "",
      alamat: "",
      luas_lahan: "",
      jumlah_anggota: "",
      kur: "",
      asset: "",
      latitude: 0,
      longitude: 0,
    },
    markers: [],
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
    this.getDashboard();
    this.scrollToTop();
  },
  computed: {
    totalRevenueChart() {
      return {
        title: {
          text: "Total Revenue",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: this.kategoriKegiatanName,
          formatter: (name) => {
            var value = this.kategoriKegiatanData.filter(
              (row) => row.name === name
            )[0].value;
            return name + " : Rp. " + value;
          },
        },
        series: [
          {
            name: "Total",
            type: "pie",
            radius: [40, 120],
            center: ["50%", "50%"],
            data: this.kategoriKegiatanData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            color: ["#FFDE59", "#B0C24D", "#6CA14C"],
          },
        ],
      };
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getColor(kategori) {
      if (kategori == 1) {
        return "blue";
      } else {
        return "grey";
      }
    },
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD/MM/YYYY");
    },
    getDataFromApi() {
      // this.list_data.push(
      //   {
      //     no: 1,
      //     user_nama: "Hardianus Yovin",
      //     luas_lahan: "300 Ha",
      //     hasil_panen: "3000 Kg",
      //     profit: "1.200.000.000",
      //     saham: "5 %",
      //     status: 1,
      //     status_value: "Aktif",
      //   },
      //   {
      //     no: 2,
      //     user_nama: "Marcelino Ponty",
      //     luas_lahan: "450 Ha",
      //     hasil_panen: "4500 Kg",
      //     profit: "300.000.000",
      //     saham: "5 %",
      //     status: 1,
      //     status_value: "Aktif",
      //   },
      //   {
      //     no: 3,
      //     user_nama: "Agus Sulaeman",
      //     luas_lahan: "250 Ha",
      //     hasil_panen: "2500 Kg",
      //     profit: "150.000.000",
      //     saham: "5 %",
      //     status: 1,
      //     status_value: "Aktif",
      //   }
      // );
      this.loading = false;
      const { page, itemsPerPage } = this.options;
      DashboardService.anggotaList(page, itemsPerPage, this.search, this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getDashboard() {
      // const { page, itemsPerPage } = this.options;

      this.kategoriKegiatanName.push("Revenue", "Profit", "Expense");
      this.kategoriKegiatanData.push(
        { value: 100, name: "Revenue" },
        { value: 1000, name: "Profit" },
        { value: 2000, name: "Expense" }
      );

      this.loading = true;
      DashboardService.getDashboardDetail(this.id)
        .then((res) => {
          console.log(res.data.data);

          this.currentData.kelompok_nama = res.data.data.nama;
          this.currentData.alamat = res.data.data.alamat;
          this.currentData.luas_lahan = res.data.data.luas_lahan;
          this.currentData.jumlah_anggota = res.data.data.jumlah_anggota;
          this.currentData.kur = res.data.data.kur;
          this.currentData.asset = res.data.data.asset;
          this.currentData.latitude = res.data.data.latitude;
          this.currentData.longitude = res.data.data.longitude;

          if (this.currentData.latitude && this.currentData.longitude) {
            const marker = {
              lat: this.currentData.latitude,
              lng: this.currentData.longitude,
            };
            this.markers.push({ position: marker });

            this.$refs.mapRef.$mapPromise.then((map) => {
              map.panTo({
                lat: this.currentData.latitude,
                lng: this.currentData.longitude,
              });
            });
          }

          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/kegiatan-edit/" + item.kegiatan_id).catch((err) => {
        console.log(err);
      });
    },

    editKelompok() {
      this.$router.push("/kelompok-edit/" + this.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      KegiatanService.delete(this.editedItem.kegiatan_id)
        .then((res) => {
          console.log(res);
          this.$toast.success("Hapus kegiatan berhasil", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Hapus kegiatan gagal. Coba lagi", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
