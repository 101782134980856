import http from "../http-common";
import httpBang from "../http-common-bang";
/* eslint-disable */
class AuthService {
  login(data) {
    return http.post("/auth/login", data);
  }

  register(data) {
    return http.post("/auth/register", data);
  }

  loginBang(data) {
    console.log(data);
    return httpBang.post("/login", data);
  }
}

export default new AuthService();
