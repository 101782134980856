/* eslint-disable */
class ValidatePermissionService {
  isAllowed(permissionName) {
    const userPermission =
      localStorage.getItem(process.env.VUE_APP_USER_PERMISSION) || "";

    var allowed = false;

    if (userPermission) {
      const permission = JSON.parse(
        localStorage.getItem(process.env.VUE_APP_USER_PERMISSION) || ""
      );

      if (permission != null && permission.length > 0) {
        permission.forEach((element) => {
          if (element.permission_name == permissionName) {
            allowed = true;
          }
        });
      }
    }

    return allowed;
  }
}

export default new ValidatePermissionService();
