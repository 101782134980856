<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Production Order Detail </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-combobox
                label="Production Type"
                dense
                outlined
                v-model="currentData.selectedProdType"
                :items="items_prod_type"
                item-text="value"
                @change="onChangeProdType"
                :rules="currentData.productionTypeRules"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="PRO No"
                outlined
                dense
                disabled
                value="GENERATED"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-textarea
                outlined
                name="input-7-4"
                label="Deskripsi"
                dense
                value=""
                rows="3"
                v-model="currentData.deskripsi"
              ></v-textarea>
            </v-col>
            <v-col v-if="isSOOrder" cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Order Penjualan"
                outlined
                dense
                required
                clearable
                v-model="currentData.so_no_kelompok"
                readonly
                @click="openPOModal"
                @click:clear="cleraSoNo"
                :rules="currentData.soNoRules"
              ></v-text-field>
            </v-col>
            <v-col v-if="isSOOrder" cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Order Penjualan Item"
                outlined
                dense
                required
                v-model="currentData.komoditi"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-menu
                v-model="currentData.menuTanggal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggal"
                    label="Tanggal Produksi"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.tanggalProduksiRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.tanggal"
                  @input="currentData.menuTanggal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-menu
                v-model="currentData.menuTanggalHarus"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggalHarus"
                    label="Harus Selesai Sebelum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.tanggalHarusRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.harus_selesai_sebelum"
                  @input="currentData.menuTanggalHarus = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-menu
                v-model="currentData.menuTanggalPerkiraan"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggalPerkiraan"
                    label="Perkiraan Akhir Produksi"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.tanggalPerkiraanRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.perkiraan_akhir_produksi"
                  @input="currentData.menuTanggalPerkiraan = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <h3 class="mr-auto">Target Finish Goods</h3>
              <!-- <v-btn
                dark
                color="primary"
                type="button"
                class="mr-4"
                @click="openDetailWF"
                >Detail WF</v-btn
              > -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table v-if="isSOOrder" :key="tableKey">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="30%" class="text-left">Material</th>
                      <th width="20%" class="text-left">Qty Order</th>
                      <th width="10%" class="text-left">UOM</th>
                      <th width="20%" class="text-left">WF Prod</th>
                      <th width="20%" class="text-left">Qty Done</th>
                      <th width="20%" class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item_fg, i) in list_data" :key="i">
                      <td style="padding: 6px">
                        <span>{{ item_fg.komoditi_nama }}</span>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item_fg.qty_order"
                          :options="options_money"
                          :rules="item_fg.qtyOrderRules"
                        />
                      </td>
                      <td>
                        <span>{{ item_fg.uom }}</span>
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          readonly
                          v-model="item_fg.wf_prod_nama"
                          @click="openWFModal(i, item_fg)"
                          :rules="item_fg.wfProdRules"
                        ></v-text-field>
                      </td>
                      <td>
                        <span>{{ item_fg.qty_done }}</span>
                      </td>
                      <td>
                        <span>{{ item_fg.status_value }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table v-else :key="tableKey">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="30%" class="text-left">Material</th>
                      <th width="20%" class="text-left">Qty Order</th>
                      <th width="10%" class="text-left">UOM</th>
                      <th width="20%" class="text-left">WF Prod</th>
                      <th width="20%" class="text-left">Qty Done</th>
                      <th width="20%" class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item_fg, i) in list_data" :key="i">
                      <td style="padding: 6px">
                        <v-textarea
                          outlined
                          v-model="item_fg.komoditi_nama"
                          rows="2"
                          readonly
                          row-height="3"
                          @click="openMaterialFGModal(i)"
                          :rules="item_fg.komoditiRules"
                        ></v-textarea>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item_fg.qty_order"
                          :options="options_money"
                          :rules="item_fg.qtyOrderRules"
                        />
                      </td>
                      <td>
                        <span>{{ item_fg.uom }}</span>
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          readonly
                          v-model="item_fg.wf_prod_nama"
                          @click="openWFModal(i, item_fg)"
                          :rules="item_fg.wfProdRules"
                        ></v-text-field>
                      </td>
                      <td>
                        <span>{{ item_fg.qty_done }}</span>
                      </td>
                      <td>
                        <span>{{ item_fg.status_value }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h3>List Proses</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="10%" class="text-left">Sequence</th>
                      <th width="20%" class="text-left">Steps WF</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in materials_wf" :key="i">
                      <td style="padding: 6px">{{ item.seq }}</td>
                      <td>{{ item.nama_proses }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h3>List Raw Item</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="30%" class="text-left">Material</th>
                      <th width="15%" class="text-left">Qty Process</th>
                      <th width="10%" class="text-left">Consumption</th>
                      <th width="15%" class="text-left">Qty Consumption</th>
                      <th width="10%" class="text-left">UOM</th>
                      <th width="20%" class="text-left">Status</th>
                      <th width="20%" class="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in materials_raw" :key="i">
                      <td style="padding: 6px">
                        <v-textarea
                          outlined
                          v-model="item.komoditi_nama"
                          rows="2"
                          readonly
                          row-height="3"
                          :rules="item.komoditiRules"
                        ></v-textarea>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.qty_raw"
                          @change="calculateConsumption(i)"
                          :options="options_money"
                          :rules="item.qtyRules"
                        />
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          readonly
                          v-model="item.consumption"
                          :options="options_money_pre"
                        />
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          readonly
                          v-model="item.qty_consumption"
                          :options="options_money_pre"
                        />
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.uom"
                          readonly
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          readonly
                          v-model="item.status"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-icon medium @click="deleteMaterial(i)">
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <v-icon medium @click="addMaterialRaw()">
                          mdi-plus
                        </v-icon>
                      </td>
                    </tr> -->
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
    <POKelompokSelectComponent
      @pilihPO="pilihPO"
      ref="modalPO"
      v-model="dialogPO"
    />
    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
      :id="currentData.raw_komoditi_target"
    />
    <WFProductionSelectComponent
      @pilihWF="pilihWF"
      ref="modalWF"
      v-model="dialogWF"
      :id="currentData.komoditi_id"
    />
    <MaterialAllSelectComponent
      @pilihMaterial="pilihMaterialAll"
      ref="modalMaterialAll"
      v-model="dialogMaterialAll"
    />
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import moment from "moment";
// import { format, parseISO } from "date-fns";

import MaterialSelectComponent from "../components/MaterialBomSelect.vue";
import WFProductionSelectComponent from "../components/WFProductionSelect.vue";
import POKelompokSelectComponent from "../components/POKelompokWithItemSelect.vue";
import ProductionOrderService from "../services/ProductionOrderService";
import WfProductionService from "../services/WfProductionService";

import MaterialAllSelectComponent from "../components/MaterialWorkflowOnlySelect.vue";
import BomService from "../services/BomService";

export default {
  name: "GoodReceiptAdd",
  components: {
    MaterialSelectComponent,
    WFProductionSelectComponent,
    POKelompokSelectComponent,
    MaterialAllSelectComponent,
  },
  data: () => ({
    options_money: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    options_money_pre: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 1,
    },
    tableKey: 0,
    dialogMaterialAll: false,
    dialogPO: false,
    dialogMaterial: false,
    dialogWF: false,
    breadcrumbs: [
      {
        text: "Data PRO",
        disabled: false,
        exact: true,
        to: { name: "ProductionOrder" },
      },
      { text: "Tambah PRO", disabled: true },
    ],
    valid: true,
    currentData: {
      order_no: "",
      no_kegiatan: "",
      menuTanggal: false,
      tanggal: "",
      deskripsi: "",
      menuTanggalHarus: false,
      harus_selesai_sebelum: "",
      menuTanggalPerkiraan: false,
      perkiraan_akhir_produksi: "",
      user_id: "",
      user_nama: "",
      total: "",
      warehouse_id: "",
      warehouse_nama: "",
      selectedTransactionType: "",
      selectedDocType: "",
      selectedProdType: "",
      selectedProdStatus: "",
      komoditi: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
      soNoRules: [(v) => !!v || "SO No harus diisi"],
      tanggalProduksiRules: [(v) => !!v || "Tanggal Produksi harus diisi"],
      tanggalHarusRules: [(v) => !!v || "Harus selesai sebelum harus diisi"],
      tanggalPerkiraanRules: [
        (v) => !!v || "Perkiraan akhir produksi harus diisi",
      ],
      productionTypeRules: [(v) => !!v || "Production Type harus diisi"],
      raw_komoditi_target: 0,
    },
    materials_raw: [
      // {
      //   komoditi_id: "",
      //   komoditi_nama: "",
      //   qty: "",
      //   selling_price: "",
      //   total: "",
      //   remarks: "",
      //   uom: "",
      //   komoditiRules: [(v) => !!v || "Komoditi harus diisi"],
      //   qtyRules: [(v) => !!v || "Qty harus diisi"],
      // },
    ],
    materials_wf: [],
    selectedMaterialIndex: 0,
    selectedWfProdIndex: 0,
    items_metode_pembayaran: [],
    items_transaction_type: [],
    items_prod_type: [],
    items_prod_status: [],
    items_doc_type: [{ code: 1, value: "SO" }],
    fotoKey: 0,

    //production order item
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Material", value: "komoditi_nama", sortable: false },
      { text: "Qty Order", value: "qty_order", sortable: false },
      { text: "UOM", value: "uom", sortable: false },
      { text: "WF Prod", value: "wf_prod_id", sortable: false },
      { text: "Qty Done", value: "qty_done", sortable: false },
      { text: "Status", value: "status_value", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    list_data: [
      {
        komoditi_id: "",
        komoditi_nama: "",
        qty_order: "",
        uom: "",
        wf_prod_id: "",
        wf_prod_nama: "",
        qty_done: "",
        status_value: "",
        komoditiRules: [(v) => !!v || "Komoditi harus diisi"],
        qtyOrderRules: [(v) => !!v || "Qty harus diisi"],
        wfProdRules: [(v) => !!v || "WF Prod harus diisi"],
      },
    ],
    options: {
      itemsPerPage: -1,
    },
    totalData: 0,
    loading: false,
    selectedRows: [],
  }),
  watch: {
    materials_raw: {
      handler() {
        this.calculateConsumptionTotal();
      },
      deep: true,
    },
  },
  mounted: async function () {
    this.getGoodMovement();
    this.getMetodePembayaran();
    this.getProdType();
    this.getProdStatus();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
    computedTanggal() {
      return this.currentData.tanggal
        ? moment(this.currentData.tanggal).format("DD MMMM YYYY")
        : "";
    },
    computedTanggalHarus() {
      return this.currentData.harus_selesai_sebelum
        ? moment(this.currentData.harus_selesai_sebelum).format("DD MMMM YYYY")
        : "";
    },
    computedTanggalPerkiraan() {
      return this.currentData.perkiraan_akhir_produksi
        ? moment(this.currentData.perkiraan_akhir_produksi).format(
            "DD MMMM YYYY"
          )
        : "";
    },
    computedCRD() {
      return this.currentData.customer_request_date
        ? moment(this.currentData.customer_request_date).format("DD MMMM YYYY")
        : "";
    },
    computedEDD() {
      return this.currentData.expected_delivery_date
        ? moment(this.currentData.expected_delivery_date).format("DD MMMM YYYY")
        : "";
    },
    computedHRD() {
      return this.currentData.expected_ho_received_date
        ? moment(this.currentData.expected_ho_received_date).format(
            "DD MMMM YYYY"
          )
        : "";
    },
    isSOOrder() {
      if (
        this.currentData.selectedProdType &&
        this.currentData.selectedProdType.code == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async getGoodMovement() {
      MasterService.goodMovementList()
        .then((res) => {
          this.items_transaction_type = res.data.list_data;

          this.items_transaction_type.forEach((entry) => {
            if (entry.MV_ID == "GR01") {
              this.currentData.selectedTransactionType = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMetodePembayaran() {
      MasterService.metodePembayaranList()
        .then((res) => {
          this.items_metode_pembayaran = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getProdType() {
      MasterService.prodTypeList()
        .then((res) => {
          this.items_prod_type = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getProdStatus() {
      MasterService.prodStatusList()
        .then((res) => {
          this.items_prod_status = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      ProductionOrderService.create(
        this.currentData,
        this.list_data,
        this.materials_raw
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Production Order berhasil");

          this.$router
            .push("/productionorder-edit/" + res.data.productionorder.id)
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Tambah Production Order gagal. Coba lagi"
          );
        });
    },

    onChangeProdType: async function () {
      // this.list_data = [];
      this.currentData.sales_order_id = "";
      this.currentData.so_no_kelompok = "";

      // if (this.currentData.selectedProdType.code == 2) {
      //   this.list_data.push({
      //     komoditi_id: "",
      //     komoditi_nama: "",
      //     qty_order: "",
      //     uom: "",
      //     wf_prod_id: "",
      //     wf_prod_nama: "",
      //     qty_done: "",
      //     status_value: "",
      //     komoditiRules: [(v) => !!v || "Komoditi harus diisi"],
      //     qtyOrderRules: [(v) => !!v || "Qty harus diisi"],
      //     wfProdRules: [(v) => !!v || "WF Prod harus diisi"],
      //   });
      // }
    },

    openDetailWF: async function () {
      this.$router.push("/productionorder-wf/1").catch((err) => {
        console.log(err);
      });
    },

    openPOModal: async function () {
      this.$refs.modalPO.getDataFromApi();
      this.dialogPO = true;
    },

    openMaterialFGModal: async function (index) {
      this.selectedMaterialIndex = index;
      this.$refs.modalMaterialAll.getDataFromApi();
      this.dialogMaterialAll = true;
    },

    openMaterialModal: async function (index) {
      this.selectedMaterialIndex = index;
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    pilihAnggota: async function (item) {
      console.log(item);
      this.currentData.user_id = item.id;
      this.currentData.user_nama = item.nama;
    },

    pilihMaterialAll: async function (item) {
      console.log(item);
      this.list_data[this.selectedMaterialIndex].so_item_id = null;
      this.list_data[this.selectedMaterialIndex].komoditi_id = item.id;
      this.list_data[this.selectedMaterialIndex].komoditi_nama = item.nama;
      this.list_data[this.selectedMaterialIndex].uom = item.uom;
    },

    pilihMaterial: async function (item) {
      console.log(item);
      this.materials_raw[this.selectedMaterialIndex].komoditi_id = item.id;
      this.materials_raw[this.selectedMaterialIndex].komoditi_nama = item.nama;
      this.materials_raw[this.selectedMaterialIndex].uom = item.uom;
      this.materials_raw[this.selectedMaterialIndex].price =
        item.production_price;
    },

    addMaterialRaw: async function () {
      this.materials_raw.push({
        komoditi_id: "",
        komoditi_nama: "",
        qty: "",
        qty_raw: "",
        qty_consumption: "",
        selling_price: "",
        remarks: "",
        uom: "",
        komoditiRules: [(v) => !!v || "Komoditi harus diisi"],
        qtyRules: [(v) => !!v || "Qty harus diisi"],
      });
    },

    deleteMaterial: async function (index) {
      this.materials_raw.splice(index, 1);
    },

    openWFModal: async function (index, item) {
      console.log(item);
      this.selectedWfProdIndex = index;

      this.dialogWF = true;
      this.$nextTick(() => {
        this.$refs.modalWF.getDataFromApi(item.komoditi_id);
      });
    },

    pilihWF: async function (item) {
      console.log(item);

      //filter raw material based on wf

      this.getRawByWf(item.id);

      this.getWfProses(item.id);

      this.$nextTick(() => {
        this.list_data[this.selectedWfProdIndex].wf_prod_id = item.id;
        this.list_data[this.selectedWfProdIndex].wf_prod_nama = item.nama_wf;
        this.tableKey++;
      });
    },

    pilihPO: async function (item) {
      console.log(item);
      this.currentData.purchase_order_id = item.id;
      this.currentData.so_no_kelompok = item.so_no_kelompok;
      this.currentData.sales_order_id = item.sales_order_id;
      this.currentData.komoditi = item.item_number + " - " + item.komoditi_nama;

      //search data production order item
      this.getDataFromApi(item.id);
    },

    cleraSoNo: async function () {
      this.currentData.purchase_order_id = null;
      this.currentData.so_no_kelompok = null;
      this.currentData.sales_order_id = null;
      this.currentData.komoditi = null;

      //search data production order item
      this.list_data = [];
    },

    getDataFromApi(purchaseOrderId) {
      this.loading = true;
      ProductionOrderService.productionOrderItemList(purchaseOrderId)
        .then((res) => {
          console.log(res.data.list_data);
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;

          this.list_data.forEach((entry) => {
            this.currentData.raw_komoditi_target = entry.komoditi_id;
            console.log(entry);
            console.log(entry.id);
            entry.id = null;
            entry.wf_key = 0;
            entry.wf_prod_id = "";
            entry.wf_prod_nama = "";
            entry.komoditiRules = [(v) => !!v || "Komoditi harus diisi"];
            entry.qtyOrderRules = [(v) => !!v || "Qty harus diisi"];
            entry.wfProdRules = [(v) => !!v || "WF Prod harus diisi"];
          });
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getRawByWf(wfId) {
      this.loading = true;
      BomService.bomByWfList(wfId)
        .then((res) => {
          res.data.list_data.forEach((entry) => {
            console.log(entry.consumption);
            this.materials_raw.push({
              komoditi_id: entry.material_bom,
              komoditi_nama: entry.material_bom_nama,
              qty: "",
              qty_raw: "",
              qty_consumption: "",
              selling_price: "",
              remarks: "",
              uom: entry.uom,
              consumption: entry.consumption,
              komoditiRules: [(v) => !!v || "Komoditi harus diisi"],
              qtyRules: [(v) => !!v || "Qty harus diisi"],
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openSelectedRow() {
      this.list_data.forEach((entry) => {
        entry.is_checked = false;
      });

      this.selectedRows.forEach((entry) => {
        console.log(entry);
        var result = this.list_data.filter((material) => {
          return material.id == entry.id;
        });

        if (result) result[0].is_checked = true;

        console.log(result);
      });
    },

    deleteItem(index) {
      this.list_data.splice(index, 1);
    },

    addItem: async function () {
      this.list_data.push({
        komoditi_id: "",
        komoditi_nama: "",
        qty_order: "",
        uom: "",
        wf_prod_id: "",
        wf_prod_nama: "",
        qty_done: "",
        status_value: "",
        komoditiRules: [(v) => !!v || "Komoditi harus diisi"],
        qtyOrderRules: [(v) => !!v || "Qty harus diisi"],
        wfProdRules: [(v) => !!v || "WF Prod harus diisi"],
      });
    },

    getWfProses(itemId) {
      WfProductionService.productionOrderItemProsesList(itemId)
        .then((res) => {
          this.materials_wf = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    calculateConsumption: async function (index) {
      console.log("change");
      console.log(this.materials_raw[index].selling_price);
      if (
        this.materials_raw[index].qty_raw &&
        this.materials_raw[index].consumption
      ) {
        this.materials_raw[index].qty_consumption =
          this.materials_raw[index].qty_raw *
          this.materials_raw[index].consumption;
      }
    },

    calculateConsumptionTotal: async function () {
      for (var i = 0; i < this.materials_raw.length; i++) {
        if (
          this.materials_raw[i].qty_raw &&
          this.materials_raw[i].consumption
        ) {
          this.materials_raw[i].qty_consumption =
            this.materials_raw[i].qty_raw * this.materials_raw[i].consumption;
        }
      }
    },
  },
};
</script>

<style scoped>
.small-font {
  font-size: 12px;
}
</style>
