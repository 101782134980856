import http from "../http-common";
/* eslint-disable */
class AnggotaKelompokService {
  anggotaList(page, itemPerPage, keyword, kelompokId) {
    return http.get(
      "/anggota/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&kelompok_id=" +
        kelompokId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(data) {
    var bodyFormData = new FormData();
    bodyFormData.append("user_id", data.user_id);

    if (data.selectedTipeAnggota && data.selectedTipeAnggota != undefined) {
      bodyFormData.append("tipe_anggota", data.selectedTipeAnggota.code);
    }

    bodyFormData.append("saham", data.saham);
    bodyFormData.append("kelompok_id", data.kelompok_id);

    return http.post("/anggota", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("user_id", data.user_id);

    if (data.selectedTipeAnggota && data.selectedTipeAnggota != undefined) {
      bodyFormData.append("tipe_anggota", data.selectedTipeAnggota.code);
    }

    bodyFormData.append("saham", data.saham);
    bodyFormData.append("kelompok_id", data.kelompok_id);

    return http.put(`/anggota/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  delete(id) {
    return http.delete(`/anggota/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new AnggotaKelompokService();
