<template>
  <div>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title>
          Permission Value
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{}">
              <v-btn color="primary" dark @click="doAdd"> Tambah </v-btn>
            </template>
            <v-card>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="pl-4 pr-4"
              >
                <v-card-title>
                  <span class="text-h5">Permission Value</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Kelompok"
                          outlined
                          dense
                          required
                          v-model="currentData.description"
                          readonly
                          @click="openKelompokModal"
                          :rules="currentData.kelompokRules"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">
                    Close
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="doSave">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <KelompokSelectComponent
      @pilihKelompok="pilihKelompok"
      ref="modalKelompok"
      v-model="dialogKelompok"
      :roleId="this.roleId"
      :permissionId="this.permissionId"
    />
  </div>
</template>

<script>
import PermissionRoleValueService from "../services/PermissionRoleValueService";
import CommonService from "../services/CommonService";

import KelompokSelectComponent from "../components/KelompokPermissionValueSelect.vue";

export default {
  props: {
    roleId: Number,
    permissionId: Number,
    valueType: String,
  },
  components: { KelompokSelectComponent },
  data: () => ({
    valid: true,
    dialogKelompok: false,
    childKey: 0,
    selectedRows: [],
    tab: null,
    breadcrumbs: [
      {
        text: "Data Authorization",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "kelompok_name", sortable: false },
      { text: "Role", value: "role_name", sortable: false },
      { text: "Permission", value: "permission_name", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      permission_value: "",
      description: "",
      kelompokRules: [(v) => !!v || "Kelompok harus diisi"],
    },
  }),
  watch: {
    selectedRows: {
      handler() {
        if (this.selectedRows != undefined && this.selectedRows[0]) {
          console.log("change");
          this.childKey++;
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PermissionRoleValueService.permissionValueList(
        page,
        itemsPerPage,
        this.search,
        this.roleId,
        this.permissionId
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.name = "";
      this.currentData.display_name = "";
      this.currentData.description = "";
      this.currentData.id = null;
    },

    doSave: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      if (this.currentData.id == null) {
        PermissionRoleValueService.create(
          this.roleId,
          this.permissionId,
          this.currentData.permission_value
        )
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah permission value berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Tambah permission value gagal. Coba lagi"
            );
          });
      } else {
        PermissionRoleValueService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update permission value berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast(
              "Update permission value gagal. Coba lagi"
            );
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.name = this.editedItem.name;
      this.currentData.display_name = this.editedItem.display_name;
      this.currentData.description = this.editedItem.description;
      this.currentData.id = this.editedItem.id;
      console.log("do add");
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PermissionRoleValueService.delete(
        this.roleId,
        this.permissionId,
        this.editedItem.permission_value
      )
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus permission value berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Hapus permission value gagal. Coba lagi"
          );
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openKelompokModal: async function () {
      this.$refs.modalKelompok.getDataFromApi();
      this.dialogKelompok = true;
    },

    pilihKelompok: async function (item) {
      console.log(item);
      this.currentData.description = item.nama;
      this.currentData.permission_value = item.id;
    },
  },
};
</script>
