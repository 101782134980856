import http from "../http-common";
/* eslint-disable */
class SalesOrderKelompokService {
  salesOrderKelompokList(salesOrderId) {
    return http.get(
      "/salesorderkelompok/list?purchase_order_id=" + salesOrderId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  getSalesOrder(id) {
    return http.get(`/salesorder/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  create(data, materials) {
    var bodyFormData = new FormData();
    if (data.po_no_cust) bodyFormData.append("po_no_cust", data.po_no_cust);
    if (data.tanggal) bodyFormData.append("tanggal", data.tanggal);
    if (data.customer_request_date)
      bodyFormData.append("customer_request_date", data.customer_request_date);
    if (data.expected_delivery_date)
      bodyFormData.append(
        "expected_delivery_date",
        data.expected_delivery_date
      );
    if (data.expected_ho_received_date)
      bodyFormData.append(
        "expected_ho_received_date",
        data.expected_ho_received_date
      );
    if (data.alamat_pengiriman)
      bodyFormData.append("alamat_pengiriman", data.alamat_pengiriman);
    if (data.shipping) bodyFormData.append("shipping", data.shipping);
    if (data.sales_person)
      bodyFormData.append("sales_person", data.sales_person);
    if (data.packaging_information)
      bodyFormData.append("packaging_information", data.packaging_information);
    if (data.notes) bodyFormData.append("notes", data.notes);
    if (data.customer_id) bodyFormData.append("customer_id", data.customer_id);
    if (data.tax) bodyFormData.append("tax", data.tax);
    if (data.discount) bodyFormData.append("discount", data.discount);
    if (data.net) bodyFormData.append("net", data.net);
    if (data.gross) bodyFormData.append("gross", data.gross);

    if (
      data.selectedMetodePembayaran &&
      data.selectedMetodePembayaran != undefined
    ) {
      bodyFormData.append(
        "metode_pembayaran",
        data.selectedMetodePembayaran.code
      );
    }

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.post("/salesorder", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  update(id, data, materials) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);

    if (data.po_no_cust) bodyFormData.append("po_no_cust", data.po_no_cust);
    if (data.tanggal) bodyFormData.append("tanggal", data.tanggal);
    if (data.customer_request_date)
      bodyFormData.append("customer_request_date", data.customer_request_date);
    if (data.expected_delivery_date)
      bodyFormData.append(
        "expected_delivery_date",
        data.expected_delivery_date
      );
    if (data.expected_ho_received_date)
      bodyFormData.append(
        "expected_ho_received_date",
        data.expected_ho_received_date
      );
    if (data.alamat_pengiriman)
      bodyFormData.append("alamat_pengiriman", data.alamat_pengiriman);
    if (data.shipping) bodyFormData.append("shipping", data.shipping);
    if (data.sales_person)
      bodyFormData.append("sales_person", data.sales_person);
    if (data.packaging_information)
      bodyFormData.append("packaging_information", data.packaging_information);
    if (data.notes) bodyFormData.append("notes", data.notes);
    if (data.customer_id) bodyFormData.append("customer_id", data.customer_id);
    if (data.tax) bodyFormData.append("tax", data.tax);
    if (data.discount) bodyFormData.append("discount", data.discount);
    if (data.net) bodyFormData.append("net", data.net);
    if (data.gross) bodyFormData.append("gross", data.gross);

    if (
      data.selectedMetodePembayaran &&
      data.selectedMetodePembayaran != undefined
    ) {
      bodyFormData.append(
        "metode_pembayaran",
        data.selectedMetodePembayaran.code
      );
    }

    bodyFormData.append("materials", JSON.stringify(materials));

    return http.put(`/salesorder/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  delete(id) {
    return http.delete(`/salesorder/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new SalesOrderKelompokService();
