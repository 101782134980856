<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> News </v-card-title>
        <form class="pl-4 pr-4">
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-sheet color="white" elevation="2">
                <v-responsive :aspect-ratio="16 / 9">
                  <v-img
                    :key="fotoKey"
                    :src="currentData.foto_user"
                    contain
                  ></v-img>
                </v-responsive>
              </v-sheet>
              <v-file-input
                @change="loadImage"
                accept="image/*"
                v-model="currentData.file_user"
                prepend-icon="mdi-image"
                show-size
                label="Foto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Judul"
                    outlined
                    dense
                    v-model="currentData.judul"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Konten"
                    dense
                    value=""
                    rows="6"
                    v-model="currentData.konten"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import NewsService from "../services/NewsService";
import CommonService from "../services/CommonService";

export default {
  name: "NewsAdd",
  components: {},
  data: () => ({
    breadcrumbs: [
      {
        text: "Data News",
        disabled: false,
        exact: true,
        to: { name: "News" },
      },
      { text: "Tambah News", disabled: true },
    ],
    currentData: {
      judul: "",
      konten: "",
      foto_user: "",
      file_user: null,
    },
    fotoKey: 0,
    result: {},
  }),
  mounted: async function () {},
  computed: {},
  methods: {
    loadImage: function (data) {
      console.log("loadImage:", data);
      if (data) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentData.foto_user = e.target.result;
          this.fotoKey++;
        };
        reader.readAsDataURL(data);
      } else {
        this.currentData.foto_user = "";
        this.currentData.file_user = null;
      }
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      NewsService.create(this.currentData)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah news berhasil");

          this.$router.push("/news-edit/" + res.data.news.id).catch((err) => {
            console.log(err);
          });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Tambah news gagal. Coba lagi");
        });
    },
  },
};
</script>

<style scoped>
</style>
