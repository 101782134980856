import http from "../http-common";
/* eslint-disable */
class PermissionRoleService {
  permissionList(page, itemPerPage, keyword, roleId) {
    return http.get(
      "/permission-role/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&role_id=" +
        roleId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  permissionUnrolList(page, itemPerPage, keyword, roleId) {
    return http.get(
      "/permission-unrole/list?page=" +
        page +
        "&item_per_page=" +
        itemPerPage +
        "&keyword=" +
        keyword +
        "&role_id=" +
        roleId,
      {
        headers: {
          "Content-type": "application/json",
          Authorization:
            "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
        },
      }
    );
  }

  create(permissionId, roleId) {
    var bodyFormData = new FormData();
    bodyFormData.append("role_id", roleId);
    bodyFormData.append("permission_id", permissionId);

    return http.post("/permission-role", bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  update(id, data) {
    var bodyFormData = new FormData();
    bodyFormData.append("role_id", data.role_id);
    bodyFormData.append("permission_id", data.permission_id);

    return http.put(`/permission-role/${id}`, bodyFormData, {
      headers: {
        "Content-Type": "multipart/form-data;boundary=MyBoundary",
      },
    });
  }

  delete(id, permissionId) {
    return http.delete(`/permission-role/${id}/${permissionId}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new PermissionRoleService();
