var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" SO Kelompok "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari SO No","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Apa anda akan menghapus data ini?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Tidak")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Ya")]),_c('v-spacer')],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list_data,"options":_vm.options,"server-items-length":_vm.totalData,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.foto",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"height":"auto","width":"100","src":item.foto}})]}},{key:"item.konten",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.getKonten(item.konten)))])]}},{key:"item.po_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCurrentDate(item.po_date))+" ")]}},{key:"item.status_kelompok",fn:function(ref){
var item = ref.item;
return [(item.status_kelompok == 0)?_c('p',[_vm._v("Tidak Aktif")]):_c('p',[_vm._v("Aktif")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{staticStyle:{"margin-left":"20px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openSOKelompok(item)}}},[_c('v-list-item-title',[_vm._v("View")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openDelivery(item)}}},[_c('v-list-item-title',[_vm._v("Delivery")])],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }