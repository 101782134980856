import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import Dashboard from "../views/Dashboard.vue";
import DashboardDetail from "../views/DashboardDetail.vue";
import SalesOrder from "../views/SalesOrder.vue";
import PurchaseOrder from "../views/PurchaseOrder.vue";
import SalesOrderAdd from "../views/SalesOrderAdd.vue";
import PurchaseOrderAdd from "../views/PurchaseOrderAdd.vue";
import SalesOrderEdit from "../views/SalesOrderEdit.vue";
import PurchaseOrderEdit from "../views/PurchaseOrderEdit.vue";
import Anggota from "../views/Anggota.vue";
import News from "../views/News.vue";
import SOKelompok from "../views/SOKelompok.vue";
import OrderToAnggota from "../views/OrderToAnggota.vue";
import OrderToAnggotaAdd from "../views/OrderToAnggotaAdd.vue";
import OrderToAnggotaEdit from "../views/OrderToAnggotaEdit.vue";
import GoodReceipt from "../views/GoodReceipt.vue";
import ProductionOrder from "../views/ProductionOrder.vue";
import ProductionOrderAdd from "../views/ProductionOrderAdd.vue";
import ProductionOrderEdit from "../views/ProductionOrderEdit.vue";
import ProductionOrderWF from "../views/ProductionOrderWF.vue";
import GoodsMovement from "../views/GoodsMovement.vue";
import StockReport from "../views/StockReport.vue";
import GoodReceiptAdd from "../views/GoodReceiptAdd.vue";
import GoodReceiptEdit from "../views/GoodReceiptEdit.vue";
import SOKelompokDetail from "../views/SOKelompokDetail.vue";
import Delivery from "../views/Delivery.vue";
import DeliveryAdd from "../views/DeliveryAdd.vue";
import DeliveryEdit from "../views/DeliveryEdit.vue";
import GoodIssue from "../views/GoodIssue.vue";
import GoodIssueProduction from "../views/GoodIssueProduction.vue";
import GoodIssuePOAnggota from "../views/GoodIssuePOAnggota.vue";
import NewsAdd from "../views/NewsAdd.vue";
import NewsEdit from "../views/NewsEdit.vue";
import AnggotaAdd from "../views/AnggotaAdd.vue";
import AnggotaEdit from "../views/AnggotaEdit.vue";
import QualityControl from "../views/QualityControl.vue";
import QualityControlPOAnggota from "../views/QualityControlPOAnggota.vue";

import User from "../views/User.vue";
import Customer from "../views/Customer.vue";
import UserAdd from "../views/UserAdd.vue";
import CustomerAdd from "../views/CustomerAdd.vue";
import UserEdit from "../views/UserEdit.vue";
import CustomerEdit from "../views/CustomerEdit.vue";

import Vendor from "../views/Vendor.vue";
import VendorAdd from "../views/VendorAdd.vue";
import VendorEdit from "../views/VendorEdit.vue";

import Login from "../views/Login.vue";
import Wilayah from "../views/Wilayah.vue";
import Kabupaten from "../views/Kabupaten.vue";
import JenisKegiatan from "../views/JenisKegiatan.vue";
import Authorization from "../views/Authorization.vue";
import MaterialType from "../views/MaterialType.vue";
import Invoice from "../views/Invoice.vue";
import EmptyPage from "../views/404.vue";
import Bom from "../views/Bom.vue";
import Loan from "../views/Loan.vue";
import Payment from "../views/Payment.vue";
import GroupMaterial from "../views/GroupMaterial.vue";
import Material from "../views/Material.vue";
import Kelompok from "../views/Kelompok.vue";
import WfProduction from "../views/WfProduction.vue";
import WfProductionAdd from "../views/WfProductionAdd.vue";
import WfProductionEdit from "../views/WfProductionEdit.vue";
import KelompokAdd from "../views/KelompokAdd.vue";
import KelompokEdit from "../views/KelompokEdit.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    props: true,
    component: Login,
    meta: {
      isHome: 0,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    props: true,
    component: Dashboard,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard-detail/:id",
    name: "DashboardDetail",
    props: true,
    component: DashboardDetail,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-dashboard-detail",
    },
  },
  {
    path: "/salesorder",
    name: "SalesOrder",
    props: true,
    component: SalesOrder,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-sales-order",
    },
  },
  {
    path: "/salesorder-add",
    name: "SalesOrderAdd",
    props: true,
    component: SalesOrderAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-sales-order",
    },
  },
  {
    path: "/salesorder-edit/:id",
    name: "SalesOrderEdit",
    props: true,
    component: SalesOrderEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-sales-order",
    },
  },
  {
    path: "/purchaseorder",
    name: "PurchaseOrder",
    props: true,
    component: PurchaseOrder,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-purchase-order",
    },
  },
  {
    path: "/purchaseorder-add",
    name: "PurchaseOrderAdd",
    props: true,
    component: PurchaseOrderAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-purchase-order",
    },
  },
  {
    path: "/purchaseorder-edit/:id/:po_id",
    name: "PurchaseOrderEdit",
    props: true,
    component: PurchaseOrderEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-purchase-order",
    },
  },
  {
    path: "/user",
    name: "User",
    props: true,
    component: User,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/customer",
    name: "Customer",
    props: true,
    component: Customer,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-customer",
    },
  },
  {
    path: "/user-add",
    name: "UserAdd",
    props: true,
    component: UserAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-user",
    },
  },
  {
    path: "/customer-add",
    name: "CustomerAdd",
    props: true,
    component: CustomerAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-customer",
    },
  },
  {
    path: "/user-edit/:id",
    name: "UserEdit",
    props: true,
    component: UserEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-user",
    },
  },
  {
    path: "/customer-edit/:id",
    name: "CustomerEdit",
    props: true,
    component: CustomerEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-customer",
    },
  },
  {
    path: "/vendor",
    name: "Vendor",
    props: true,
    component: Vendor,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-vendor",
    },
  },
  {
    path: "/vendor-add",
    name: "VendorAdd",
    props: true,
    component: VendorAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-vendor",
    },
  },
  {
    path: "/vendor-edit/:id",
    name: "VendorEdit",
    props: true,
    component: VendorEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-vendor",
    },
  },
  {
    path: "/wilayah",
    name: "Wilayah",
    props: true,
    component: Wilayah,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/kabupaten/:id",
    name: "Kabupaten",
    props: true,
    component: Kabupaten,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/authorization",
    name: "Authorization",
    props: true,
    component: Authorization,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-authorization",
    },
  },
  {
    path: "/jenis-kegiatan",
    name: "JenisKegiatan",
    props: true,
    component: JenisKegiatan,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-jenis-kegiatan",
    },
  },
  {
    path: "/material-type",
    name: "MaterialType",
    props: true,
    component: MaterialType,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-material-type",
    },
  },
  {
    path: "/bom",
    name: "Bom",
    props: true,
    component: Bom,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
  {
    path: "/loan",
    name: "Loan",
    props: true,
    component: Loan,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-loan",
    },
  },
  {
    path: "/payment",
    name: "Payment",
    props: true,
    component: Payment,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-payment",
    },
  },
  {
    path: "/group-material",
    name: "GroupMaterial",
    props: true,
    component: GroupMaterial,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-group-material",
    },
  },
  {
    path: "/material",
    name: "Material",
    props: true,
    component: Material,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-material",
    },
  },
  {
    path: "/wf-production",
    name: "WfProduction",
    props: true,
    component: WfProduction,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-wf-production",
    },
  },
  {
    path: "/wf-production-add",
    name: "WfProductionAdd",
    props: true,
    component: WfProductionAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-wf-production",
    },
  },
  {
    path: "/wf-production-edit/:id",
    name: "WfProductionEdit",
    props: true,
    component: WfProductionEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-wf-production",
    },
  },
  {
    path: "/anggota",
    name: "Anggota",
    props: true,
    component: Anggota,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-user",
    },
  },
  {
    path: "/anggota-add",
    name: "AnggotaAdd",
    props: true,
    component: AnggotaAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-user",
    },
  },
  {
    path: "/anggota-edit/:id",
    name: "AnggotaEdit",
    props: true,
    component: AnggotaEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-user",
    },
  },
  {
    path: "/sokelompok",
    name: "SOKelompok",
    props: true,
    component: SOKelompok,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-so-kelompok",
    },
  },
  {
    path: "/sokelompok-detail/:id",
    name: "SOKelompokDetail",
    props: true,
    component: SOKelompokDetail,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-so-kelompok-detail",
    },
  },
  {
    path: "/ordertoanggota",
    name: "OrderToAnggota",
    props: true,
    component: OrderToAnggota,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-order-anggota",
    },
  },
  {
    path: "/ordertoanggota-add",
    name: "OrderToAnggotaAdd",
    props: true,
    component: OrderToAnggotaAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-order-anggota",
    },
  },
  {
    path: "/ordertoanggota-edit/:id",
    name: "OrderToAnggotaEdit",
    props: true,
    component: OrderToAnggotaEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-order-anggota",
    },
  },
  {
    path: "/goodreceipt",
    name: "GoodReceipt",
    props: true,
    component: GoodReceipt,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-good-receipt",
    },
  },
  {
    path: "/goodreceipt-add",
    name: "GoodReceiptAdd",
    props: true,
    component: GoodReceiptAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-good-receipt",
    },
  },
  {
    path: "/goodreceipt-edit/:id",
    name: "GoodReceiptEdit",
    props: true,
    component: GoodReceiptEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-good-receipt",
    },
  },
  {
    path: "/productionorder",
    name: "ProductionOrder",
    props: true,
    component: ProductionOrder,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-production-order",
    },
  },
  {
    path: "/productionorder-add",
    name: "ProductionOrderAdd",
    props: true,
    component: ProductionOrderAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-production-order",
    },
  },
  {
    path: "/productionorder-edit/:id",
    name: "ProductionOrderEdit",
    props: true,
    component: ProductionOrderEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-production-order",
    },
  },
  {
    path: "/productionorder-wf/:id",
    name: "ProductionOrderWF",
    props: true,
    component: ProductionOrderWF,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "detail-workflow",
    },
  },
  {
    path: "/qualitycontrol/:id",
    name: "QualityControl",
    props: true,
    component: QualityControl,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-qc-process",
    },
  },
  {
    path: "/qualitycontrol-po/:id",
    name: "QualityControlPOAnggota",
    props: true,
    component: QualityControlPOAnggota,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-qc-process",
    },
  },
  {
    path: "/goodsmovement",
    name: "GoodsMovement",
    props: true,
    component: GoodsMovement,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-goods-movement",
    },
  },
  {
    path: "/stockreport",
    name: "StockReport",
    props: true,
    component: StockReport,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-stock-report",
    },
  },
  {
    path: "/delivery/:id",
    name: "Delivery",
    props: true,
    component: Delivery,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-delivery",
    },
  },
  {
    path: "/delivery-add/:id",
    name: "DeliveryAdd",
    props: true,
    component: DeliveryAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-delivery",
    },
  },
  {
    path: "/delivery-edit/:id",
    name: "DeliveryEdit",
    props: true,
    component: DeliveryEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-delivery",
    },
  },
  {
    path: "/goodissue/:id",
    name: "GoodIssue",
    props: true,
    component: GoodIssue,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-good-issue",
    },
  },
  {
    path: "/goodissue-prod/:id",
    name: "GoodIssueProduction",
    props: true,
    component: GoodIssueProduction,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-good-issue",
    },
  },
  {
    path: "/goodissue-po/:id",
    name: "GoodIssuePOAnggota",
    props: true,
    component: GoodIssuePOAnggota,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-good-issue",
    },
  },
  {
    path: "/news",
    name: "News",
    props: true,
    component: News,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-news",
    },
  },
  {
    path: "/news-add",
    name: "NewsAdd",
    props: true,
    component: NewsAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-news",
    },
  },
  {
    path: "/news-edit/:id",
    name: "NewsEdit",
    props: true,
    component: NewsEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-news",
    },
  },
  {
    path: "/kelompok",
    name: "Kelompok",
    props: true,
    component: Kelompok,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-kelompok",
    },
  },
  {
    path: "/kelompok-add",
    name: "KelompokAdd",
    props: true,
    component: KelompokAdd,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "create-kelompok",
    },
  },
  {
    path: "/kelompok-edit/:id",
    name: "KelompokEdit",
    props: true,
    component: KelompokEdit,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "edit-kelompok",
    },
  },
  {
    path: "/invoice",
    name: "Invoice",
    props: true,
    component: Invoice,
    meta: {
      isHome: 1,
      requiresAuth: true,
      permission_name: "view-invoice",
    },
  },
  {
    path: "/404",
    name: "404",
    props: true,
    component: EmptyPage,
    meta: {
      isHome: 1,
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const userPermission =
    localStorage.getItem(process.env.VUE_APP_USER_PERMISSION) || "";

  if (userPermission) {
    const permission = JSON.parse(
      localStorage.getItem(process.env.VUE_APP_USER_PERMISSION) || ""
    );

    // console.log(permission[0].permission_name);
    // console.log(to.meta.permission_name);
    // console.log(to.path);

    var allowed = false;

    if (to.path != "/" && to.path != "/dashboard" && to.path != "/404") {
      if (permission != null && permission.length > 0) {
        console.log("ini list permission");
        permission.forEach((element) => {
          console.log(element.permission_name + " " + to.meta.permission_name);
          if (element.permission_name == to.meta.permission_name) {
            allowed = true;
          }
        });

        if (!allowed) {
          next("/404");
        }
      } else {
        next("/404");
      }
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
