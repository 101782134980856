<template>
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <!-- If using vue-router -->
      <!-- <router-view></router-view> -->
      <v-card>
        <v-card-title> Data Order Penjualan Kelompok </v-card-title>
        <v-form ref="form" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-text-field
                label="Order Penjualan Kelompok"
                outlined
                dense
                required
                readonly
                :value="currentData.so_no_kelompok"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Tanggal Order"
                outlined
                dense
                required
                readonly
                :value="currentData.po_date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Currency"
                outlined
                dense
                required
                readonly
                :value="currentData.currency"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-text-field
                label="Referensi Order Pembelian HO"
                outlined
                dense
                required
                readonly
                :value="currentData.po_no"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Status"
                outlined
                dense
                required
                readonly
                :value="currentData.status_value"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" class="d-flex justify-start">
              <v-btn
                class="mr-2"
                dark
                type="button"
                :color="currentData.status > 1 ? 'grey' : 'green'"
                :readonly="currentData.status > 1"
                @click="openConfirm"
                >Confirm</v-btn
              >
              <v-btn
                dark
                type="button"
                :color="currentData.status > 1 ? 'grey' : 'red'"
                :readonly="currentData.status > 1"
                >Reject</v-btn
              >
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <v-btn
                class="mr-2"
                dark
                type="button"
                :color="currentData.status != 2 ? 'grey' : 'green'"
                :readonly="currentData.status != 2"
                @click="processFromStock"
                >Process from Stock</v-btn
              >
              <v-btn
                class="mr-2"
                dark
                type="button"
                color="green"
                @click="openDelivery"
                >Delivery</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-card class="mt-6">
        <v-card-title>
          <v-spacer></v-spacer>

          <!-- <router-link
            to="/news-add"
            style="text-decoration: none; color: inherit"
          >
            <v-btn color="purple" dark class="mb-2"> Tambah </v-btn>
          </router-link> -->
          <v-dialog v-model="dialogConfirm" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan mengkonfirmasi data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeConfirm"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="submitConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:[`item.foto`]="{ item }">
            <v-img height="auto" width="100" :src="item.foto"></v-img>
          </template>
          <template v-slot:[`item.qty`]="{ item }">
            <span>{{ numberWithCommas(item.qty) }}</span>
          </template>
          <template v-slot:[`item.production_price`]="{ item }">
            <span>{{ numberWithCommas(item.production_price) }}</span>
          </template>
          <template v-slot:[`item.qty_delivery`]="{ item }">
            <span v-if="item.qty_delivery">{{
              numberWithCommas(item.qty_delivery)
            }}</span>
          </template>
          <template v-slot:[`item.konten`]="{ item }">
            <p>{{ getKonten(item.konten) }}</p>
          </template>
          <template v-slot:[`item.po_date`]="{ item }">
            {{ getCurrentDate(item.po_date) }}
          </template>
          <template v-slot:[`item.status_kelompok`]="{ item }">
            <p v-if="item.status_kelompok == 0">Tidak Aktif</p>
            <p v-else>Aktif</p>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu style="margin-left: 20px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium v-bind="attrs" v-on="on">
                  mdi-dots-vertical
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="openSOKelompok(item)">
                  <v-list-item-title>View</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openDelivery(item)">
                  <v-list-item-title>Delivery</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
      <v-card class="mt-4">
        <GoodIssueByPOComponent :id="id" />
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import PurchaseOrderService from "../services/PurchaseOrderService";
import SalesOrderKelompokService from "../services/SalesOrderKelompokService";
import GoodIssueByPOComponent from "./GoodIssueByPO.vue";

import CommonService from "../services/CommonService";
import * as moment from "moment";

export default {
  components: {
    GoodIssueByPOComponent,
  },
  props: {
    id: String,
  },
  data: () => ({
    dialogConfirm: false,
    dialogReject: false,
    breadcrumbs: [
      {
        text: "Data Order Penjualan Kelompok",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Material", value: "komoditi_nama", sortable: false },
      { text: "Ref Item No", value: "item_number", sortable: false },
      { text: "Qty Order", value: "qty", sortable: false },
      { text: "Harga Jual", value: "production_price", sortable: false },
      { text: "Qty Terpenuhi", value: "qty_delivery", sortable: false },
      { text: "UOM", value: "uom", sortable: false },
      { text: "Status", value: "status_value", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,

    currentData: {
      so_no_kelompok: "",
      po_no: "",
      po_date: "",
      status: "",
      status_value: "",
    },
  }),
  watch: {
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getPurchaseOrder();
    this.getDataFromApi();
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },
    getKonten(konten) {
      if (konten.length > 100) {
        return konten.substring(0, 100) + "...";
      } else {
        return konten;
      }
    },
    getDataFromApi() {
      this.loading = true;
      SalesOrderKelompokService.salesOrderKelompokList(this.id)
        .then((res) => {
          this.list_data = res.data.list_data;
          console.log(this.list_data);
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getPurchaseOrder() {
      PurchaseOrderService.getPurchaseOrder(this.id)
        .then((res) => {
          this.currentData.so_no_kelompok =
            res.data.purchase_order.so_no_kelompok;
          this.currentData.po_no = res.data.purchase_order.po_no;
          this.currentData.status = res.data.purchase_order.status;
          this.currentData.status_value = res.data.purchase_order.status_value;
          this.currentData.currency = res.data.purchase_order.currency;
          this.currentData.po_date = moment(
            res.data.purchase_order.po_date
          ).format("DD MMMM YYYY");
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.$router.push("/news-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    submitConfirm() {
      this.closeConfirm();

      PurchaseOrderService.confirm(this.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("SO Kelompok berhasil di konfirmasi");

          this.getPurchaseOrder();
          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "SO Kelompok gagal dikonfirmasi. Coba lagi"
          );
        });
    },

    deleteItemConfirm() {
      this.closeDelete();

      PurchaseOrderService.delete(this.editedItem.id)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Hapus news berhasil");

          this.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus news gagal. Coba lagi");
        });
    },

    closeConfirm() {
      this.dialogConfirm = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    openConfirm() {
      this.dialogConfirm = true;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    processFromStock() {
      this.$router.push("/goodissue/" + this.id).catch((err) => {
        console.log(err);
      });
    },

    openSOKelompok(item) {
      console.log(item);

      this.$router.push("/sokelompok-edit/" + item.id).catch((err) => {
        console.log(err);
      });
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    openDelivery() {
      this.$router.push("/delivery/" + this.id).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>
