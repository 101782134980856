import http from "../http-common";
/* eslint-disable */
class ReportService {
  downloadInvoice(id) {
    return http.get(`/report/invoice/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }

  downloadSuratJalan(id) {
    return http.get(`/report/suratjalan/download/${id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN_PREFIX),
      },
    });
  }
}

export default new ReportService();
