<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Add Good Receipt </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-combobox
                label="Doc Type"
                dense
                outlined
                v-model="currentData.selectedDocType"
                :items="items_doc_type"
                item-text="value"
                :rules="currentData.docTypeRules"
                @change="onChangeDocType"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Warehouse"
                outlined
                dense
                required
                v-model="currentData.warehouse_nama"
                @click="openWarehouseModal"
                :rules="currentData.warehouseRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="currentData.menuTanggal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggal"
                    label="Tanggal Transaksi"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.tanggalRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.tanggal"
                  @input="currentData.menuTanggal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Doc No"
                outlined
                dense
                required
                v-model="currentData.no_kegiatan"
                readonly
                @click="openPoModal"
                :rules="currentData.poNoRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-combobox
                label="Transaction Type"
                dense
                outlined
                readonly
                v-model="currentData.selectedTransactionType"
                :items="items_transaction_type"
                item-text="MV_DESC"
                :rules="currentData.transactionTypeRules"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Batch No"
                outlined
                dense
                required
                readonly
                value="GENERATED"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="list_data"
                :options.sync="options"
                :server-items-length="totalData"
                :loading="loading"
                class="elevation-1"
                show-select
                v-model="selectedRows"
                @input="openSelectedRow"
              >
                <template v-slot:[`item.qty_ok`]="{ item }">
                  <div v-if="!item.is_checked">
                    <v-text-field
                      outlined
                      dense
                      required
                      :disabled="!item.is_checked"
                      :background-color="item.is_checked ? 'white' : '#ddd'"
                      :key="item.id"
                      v-model="item.qty_ok"
                    ></v-text-field>
                  </div>
                  <div v-else>
                    <v-text-field
                      outlined
                      dense
                      required
                      :disabled="!item.is_checked"
                      :background-color="item.is_checked ? 'white' : '#ddd'"
                      :key="item.id"
                      v-model="item.qty_ok"
                      :rules="qtyRules"
                    ></v-text-field>
                  </div>
                </template>
                <template v-slot:[`item.qty_ng`]="{ item }">
                  <div v-if="!item.is_checked">
                    <v-text-field
                      outlined
                      dense
                      required
                      :disabled="!item.is_checked"
                      :background-color="item.is_checked ? 'white' : '#ddd'"
                      :key="item.id"
                      v-model="item.qty_ng"
                    ></v-text-field>
                  </div>
                  <div v-else>
                    <v-text-field
                      outlined
                      dense
                      required
                      :disabled="!item.is_checked"
                      :background-color="item.is_checked ? 'white' : '#ddd'"
                      :key="item.id"
                      v-model="item.qty_ng"
                      :rules="qtyRules"
                    ></v-text-field>
                  </div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu style="margin-left: 20px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon medium v-bind="attrs" v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <v-list>
                      <v-list-item @click="openSOKelompok(item)">
                        <v-list-item-title>View</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openDelivery(item)">
                        <v-list-item-title>Delivery</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
    <POAnggotaSelectComponent
      @pilihPO="pilihPO"
      ref="modalPo"
      v-model="dialogPo"
    />
    <ProductionOrderSelectComponent
      @pilihProd="pilihProd"
      ref="modalProd"
      v-model="dialogProd"
    />
    <WarehouseSelectComponent
      @pilihWarehouse="pilihWarehouse"
      ref="modalWarehouse"
      v-model="dialogWarehouse"
      :id="currentData.kelompok_id"
    />
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import moment from "moment";
// import { format, parseISO } from "date-fns";

import WarehouseSelectComponent from "../components/WarehouseAllSelect.vue";
import POAnggotaSelectComponent from "../components/POAnggotaSelect.vue";
import ProductionOrderSelectComponent from "../components/ProductionOrderSelect.vue";
import GoodReceiveService from "../services/GoodReceiveService";

export default {
  name: "GoodReceiptAdd",
  components: {
    WarehouseSelectComponent,
    POAnggotaSelectComponent,
    ProductionOrderSelectComponent,
  },
  data: () => ({
    dialogPo: false,
    dialogProd: false,
    dialogMaterial: false,
    dialogWarehouse: false,
    breadcrumbs: [
      {
        text: "Data GR",
        disabled: false,
        exact: true,
        to: { name: "GoodReceipt" },
      },
      { text: "Tambah GR", disabled: true },
    ],
    valid: true,
    currentData: {
      kegiatan_id: "",
      production_order_id: "",
      order_no: "",
      no_kegiatan: "",
      menuTanggal: false,
      tanggal: "",
      user_id: "",
      user_nama: "",
      total: "",
      warehouse_id: "",
      warehouse_nama: "",
      selectedTransactionType: "",
      selectedDocType: "",
      docTypeRules: [(v) => !!v || "Doc Type harus diisi"],
      warehouseRules: [(v) => !!v || "Warehouse harus diisi"],
      tanggalRules: [(v) => !!v || "Tanggal Transaksi harus diisi"],
      poNoRules: [(v) => !!v || "PO No harus diisi"],
      transactionTypeRules: [(v) => !!v || "Transaction Type harus diisi"],
    },
    qtyRules: [(v) => !!v || "Qty harus diisi"],
    selectedMaterialIndex: 0,
    items_metode_pembayaran: [],
    items_transaction_type: [],
    items_doc_type: [],
    fotoKey: 0,

    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Material", value: "komoditi_nama", sortable: false },
      { text: "Qty Order", value: "qty", sortable: false },
      { text: "Qty OK", value: "qty_ok", sortable: false },
      // { text: "Qty NG", value: "qty_ng", sortable: false },
      { text: "UOM", value: "uom", sortable: false },
    ],
    list_data: [],
    options: {
      itemsPerPage: -1,
    },
    totalData: 0,
    loading: false,
    selectedRows: [],
  }),
  mounted: async function () {
    this.getGoodMovement();
    this.getMetodePembayaran();
    this.getDocType();
    this.currentData.tanggal = moment(Date.now()).format("YYYY-MM-DD");
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
    computedTanggal() {
      return this.currentData.tanggal
        ? moment(this.currentData.tanggal).format("DD MMMM YYYY")
        : "";
    },
    computedCRD() {
      return this.currentData.customer_request_date
        ? moment(this.currentData.customer_request_date).format("DD MMMM YYYY")
        : "";
    },
    computedEDD() {
      return this.currentData.expected_delivery_date
        ? moment(this.currentData.expected_delivery_date).format("DD MMMM YYYY")
        : "";
    },
    computedHRD() {
      return this.currentData.expected_ho_received_date
        ? moment(this.currentData.expected_ho_received_date).format(
            "DD MMMM YYYY"
          )
        : "";
    },
  },
  methods: {
    openSelectedRow() {
      console.log(this.selectedRows);

      this.list_data.forEach((entry) => {
        entry.is_checked = false;
      });

      this.selectedRows.forEach((entry) => {
        console.log(entry);
        var result = this.list_data.filter((material) => {
          return material.id == entry.id;
        });

        if (result) result[0].is_checked = true;

        console.log(result);
      });
    },

    async getGoodMovement() {
      MasterService.goodMovementList()
        .then((res) => {
          this.items_transaction_type = res.data.list_data;

          this.items_transaction_type.forEach((entry) => {
            if (entry.MV_ID == "GR01") {
              this.currentData.selectedTransactionType = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onChangeDocType: async function () {
      console.log(this.currentData.selectedDocType.code);
      this.items_transaction_type.forEach((entry) => {
        if (this.currentData.selectedDocType.code == 1) {
          if (entry.MV_ID == "GR01") {
            this.currentData.selectedTransactionType = entry;
          }
        } else {
          if (entry.MV_ID == "GR03") {
            this.currentData.selectedTransactionType = entry;
          }
        }
      });
    },

    async getMetodePembayaran() {
      MasterService.metodePembayaranList()
        .then((res) => {
          this.items_metode_pembayaran = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getDocType() {
      MasterService.docTypeList()
        .then((res) => {
          this.items_doc_type = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      var materials = [];
      // var isErrorOutstanding = false;
      // var currentEntry;
      // var BreakException = {};
      var isMaterialExists = 0;
      // try {
      this.list_data.forEach((entry) => {
        if (entry.is_checked) {
          // if (
          //   entry.qty !=
          //   (entry.qty_ok ? parseInt(entry.qty_ok) : 0) +
          //     (entry.qty_ng ? parseInt(entry.qty_ng) : 0)
          // ) {
          //   isErrorOutstanding = true;
          //   currentEntry = entry;
          //   throw BreakException;
          // }
          isMaterialExists++;
          materials.push({
            komoditi_id: entry.komoditi_id,
            kegiatan_item_id: entry.id,
            production_order_item_id: entry.id,
            qty_ok: entry.qty_ok,
            qty_ng: entry.qty_ng,
          });
        }
      });
      // } catch (e) {
      //   if (e !== BreakException) {
      //     console.log(e);
      //   }
      // }

      // if (isErrorOutstanding) {
      //   console.log(currentEntry);
      //   CommonService.showErrorToast("Total Qty tidak sama dengan qty order");
      //   return;
      // }

      if (isMaterialExists == 0) {
        CommonService.showErrorToast("Pilih material untuk menyimpan");
        return;
      }

      GoodReceiveService.create(this.currentData, materials)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Good Receipt berhasil");

          this.$router
            .push("/goodreceipt-edit/" + res.data.goodreceive.id)
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Tambah Good Receipt gagal. Coba lagi");
        });
    },

    openPoModal: async function () {
      if (this.currentData.selectedDocType) {
        if (this.currentData.selectedDocType.code == 1) {
          this.$refs.modalPo.getDataFromApi();
          this.dialogPo = true;
        } else {
          this.$refs.modalProd.getDataFromApi();
          this.dialogProd = true;
        }
      }
    },

    openMaterialModal: async function (index) {
      this.selectedMaterialIndex = index;
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    pilihAnggota: async function (item) {
      console.log(item);
      this.currentData.user_id = item.id;
      this.currentData.user_nama = item.nama;
    },

    openWarehouseModal: async function () {
      this.$refs.modalWarehouse.getDataFromApi();
      this.dialogWarehouse = true;
    },

    pilihWarehouse: async function (item) {
      console.log(item);
      this.currentData.warehouse_id = item.id;
      this.currentData.warehouse_nama = item.nama;
    },

    pilihPO: async function (item) {
      console.log(item);
      this.currentData.kegiatan_id = item.id;
      this.currentData.no_kegiatan = item.no_kegiatan;

      //select material list from po anggota
      this.getDataFromApi(item.id);
    },

    pilihProd: async function (item) {
      console.log(item);
      this.currentData.production_order_id = item.id;
      this.currentData.no_kegiatan = item.pro_no;

      //select material list from po anggota
      this.getDataFromProd(item.id);
    },

    getDataFromApi(kegiatanId) {
      this.loading = true;
      GoodReceiveService.poAnggotaToGoodReceiveList(kegiatanId)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;

          this.list_data.forEach((entry) => {
            entry.is_checked = false;
            entry.qty_ok = "";
            entry.qty_ng = "";
          });
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getDataFromProd(prodId) {
      this.loading = true;
      GoodReceiveService.prodOrderToGoodReceiveList(prodId)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;

          this.list_data.forEach((entry) => {
            entry.is_checked = false;
            entry.qty_ok = "";
            entry.qty_ng = "";
          });
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.small-font {
  font-size: 12px;
}
</style>
