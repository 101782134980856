<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Production Order Detail Workflow </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="PRO No"
                outlined
                dense
                disabled
                :value="currentData.pro_no"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-text-field
                :value="computedTanggal"
                label="Tanggal Produksi"
                prepend-icon="mdi-calendar"
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <h3 class="mr-auto">Target Finish Goods</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="25%" class="text-left">Material</th>
                      <th width="10%" class="text-left">Qty Order</th>
                      <th width="10%" class="text-left">UOM</th>
                      <th width="10%" class="text-left">Qty Done</th>
                      <th width="20%" class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in materials" :key="i">
                      <td style="padding: 6px">{{ item.komoditi_nama }}</td>
                      <td>{{ item.qty_order }}</td>
                      <td>{{ item.uom }}</td>
                      <td>{{ item.qty_done }}</td>
                      <td>{{ item.status_value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h3>List Proses</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="10%" class="text-left">Sequence</th>
                      <th width="20%" class="text-left">Steps WF</th>
                      <th width="20%" class="text-left">Process Date</th>
                      <th width="10%" class="text-left">Qty OK</th>
                      <th width="10%" class="text-left">Qty NG</th>
                      <th width="10%" class="text-left">Status</th>
                      <th width="10%" class="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in materials_raw" :key="i">
                      <td style="padding: 6px">{{ item.seq }}</td>
                      <td>{{ item.nama_proses }}</td>
                      <td>{{ getCurrentDate(item.proses_date) }}</td>
                      <td>{{ item.qty_ok }}</td>
                      <td>{{ item.qty_ng }}</td>
                      <td>{{ item.status_value }}</td>
                      <td>
                        <v-icon medium class="mr-3" @click="editItem(item)">
                          mdi-pencil
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Kembali</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Proses</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="formproses"
            v-model="validproses"
            lazy-validation
            class="pl-4 pr-4"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Qty OK"
                    required
                    v-model="currentProses.qty_ok"
                    :rules="currentProses.qtyOkRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Qty NG"
                    required
                    v-model="currentProses.qty_ng"
                    :rules="currentProses.qtyNgRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    label="Status"
                    dense
                    outlined
                    v-model="currentProses.selectedProdStatus"
                    :items="items_prod_status"
                    item-text="value"
                    :rules="currentProses.statusRules"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="doSave"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <POAnggotaSelectComponent
      @pilihPO="pilihPO"
      ref="modalCustomer"
      v-model="dialogCustomer"
    />
    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
    />
    <WarehouseSelectComponent
      @pilihWarehouse="pilihWarehouse"
      ref="modalWarehouse"
      v-model="dialogWarehouse"
      :id="currentData.kelompok_id"
    />
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import ProductionOrderService from "../services/ProductionOrderService";
import MasterService from "../services/MasterService";
import moment from "moment";
// import { format, parseISO } from "date-fns";

import MaterialSelectComponent from "../components/MaterialSelect.vue";
import WarehouseSelectComponent from "../components/WarehouseAllSelect.vue";
import POAnggotaSelectComponent from "../components/POAnggotaSelect.vue";

export default {
  props: {
    id: String,
  },
  name: "ProductionOrderWF",
  components: {
    MaterialSelectComponent,
    WarehouseSelectComponent,
    POAnggotaSelectComponent,
  },
  data: () => ({
    dialog: false,
    dialogCustomer: false,
    dialogMaterial: false,
    dialogWarehouse: false,
    breadcrumbs: [
      {
        text: "Data PRO WF",
        disabled: false,
        exact: true,
        to: { name: "ProductionOrderAdd" },
      },
      { text: "Pro WF", disabled: true },
    ],
    valid: true,
    validproses: true,
    currentData: {
      order_no: "",
      no_kegiatan: "",
      menuTanggal: false,
      tanggal: "",
      user_id: "",
      user_nama: "",
      total: "",
      warehouse_id: "",
      warehouse_nama: "",
      selectedTransactionType: "",
      selectedDocType: "",

      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    materials: [
      {
        komoditi_id: "",
        komoditi_nama: "",
        qty: "",
        selling_price: "",
        total: "",
        remarks: "",
        uom: "",
      },
    ],
    materials_raw: [],
    selectedMaterialIndex: 0,
    items_metode_pembayaran: [],
    items_transaction_type: [],
    items_prod_status: [],
    items_doc_type: [{ code: 1, value: "SO" }],
    fotoKey: 0,
    currentProses: {
      wf_prod_id: "",
      qty_ok: "",
      qty_ng: "",
      selectedProdStatus: "",
      qtyOkRules: [(v) => !!v || "Qty Ok harus diisi"],
      qtyNgRules: [(v) => !!v || "Qty Ng harus diisi"],
      statusRules: [(v) => !!v || "Status harus diisi"],
    },
  }),
  mounted: async function () {
    this.getGoodMovement();
    this.getMetodePembayaran();
    this.getProdStatus();
    this.getProductionOrder();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
    computedTanggal() {
      return this.currentData.tanggal
        ? moment(this.currentData.tanggal).format("DD MMMM YYYY")
        : "";
    },
    computedCRD() {
      return this.currentData.customer_request_date
        ? moment(this.currentData.customer_request_date).format("DD MMMM YYYY")
        : "";
    },
    computedEDD() {
      return this.currentData.expected_delivery_date
        ? moment(this.currentData.expected_delivery_date).format("DD MMMM YYYY")
        : "";
    },
    computedHRD() {
      return this.currentData.expected_ho_received_date
        ? moment(this.currentData.expected_ho_received_date).format(
            "DD MMMM YYYY"
          )
        : "";
    },
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },

    async getProductionOrder() {
      ProductionOrderService.getProductionOrder(this.id)
        .then((res) => {
          this.currentData.pro_no = res.data.productionorder.pro_no;
          if (res.data.productionorder.tanggal) {
            this.currentData.tanggal = moment(
              res.data.productionorder.tanggal
            ).format("YYYY-MM-DD");
          }

          this.getDataFromApi(this.id);
          this.$nextTick(() => {
            // this.items_prod_type.forEach((entry) => {
            //   if (entry.code == res.data.productionorder.prod_type) {
            //     this.currentData.selectedProdType = entry;
            //   }
            // });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getDataFromApi(proId) {
      this.loading = true;
      ProductionOrderService.productionOrderItemSavedList(proId)
        .then((res) => {
          this.materials = res.data.list_data;
          console.log(this.materials);
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;

          this.materials.forEach((entry) => {
            entry.is_checked = false;
            entry.qty_ok = "";
            entry.qty_ng = "";
          });

          if (this.materials.length > 0) {
            this.getDataProsesFromApi(
              this.materials[0].id,
              this.materials[0].qty_order,
              this.materials[0].wf_prod_id
            );
          }
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getDataProsesFromApi(itemId, qtyOrder, wfProdId) {
      this.loading = true;
      ProductionOrderService.productionOrderItemProsesList(itemId, wfProdId)
        .then((res) => {
          this.loading = false;
          this.isFirst = true;

          console.log("log proses");
          console.log(res.data.list_data);
          this.materials_raw = [];
          res.data.list_data.forEach((entry) => {
            this.materials_raw.push({
              seq: entry.seq,
              nama_proses: entry.nama_proses,
              proses_date: entry.proses_date,
              qty_order: qtyOrder,
              qty_ok: entry.qty_ok,
              qty_ng: entry.qty_ng,
              status: entry.status,
              status_value: entry.status_value,
              production_order_item_id: itemId,
              id: entry.id,
              prod_wf_id: entry.prod_wf_id,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getGoodMovement() {
      MasterService.goodMovementList()
        .then((res) => {
          this.items_transaction_type = res.data.list_data;

          this.items_transaction_type.forEach((entry) => {
            if (entry.MV_ID == "GR01") {
              this.currentData.selectedTransactionType = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getProdStatus() {
      MasterService.prodStatusList()
        .then((res) => {
          console.log("prod status");
          this.items_prod_status = res.data.list_data;
          console.log(this.items_prod_status);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMetodePembayaran() {
      MasterService.metodePembayaranList()
        .then((res) => {
          this.items_metode_pembayaran = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      ProductionOrderService.create(this.currentData, this.materials)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Sales Order berhasil");

          this.$router
            .push("/salesorder-edit/" + res.data.sales_order.id)
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Tambah Sales Order gagal. Coba lagi");
        });
    },

    openCustomerModal: async function () {
      this.$refs.modalCustomer.getDataFromApi();
      this.dialogCustomer = true;
    },

    openMaterialModal: async function (index) {
      this.selectedMaterialIndex = index;
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    pilihAnggota: async function (item) {
      console.log(item);
      this.currentData.user_id = item.id;
      this.currentData.user_nama = item.nama;
    },

    pilihMaterial: async function (item) {
      console.log(item);
      this.materials[this.selectedMaterialIndex].komoditi_id = item.id;
      this.materials[this.selectedMaterialIndex].komoditi_nama = item.nama;
      this.materials[this.selectedMaterialIndex].uom = item.uom;
      this.materials[this.selectedMaterialIndex].price = item.production_price;
    },

    addMaterial: async function () {
      this.materials.push({
        komoditi_id: "",
        komoditi_nama: "",
        qty: "",
        selling_price: "",
        remarks: "",
        uom: "",
      });
    },

    addMaterialRaw: async function () {
      this.materials_raw.push({
        seq: "",
        nama_proses: "",
        proses_date: "",
        qty_ok: "",
        qty_ng: "",
        status_value: "",
      });
    },

    deleteMaterial: async function (index) {
      this.materials.splice(index, 1);
    },

    calculateTotal: async function (index) {
      if (this.materials[index].qty && this.materials[index].price) {
        this.materials[index].total =
          this.materials[index].qty * this.materials[index].price;
      }

      var net = 0;
      for (var i = 0; i < this.materials.length; i++) {
        if (this.materials[i].qty && this.materials[i].price) {
          net += this.materials[i].qty * this.materials[i].price;
        }
      }

      console.log(net);

      this.currentData.total = net;
    },

    openWarehouseModal: async function () {
      this.$refs.modalWarehouse.getDataFromApi();
      this.dialogWarehouse = true;
    },

    pilihWarehouse: async function (item) {
      console.log(item);
      this.currentData.warehouse_id = item.id;
      this.currentData.warehouse_nama = item.nama;
    },

    pilihPO: async function (item) {
      console.log(item);
      this.currentData.no_kegiatan = item.no_kegiatan;
    },

    editItem(item) {
      console.log(item);
      this.dialog = true;
      this.currentProses.production_order_item_id =
        item.production_order_item_id;
      this.currentProses.wf_detail_id = item.id;
      this.currentProses.prod_wf_id = item.prod_wf_id;
      this.currentProses.qty_ok = item.qty_ok;
      this.currentProses.qty_ng = item.qty_ng;
      this.currentProses.qty_order = item.qty_order;

      this.$nextTick(() => {
        console.log(item.status);
        this.items_prod_status.forEach((entry) => {
          if (entry.code == item.status) {
            this.currentProses.selectedProdStatus = entry;
          }
        });
      });
    },

    doSave: async function () {
      if (!this.$refs.formproses.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      if (this.currentProses.prod_wf_id == null) {
        ProductionOrderService.createWf(this.currentProses)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah proses berhasil");

            this.getDataProsesFromApi(
              this.materials[0].id,
              this.materials[0].qty_order,
              this.materials[0].wf_prod_id
            );
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah proses gagal. Coba lagi");
          });
      } else {
        ProductionOrderService.updateWf(
          this.currentProses.prod_wf_id,
          this.currentProses
        )
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update proses berhasil");

            this.getDataProsesFromApi(
              this.materials[0].id,
              this.materials[0].qty_order,
              this.materials[0].wf_prod_id
            );
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update proses gagal. Coba lagi");
          });
      }
    },
  },
};
</script>

<style scoped>
.small-font {
  font-size: 12px;
}
</style>
