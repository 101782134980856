<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-card-title>
          Permission Role
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari Nama"
            single-line
            hide-details
            v-on:keyup.enter="getDataFromApi"
          ></v-text-field>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Apa anda akan menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Tidak</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Ya</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list_data"
          :options.sync="options"
          :server-items-length="totalData"
          :loading="loading"
          class="elevation-1"
          item-key="name"
          :single-select="true"
          show-select
          v-model="selected"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-3" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
      <v-card class="mt-4">
        <PermissionUnroleComponent
          ref="mychild"
          :id="id"
          :refresh="getDataFromApi"
        />
      </v-card>
    </v-container>
    <v-container
      fluid
      v-if="selected != undefined && selected.length > 0"
      :key="childKey"
    >
      <v-card class="mt-4">
        <PermissionRoleValueComponent
          ref="mychild"
          :roleId="id"
          :permissionId="selected[0].permission_id"
          :valueType="selected[0].permission_value_type"
          :refresh="getDataFromApi"
        />
      </v-card>
    </v-container>
  </div>
</template>

<script>
import PermissionRoleService from "../services/PermissionRoleService";
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";

import PermissionUnroleComponent from "../views/PermissionUnrole.vue";
import PermissionRoleValueComponent from "../views/PermissionRoleValue.vue";

export default {
  props: {
    id: Number,
  },
  components: { PermissionUnroleComponent, PermissionRoleValueComponent },
  data: () => ({
    tab: null,
    childKey: 0,
    selected: [],
    dialogUser: false,
    breadcrumbs: [
      {
        text: "Data Anggota",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Nama", value: "name", sortable: false },
      { text: "Display Name", value: "display_name", sortable: false },
      { text: "Description", value: "description", sortable: false },
      { text: "Value Type", value: "permission_value_type", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    isFirst: false,
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      itemsPerPage: 5,
    },

    editedIndex: -1,
    editedItem: {},
    dialog: false,
    dialogDelete: false,
    currentData: {
      id: null,
      user_id: null,
      nama: "",
      selectedTipeAnggota: "",
      saham: "",
      kelompok_id: 0,
    },
    items_tipe_anggota: [],
  }),
  watch: {
    selected: {
      handler() {
        if (this.selected != undefined && this.selected[0]) {
          console.log("change");
          this.childKey++;
          console.log(this.selected);
        }
      },
      deep: true,
    },
    options: {
      handler() {
        if (this.isFirst) {
          this.getDataFromApi();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getTipeAnggota();
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      PermissionRoleService.permissionList(
        page,
        itemsPerPage,
        this.search,
        this.id
      )
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    async getTipeAnggota() {
      MasterService.tipeAnggotaList()
        .then((res) => {
          this.items_tipe_anggota = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doAdd: async function () {
      this.dialog = true;
      this.currentData.user_id = "";
      this.currentData.nama = "";
      this.currentData.selectedTipeAnggota = null;
      this.currentData.saham = "";
      this.currentData.id = null;
    },

    doSave: async function () {
      this.currentData.role_id = this.id;
      if (this.currentData.id == null) {
        PermissionRoleService.create(this.currentData)
          .then((res) => {
            console.log(res);
            this.dialog = false;

            CommonService.showSuccessToast("Tambah permission berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Tambah permission gagal. Coba lagi");
          });
      } else {
        PermissionRoleService.update(this.currentData.id, this.currentData)
          .then((res) => {
            this.dialog = false;

            console.log(res);
            CommonService.showSuccessToast("Update permission berhasil");

            this.getDataFromApi();
          })
          .catch((err) => {
            console.log(err);
            CommonService.showErrorToast("Update permission gagal. Coba lagi");
          });
      }
    },

    editItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.dialog = true;
      this.currentData.user_id = this.editedItem.user_id;
      this.currentData.nama = this.editedItem.user_nama;
      this.currentData.saham = this.editedItem.saham;

      if (this.editedItem.tipe_anggota) {
        this.items_tipe_anggota.forEach((entry) => {
          if (entry.code == this.editedItem.tipe_anggota) {
            this.currentData.selectedTipeAnggota = entry;
          }
        });
      }

      this.currentData.id = this.editedItem.id;
      console.log("do add");
    },

    openDetail(item) {
      this.$router.push("/anggota-edit/" + item.user_id).catch((err) => {
        console.log(err);
      });
    },

    deleteItem(item) {
      console.log(item);
      this.editedIndex = this.list_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();

      PermissionRoleService.delete(this.id, this.editedItem.permission_id)
        .then((res) => {
          console.log(res);

          CommonService.showSuccessToast("Hapus permission berhasil");

          this.getDataFromApi();
          this.$refs.mychild.getDataFromApi();
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast("Hapus permission gagal. Coba lagi");
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    pilihUser: async function (item) {
      this.currentData.user_id = item.id;
      this.currentData.nama = item.nama;
    },

    openUserModal: async function () {
      this.$refs.modalUser.getDataFromApi();
      this.dialogUser = true;
    },
  },
};
</script>
