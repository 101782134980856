<template>
  <v-dialog v-model="show" max-width="800px">
    <v-card>
      <v-card-title>
        Sales Order Phala
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari SO NO"
          single-line
          hide-details
          v-on:keyup.enter="getDataFromApi"
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="list_data"
        :options.sync="options"
        :server-items-length="totalData"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:[`item.tanggal`]="{ item }">
          {{ getCurrentDate(item.tanggal) }}
        </template>
        <template v-slot:[`item.customer_request_date`]="{ item }">
          {{ getCurrentDate(item.customer_request_date) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="ma-2" color="primary" dark @click.stop="pilih(item)">
            Pilih
            <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SalesOrderService from "../services/SalesOrderService";
import * as moment from "moment";

export default {
  name: "SalesOrderSelect",
  props: {
    value: Boolean,
  },
  data: () => ({
    dialogSO: false,
    search: "",
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Order No", value: "order_no", sortable: false },
      { text: "Tanggal", value: "tanggal", sortable: false },
      { text: "Customer", value: "customer_nama", sortable: false },
      {
        text: "Customer Request Date",
        value: "customer_request_date",
        sortable: false,
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    totalData: 0,
    list_data: [],
    loading: true,
    options: {
      page: 1,
      itemsPerPage: 5,
    },
  }),
  mounted() {},
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },

    getDataFromApi() {
      const { page, itemsPerPage } = this.options;

      this.loading = true;
      SalesOrderService.salesOrderList(page, itemsPerPage, this.search)
        .then((res) => {
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    pilih: function (item) {
      this.$emit("pilihSO", item);

      this.show = false;
    },
  },
};
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
