<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title>Distribusi Order </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-text-field
                label="Order Penjualan"
                outlined
                dense
                required
                v-model="currentData.sales_order_no"
                readonly
                @click="openSOModal"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-menu
                v-model="currentData.menuTanggal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
                disabled
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggal"
                    label="Tanggal Order Penjualan"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.tanggal"
                  @input="currentData.menuTanggal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-text-field
                label="Currency"
                outlined
                dense
                required
                v-model="currentData.currency"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- <SalesOrderItemTableComponent
            ref="salesOrderItem"
            :id="currentData.sales_order_id"
          /> -->

          <v-row>
            <v-col>
              <h3 class="mt-6">Detail Order Pembelian Kelompok</h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col col="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Kelompok</th>
                      <th class="text-left">Material</th>
                      <th class="text-left">Item. No</th>
                      <th class="text-left">Kuantiti</th>
                      <th class="text-left">Harga Pembelian ke Kelompok</th>
                      <th class="text-left">Total</th>
                      <th class="text-left">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in materials" :key="i">
                      <td style="padding: 6px">
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.kelompok_nama"
                          readonly
                          @click="openKelompokModal(i)"
                        ></v-text-field>
                      </td>
                      <td style="padding: 6px">
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.komoditi_nama"
                          readonly
                          @click="openMaterialModal(i)"
                        ></v-text-field>
                      </td>
                      <td style="padding: 6px">
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.item_number"
                          readonly
                        ></v-text-field>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.qty"
                          :options="options"
                        />
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.production_price"
                          disabled
                          :options="options"
                        />
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.total"
                          disabled
                          :options="options"
                        />
                      </td>
                      <td>
                        <v-icon medium @click="deleteMaterial(i)">
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <v-icon medium @click="addMaterial()">
                          mdi-plus
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="6"> </v-col>
            <v-col style="vertical-align: center">
              <h4 class="v-heading text-h7 mb-2">Total</h4>
            </v-col>
            <v-col>
              <vuetify-money
                outlined
                dense
                required
                v-model="currentData.total"
                readonly
                :options="options"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
    <CustomerSelectComponent
      @pilihCustomer="pilihCustomer"
      ref="modalCustomer"
      v-model="dialogCustomer"
    />
    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
      :id="currentData.sales_order_id"
    />
    <KelompokSelectComponent
      @pilihKelompok="pilihKelompok"
      ref="modalKelompok"
      v-model="dialogKelompok"
    />
    <SalesOrderSelectComponent
      @pilihSO="pilihSO"
      ref="modalSO"
      v-model="dialogSO"
    />
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import PurchaseOrderService from "../services/PurchaseOrderService";
import MasterService from "../services/MasterService";
import moment from "moment";
// import { format, parseISO } from "date-fns";

import CustomerSelectComponent from "../components/CustomerSelect.vue";
import MaterialSelectComponent from "../components/MaterialBySOSelect.vue";
import KelompokSelectComponent from "../components/KelompokSelect.vue";
import SalesOrderSelectComponent from "../components/SalesOrderSelect.vue";
// import SalesOrderItemTableComponent from "../components/tables/SalesOrderItemTable.vue";

export default {
  name: "PurchaseOrderAdd",
  components: {
    CustomerSelectComponent,
    MaterialSelectComponent,
    KelompokSelectComponent,
    SalesOrderSelectComponent,
    // SalesOrderItemTableComponent,
  },
  data: () => ({
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    dialogCustomer: false,
    dialogMaterial: false,
    dialogSO: false,
    dialogKelompok: false,
    breadcrumbs: [
      {
        text: "Data Distribusi Order",
        disabled: false,
        exact: true,
        to: { name: "PurchaseOrder" },
      },
      { text: "Distribusi Order", disabled: true },
    ],
    valid: true,
    currentData: {
      sales_order_id: 0,
      sales_order_no: "",
      order_no: "",
      po_no_cust: "",
      menuTanggal: false,
      tanggal: "",
      total: "",
      currency: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
    },
    materials: [
      {
        kelompok_id: "",
        kelompok_nama: "",
        komoditi_id: "",
        komoditi_nama: "",
        qty: "",
        production_price: "",
        total: "",
      },
    ],
    selectedMaterialIndex: 0,
    items_metode_pembayaran: [],
    fotoKey: 0,
  }),
  watch: {
    materials: {
      handler() {
        this.calculateTotalAmount();
      },
      deep: true,
    },
  },
  mounted: async function () {
    this.getMetodePembayaran();
    this.currentData.currency = "IDR";
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
    computedTanggal() {
      return this.currentData.tanggal
        ? moment(this.currentData.tanggal).format("DD MMMM YYYY")
        : "";
    },
    computedCRD() {
      return this.currentData.customer_request_date
        ? moment(this.currentData.customer_request_date).format("DD MMMM YYYY")
        : "";
    },
    computedEDD() {
      return this.currentData.expected_delivery_date
        ? moment(this.currentData.expected_delivery_date).format("DD MMMM YYYY")
        : "";
    },
    computedHRD() {
      return this.currentData.expected_ho_received_date
        ? moment(this.currentData.expected_ho_received_date).format(
            "DD MMMM YYYY"
          )
        : "";
    },
  },
  methods: {
    async getMetodePembayaran() {
      MasterService.metodePembayaranList()
        .then((res) => {
          this.items_metode_pembayaran = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      PurchaseOrderService.create(this.currentData, this.materials)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Tambah Purchase Order berhasil");

          this.$router
            .push(
              "/purchaseorder-edit/" +
                res.data.purchase_order.sales_order_id +
                "/" +
                res.data.purchase_order.id
            )
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);

          if (err.response.data.message) {
            CommonService.showErrorToast(err.response.data.message);
          } else {
            CommonService.showErrorToast(
              "Tambah Purchase Order gagal. Coba lagi"
            );
          }
        });
    },

    openCustomerModal: async function () {
      this.$refs.modalCustomer.getDataFromApi();
      this.dialogCustomer = true;
    },

    openKelompokModal: async function (index) {
      this.selectedMaterialIndex = index;
      this.$refs.modalKelompok.getDataFromApi();
      this.dialogKelompok = true;
    },

    openMaterialModal: async function (index) {
      this.selectedMaterialIndex = index;
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    openSOModal: async function () {
      this.dialogSO = true;

      this.$nextTick(() => {
        this.$refs.modalSO.getDataFromApi();
      });
    },

    pilihCustomer: async function (item) {
      console.log(item);
      this.currentData.customer_id = item.id;
      this.currentData.customer_nama = item.name;
    },

    pilihMaterial: async function (item) {
      console.log(item);
      this.materials[this.selectedMaterialIndex].komoditi_id = item.id;
      this.materials[this.selectedMaterialIndex].komoditi_nama = item.nama;
      this.materials[this.selectedMaterialIndex].so_item_id = item.so_item_id;
      this.materials[this.selectedMaterialIndex].item_number = item.item_number;
      this.materials[this.selectedMaterialIndex].production_price =
        item.production_price;
    },

    pilihKelompok: async function (item) {
      this.materials[this.selectedMaterialIndex].kelompok_id = item.id;
      this.materials[this.selectedMaterialIndex].kelompok_nama = item.nama;
    },

    pilihSO: async function (item) {
      this.currentData.sales_order_id = item.id;
      this.currentData.sales_order_no = item.order_no;
      this.currentData.tanggal = moment(item.tanggal).format("DD MMMM YYYY");

      // this.$nextTick(() => {
      //   this.$refs.salesOrderItem.getDataFromApi();
      // });
    },

    addMaterial: async function () {
      this.materials.push({
        kelompok_id: "",
        kelompok_nama: "",
        komoditi_id: "",
        komoditi_nama: "",
        qty: "",
        production_price: "",
        total: "",
      });
    },

    deleteMaterial: async function (index) {
      this.materials.splice(index, 1);
    },

    calculateTotal: async function (index) {
      if (this.materials[index].qty && this.materials[index].production_price) {
        this.materials[index].total =
          this.materials[index].qty * this.materials[index].production_price;

        //update qty terpenuhi
        // this.$refs.salesOrderItem.updateQtyTerpenuhi(this.materials, index);
      }

      var net = 0;
      for (var i = 0; i < this.materials.length; i++) {
        if (this.materials[i].qty && this.materials[i].production_price) {
          net += this.materials[i].qty * this.materials[i].production_price;
        }
      }

      this.currentData.total = net;
    },

    calculateTotalAmount: async function () {
      var net = 0;
      for (var i = 0; i < this.materials.length; i++) {
        if (this.materials[i].qty && this.materials[i].production_price) {
          this.materials[i].total =
            this.materials[i].qty * this.materials[i].production_price;

          net += this.materials[i].qty * this.materials[i].production_price;
        }
      }

      this.currentData.total = net;
    },
  },
};
</script>

<style scoped>
.small-font {
  font-size: 12px;
}
</style>
