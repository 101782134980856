<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Vendor </v-card-title>
        <v-form ref="form" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="12" md="12">
              <v-row dense>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Nama"
                    outlined
                    dense
                    v-model="currentData.nama"
                    :rules="currentData.namaRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Email"
                    outlined
                    dense
                    v-model="currentData.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Deskripsi"
                    dense
                    value=""
                    rows="3"
                    v-model="currentData.deskripsi"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Negara"
                    outlined
                    dense
                    v-model="currentData.negara_nama"
                    readonly
                    @click="openNegaraModal"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Propinsi"
                    outlined
                    dense
                    v-model="currentData.propinsi_nama"
                    readonly
                    @click="openPropinsiModal"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Kabupaten/Kota"
                    outlined
                    dense
                    v-model="currentData.kabupaten_nama"
                    readonly
                    @click="openKabupatenModal"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Kecamatan"
                    outlined
                    dense
                    v-model="currentData.kecamatan"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Alamat"
                    dense
                    value=""
                    rows="3"
                    v-model="currentData.address"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Alamat 2"
                    dense
                    value=""
                    rows="3"
                    v-model="currentData.address_dua"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Kode Pos"
                    outlined
                    dense
                    v-model="currentData.postal_code"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Nama Bank"
                    outlined
                    dense
                    v-model="currentData.nama_bank"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Nama Rekening Bank"
                    outlined
                    dense
                    v-model="currentData.nama_rekening_bank"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="No Rekening"
                    outlined
                    dense
                    v-model="currentData.nomor_rekening"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="No Telp 1"
                    outlined
                    dense
                    v-model="currentData.no_telepon_satu"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Contact Person 1"
                    outlined
                    dense
                    v-model="currentData.kontak_person_satu"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4"></v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="No Telp 2"
                    outlined
                    dense
                    v-model="currentData.no_telepon_dua"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Contact Person 2"
                    outlined
                    dense
                    v-model="currentData.kontak_person_dua"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4"></v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="No Telp 3"
                    outlined
                    dense
                    v-model="currentData.no_telepon_tiga"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Contact Person 3"
                    outlined
                    dense
                    v-model="currentData.kontak_person_tiga"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4"></v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    label="Tax ID"
                    outlined
                    dense
                    v-model="currentData.vat_number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>

    <PropinsiSelectComponent
      @pilihPropinsi="pilihPropinsi"
      ref="modalPropinsi"
      v-model="dialogPropinsi"
    />

    <KabupatenSelectComponent
      @pilihKabupaten="pilihKabupaten"
      ref="modalKabupaten"
      v-model="dialogKabupaten"
      :propinsi_id="currentData.propinsi_id"
    />

    <NegaraSelectComponent
      @pilihNegara="pilihNegara"
      ref="modalNegara"
      v-model="dialogNegara"
    />
  </v-main>
</template>

<script>
import VendorService from "../services/VendorService";
import CommonService from "../services/CommonService";

import PropinsiSelectComponent from "../components/PropinsiSelect.vue";
import KabupatenSelectComponent from "../components/KabupatenSelect.vue";
import NegaraSelectComponent from "../components/NegaraSelect.vue";

export default {
  name: "CustomerEdit",
  components: {
    PropinsiSelectComponent,
    KabupatenSelectComponent,
    NegaraSelectComponent,
  },
  props: {
    id: String,
  },
  data: () => ({
    options: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    dialogPropinsi: false,
    dialogKabupaten: false,
    dialogNegara: false,
    tab: null,
    isEditable: 0,
    breadcrumbs: [
      {
        text: "Data Vendor",
        disabled: false,
        exact: true,
        to: { name: "Vendor" },
      },
      { text: "Edit Vendor", disabled: true },
    ],
    currentData: {
      id: null,
      nama: "",
      deskripsi: "",
      email: "",
      no_telepon_satu: "",
      kontak_person_satu: "",
      no_telepon_dua: "",
      kontak_person_dua: "",
      no_telepon_tiga: "",
      kontak_person_tiga: "",
      vat_number: "",
      nomor_rekening: "",
      nama_bank: "",
      nama_rekening_bank: "",
      address: "",
      address_dua: "",
      propinsi_id: -1,
      propinsi_nama: "",
      kabupaten_id: "",
      kabupaten_nama: "",
      kecamatan: "",
      postal_code: "",
      negara: "",
      negara_nama: "",
      namaRules: [(v) => !!v || "Nama harus diisi"],
      emailRules: [(v) => !!v || "Email harus diisi"],
      passwordRules: [(v) => !!v || "Password harus diisi"],
      statusRules: [(v) => !!v || "Status harus diisi"],
    },
    rules: {
      required: (value) => !!value || "Field harus diisi",
      counter: (value) => {
        if (value.length > 0) {
          if (value.length < 10) {
            return "Panjang minimal 10 karakter";
          }

          const pattern =
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/;
          return (
            pattern.test(value) ||
            "Password harus terdiri angka, huruf besar, huruf kecil, special character"
          );
        } else {
          return true;
        }
      },
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    result: {},
  }),
  mounted: async function () {
    this.getVendor();
  },
  computed: {},
  methods: {
    changeEditable: function () {
      this.isEditable = 1;
    },

    getVendor() {
      VendorService.getVendor(this.id)
        .then((res) => {
          this.currentData.nama = res.data.vendor.nama;
          this.currentData.email = res.data.vendor.email;
          this.currentData.deskripsi = res.data.vendor.deskripsi;
          this.currentData.email = res.data.vendor.email;
          this.currentData.vat_number = res.data.vendor.vat_number;
          this.currentData.address = res.data.vendor.address;
          this.currentData.address_dua = res.data.vendor.address_dua;
          this.currentData.postal_code = res.data.vendor.postal_code;
          this.currentData.propinsi_id = res.data.vendor.propinsi_id;
          this.currentData.propinsi_nama = res.data.vendor.propinsi_nama;
          this.currentData.kabupaten_id = res.data.vendor.kabupaten_id;
          this.currentData.kabupaten_nama = res.data.vendor.kabupaten_nama;
          this.currentData.negara = res.data.vendor.negara;
          this.currentData.negara_nama = res.data.vendor.negara_nama;
          this.currentData.kecamatan = res.data.vendor.kecamatan;
          this.currentData.nama_bank = res.data.vendor.nama_bank;
          this.currentData.nama_rekening_bank =
            res.data.vendor.nama_rekening_bank;
          this.currentData.nomor_rekening = res.data.vendor.nomor_rekening;
          this.currentData.no_telepon_satu = res.data.vendor.no_telepon_satu;
          this.currentData.kontak_person_satu =
            res.data.vendor.kontak_person_satu;
          this.currentData.no_telepon_dua = res.data.vendor.no_telepon_dua;
          this.currentData.kontak_person_dua =
            res.data.vendor.kontak_person_dua;
          this.currentData.no_telepon_tiga = res.data.vendor.no_telepon_tiga;
          this.currentData.kontak_person_tiga =
            res.data.vendor.kontak_person_tiga;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      VendorService.update(this.id, this.currentData)
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Update Vendor berhasil");
        })
        .catch((err) => {
          console.log(err);

          CommonService.showErrorToast("Update Vendor gagal. Coba lagi");
        });
    },

    openPropinsiModal: async function () {
      this.$refs.modalPropinsi.getDataFromApi();
      this.dialogPropinsi = true;
    },

    pilihPropinsi: async function (item) {
      console.log(item);
      this.currentData.propinsi_id = item.id;
      this.currentData.propinsi_nama = item.nama;
    },

    openKabupatenModal: async function () {
      this.$refs.modalKabupaten.getDataFromApi();
      this.dialogKabupaten = true;
    },

    pilihKabupaten: async function (item) {
      this.currentData.kabupaten_id = item.id;
      this.currentData.kabupaten_nama = item.nama;
    },

    openNegaraModal: async function () {
      this.$refs.modalNegara.getDataFromApi();
      this.dialogNegara = true;
    },

    pilihNegara: async function (item) {
      this.currentData.negara = item.id;
      this.currentData.negara_nama = item.nama;
    },
  },
};
</script>

<style scoped>
</style>
