var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-title',[_vm._v(" Good Issue ")]),_c('v-form',{ref:"form",staticClass:"pl-4 pr-4",attrs:{"lazy-validation":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pr-4",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-combobox',{attrs:{"label":"Transaction Type","dense":"","outlined":"","readonly":"","items":_vm.items_transaction_type,"item-text":"MV_DESC"},model:{value:(_vm.currentData.selectedTransactionType),callback:function ($$v) {_vm.$set(_vm.currentData, "selectedTransactionType", $$v)},expression:"currentData.selectedTransactionType"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"GI No","outlined":"","dense":"","required":"","readonly":"","value":"GENERATED"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pr-4",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Doc No","outlined":"","dense":"","required":"","readonly":"","value":_vm.currentData.so_no_kelompok}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedTanggal,"label":"Tanggal Transaksi","prepend-icon":"mdi-calendar","readonly":"","dense":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.currentData.menuTanggal),callback:function ($$v) {_vm.$set(_vm.currentData, "menuTanggal", $$v)},expression:"currentData.menuTanggal"}},[_c('v-date-picker',{on:{"input":function($event){_vm.currentData.menuTanggal = false}},model:{value:(_vm.currentData.tanggal),callback:function ($$v) {_vm.$set(_vm.currentData, "tanggal", $$v)},expression:"currentData.tanggal"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"label":"Warehouse","outlined":"","dense":"","required":"","rules":_vm.currentData.warehouseRules},on:{"click":_vm.openWarehouseModal},model:{value:(_vm.currentData.warehouse_nama),callback:function ($$v) {_vm.$set(_vm.currentData, "warehouse_nama", $$v)},expression:"currentData.warehouse_nama"}})],1)],1)],1)],1),_c('v-card',{staticClass:"mt-6"},[_c('v-card-title',[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Apa anda akan mengkonfirmasi data ini?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeConfirm}},[_vm._v("Tidak")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.submitConfirm}},[_vm._v("Ya")]),_c('v-spacer')],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list_data,"options":_vm.options,"server-items-length":_vm.totalData,"loading":_vm.loading,"show-select":""},on:{"update:options":function($event){_vm.options=$event},"input":_vm.openSelectedRow},scopedSlots:_vm._u([{key:"item.foto",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"height":"auto","width":"100","src":item.foto}})]}},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.numberWithCommas(item.qty)))])]}},{key:"item.qty_outstanding",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.numberWithCommas(item.qty_outstanding)))])]}},{key:"item.production_price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.numberWithCommas(item.production_price)))])]}},{key:"item.konten",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.getKonten(item.konten)))])]}},{key:"item.po_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCurrentDate(item.po_date))+" ")]}},{key:"item.status_kelompok",fn:function(ref){
var item = ref.item;
return [(item.status_kelompok == 0)?_c('p',[_vm._v("Tidak Aktif")]):_c('p',[_vm._v("Aktif")])]}},{key:"item.qty_stock",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{key:item.id,attrs:{"outlined":"","dense":"","required":"","disabled":!item.is_checked,"background-color":item.is_checked ? 'white' : '#ddd'},model:{value:(item.qty_gi),callback:function ($$v) {_vm.$set(item, "qty_gi", $$v)},expression:"item.qty_gi"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{staticStyle:{"margin-left":"20px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openSOKelompok(item)}}},[_c('v-list-item-title',[_vm._v("View")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openDelivery(item)}}},[_c('v-list-item-title',[_vm._v("Delivery")])],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-row',{staticClass:"mt-2 mr-2"},[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"6"}}),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","type":"button","color":"grey"},on:{"click":_vm.cancelGoodIssue}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"dark":"","color":"primary","type":"button"},on:{"click":_vm.doSubmit}},[_vm._v("Save")])],1)],1)],1)],1),_c('WarehouseSelectComponent',{ref:"modalWarehouse",attrs:{"id":_vm.currentData.kelompok_id},on:{"pilihWarehouse":_vm.pilihWarehouse},model:{value:(_vm.dialogWarehouse),callback:function ($$v) {_vm.dialogWarehouse=$$v},expression:"dialogWarehouse"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }