<template>
  <v-main>
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-container fluid>
      <v-card>
        <v-card-title> Production Order Detail </v-card-title>
        <v-form ref="form" v-model="valid" lazy-validation class="pl-4 pr-4">
          <v-row dense>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-combobox
                label="Production Type"
                dense
                outlined
                v-model="currentData.selectedProdType"
                :items="items_prod_type"
                item-text="value"
                readonly
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="PRO No"
                outlined
                dense
                readonly
                :value="currentData.pro_no"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-textarea
                outlined
                name="input-7-4"
                label="Deskripsi"
                dense
                value=""
                rows="3"
                v-model="currentData.deskripsi"
              ></v-textarea>
            </v-col>
            <v-col v-if="isSOOrder" cols="12" sm="4" md="4" class="">
              <v-text-field
                label="SO No"
                outlined
                dense
                required
                v-model="currentData.so_no_kelompok"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-menu
                v-model="currentData.menuTanggal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggal"
                    label="Tanggal Produksi"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.tanggal"
                  @input="currentData.menuTanggal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-menu
                v-model="currentData.menuTanggalHarus"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggalHarus"
                    label="Harus Selesai Sebelum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.tanggalHarusRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.harus_selesai_sebelum"
                  @input="currentData.menuTanggalHarus = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pr-4">
              <v-menu
                v-model="currentData.menuTanggalPerkiraan"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedTanggalPerkiraan"
                    label="Perkiraan Akhir Produksi"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="currentData.tanggalPerkiraanRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentData.perkiraan_akhir_produksi"
                  @input="currentData.menuTanggalPerkiraan = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Created By"
                outlined
                dense
                required
                readonly
                v-model="currentData.createdBy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="">
              <v-text-field
                label="Created At"
                outlined
                dense
                required
                readonly
                v-model="currentData.createdAt"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Status"
                outlined
                dense
                required
                readonly
                :value="currentData.status_value"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex">
              <h3 class="mr-auto">Target Finish Goods</h3>
              <v-btn
                dark
                color="primary"
                type="button"
                class="mr-4"
                @click="openDetailWF"
                >Detail WF</v-btn
              >
              <v-btn
                dark
                color="primary"
                type="button"
                class="mr-4"
                @click="openGoodIssue"
                >Good Issue to Production</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="list_data"
                :options.sync="options"
                :server-items-length="totalData"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:[`item.qty_order`]="{ item }">
                  <v-text-field
                    outlined
                    dense
                    required
                    :key="item.id"
                    v-model="item.qty_order"
                    readonly
                  ></v-text-field>
                </template>
                <template v-slot:[`item.wf_prod_id`]="{ item }">
                  <v-text-field
                    outlined
                    dense
                    required
                    :key="item.id"
                    readonly
                    v-model="item.wf_prod_nama"
                  ></v-text-field>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu style="margin-left: 20px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon medium v-bind="attrs" v-on="on">
                        mdi-dots-vertical
                      </v-icon>
                    </template>
                    <v-list>
                      <v-list-item @click="openSOKelompok(item)">
                        <v-list-item-title>View</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openDelivery(item)">
                        <v-list-item-title>Delivery</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h3>List Proses</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="10%" class="text-left">Sequence</th>
                      <th width="20%" class="text-left">Steps WF</th>
                      <th width="20%" class="text-left">Process Date</th>
                      <th width="10%" class="text-left">Qty OK</th>
                      <th width="10%" class="text-left">Qty NG</th>
                      <th width="10%" class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in materials_proses" :key="i">
                      <td style="padding: 6px">{{ item.seq }}</td>
                      <td>{{ item.nama_proses }}</td>
                      <td>{{ getCurrentDate(item.proses_date) }}</td>
                      <td>{{ item.qty_ok }}</td>
                      <td>{{ item.qty_ng }}</td>
                      <td>{{ item.status_value }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h3>List Raw Item</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="30%" class="text-left">Material</th>
                      <th width="15%" class="text-left">Qty Process</th>
                      <th width="10%" class="text-left">Consumption</th>
                      <th width="15%" class="text-left">Qty Consumption</th>
                      <th width="10%" class="text-left">UOM</th>
                      <th width="20%" class="text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in materials_raw" :key="i">
                      <td style="padding: 6px">
                        <v-textarea
                          outlined
                          v-model="item.komoditi_nama"
                          rows="2"
                          readonly
                          row-height="3"
                        ></v-textarea>
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          v-model="item.qty_raw"
                          :options="options_money"
                        />
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          readonly
                          v-model="item.consumption"
                          :options="options_money_pre"
                        />
                      </td>
                      <td>
                        <vuetify-money
                          outlined
                          dense
                          required
                          readonly
                          v-model="item.qty_consumption"
                          :options="options_money_pre"
                        />
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          v-model="item.uom"
                          readonly
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          required
                          readonly
                          v-model="item.status"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="doBatal" style="margin-right: 10px">Batal</v-btn>
              <v-btn dark color="primary" type="button" @click="doSubmit"
                >Simpan</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
    <POKelompokSelectComponent
      @pilihPO="pilihPO"
      ref="modalPO"
      v-model="dialogPO"
    />
    <MaterialSelectComponent
      @pilihMaterial="pilihMaterial"
      ref="modalMaterial"
      v-model="dialogMaterial"
    />
    <WFProductionSelectComponent
      @pilihWF="pilihWF"
      ref="modalWF"
      v-model="dialogWF"
      :id="currentData.komoditi_id"
    />
  </v-main>
</template>

<script>
import CommonService from "../services/CommonService";
import MasterService from "../services/MasterService";
import moment from "moment";
// import { format, parseISO } from "date-fns";

import MaterialSelectComponent from "../components/MaterialSelect.vue";
import WFProductionSelectComponent from "../components/WFProductionSelect.vue";
import POKelompokSelectComponent from "../components/POKelompokSelect.vue";
import ProductionOrderService from "../services/ProductionOrderService";

export default {
  props: {
    id: String,
  },
  name: "GoodReceiptAdd",
  components: {
    MaterialSelectComponent,
    WFProductionSelectComponent,
    POKelompokSelectComponent,
  },
  data: () => ({
    options_money: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 0,
    },
    options_money_pre: {
      locale: "pt-BR",
      prefix: "",
      suffix: "",
      length: 11,
      precision: 1,
    },
    dialogPO: false,
    dialogMaterial: false,
    dialogWF: false,
    breadcrumbs: [
      {
        text: "Data PRO",
        disabled: false,
        exact: true,
        to: { name: "ProductionOrder" },
      },
      { text: "Edit PRO", disabled: true },
    ],
    valid: true,
    currentData: {
      order_no: "",
      no_kegiatan: "",
      menuTanggal: false,
      tanggal: "",
      deskripsi: "",
      menuTanggalHarus: false,
      harus_selesai_sebelum: "",
      menuTanggalPerkiraan: false,
      perkiraan_akhir_produksi: "",
      user_id: "",
      user_nama: "",
      total: "",
      warehouse_id: "",
      warehouse_nama: "",
      selectedTransactionType: "",
      selectedDocType: "",
      selectedProdType: "",
      selectedProdStatus: "",
      createdBy: "",
      createdAt: "",
      status_value: "",
      kegiatanTitleRules: [(v) => !!v || "Judul harus diisi"],
      kegiatanDescRules: [(v) => !!v || "Deskripsi harus diisi"],
      tanggalHarusRules: [(v) => !!v || "Harus selesai sebelum harus diisi"],
      tanggalPerkiraanRules: [
        (v) => !!v || "Perkiraan akhir produksi harus diisi",
      ],
    },
    materials_raw: [],
    materials_proses: [],
    selectedMaterialIndex: 0,
    items_metode_pembayaran: [],
    items_transaction_type: [],
    items_prod_type: [],
    items_prod_status: [],
    items_doc_type: [{ code: 1, value: "SO" }],
    fotoKey: 0,

    //production order item
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Material", value: "komoditi_nama", sortable: false },
      { text: "Qty Order", value: "qty_order", sortable: false },
      { text: "UOM", value: "uom", sortable: false },
      { text: "WF Prod", value: "wf_prod_id", sortable: false },
      { text: "Qty Done", value: "qty_done", sortable: false },
      { text: "Status", value: "status_value", sortable: false },
    ],
    list_data: [],
    options: {
      itemsPerPage: -1,
    },
    totalData: 0,
    loading: false,
    selectedRows: [],
  }),
  watch: {
    materials_raw: {
      handler() {
        this.calculateConsumptionTotal();
      },
      deep: true,
    },
  },
  mounted: async function () {
    this.getGoodMovement();
    this.getMetodePembayaran();
    this.getProdType();
    this.getProdStatus();
    this.getProductionOrder();
  },
  computed: {
    imageSrc() {
      return this.currentData.FotoUrl;
    },
    computedTanggal() {
      return this.currentData.tanggal
        ? moment(this.currentData.tanggal).format("DD MMMM YYYY")
        : "";
    },
    computedTanggalHarus() {
      return this.currentData.harus_selesai_sebelum
        ? moment(this.currentData.harus_selesai_sebelum).format("DD MMMM YYYY")
        : "";
    },
    computedTanggalPerkiraan() {
      return this.currentData.perkiraan_akhir_produksi
        ? moment(this.currentData.perkiraan_akhir_produksi).format(
            "DD MMMM YYYY"
          )
        : "";
    },
    computedCRD() {
      return this.currentData.customer_request_date
        ? moment(this.currentData.customer_request_date).format("DD MMMM YYYY")
        : "";
    },
    computedEDD() {
      return this.currentData.expected_delivery_date
        ? moment(this.currentData.expected_delivery_date).format("DD MMMM YYYY")
        : "";
    },
    computedHRD() {
      return this.currentData.expected_ho_received_date
        ? moment(this.currentData.expected_ho_received_date).format(
            "DD MMMM YYYY"
          )
        : "";
    },
    isSOOrder() {
      if (
        this.currentData.selectedProdType &&
        this.currentData.selectedProdType.code == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getCurrentDate(tanggal) {
      return moment(tanggal).format("DD MMMM YYYY");
    },

    async getProductionOrder() {
      ProductionOrderService.getProductionOrder(this.id)
        .then((res) => {
          this.currentData.so_no_kelompok =
            res.data.productionorder.so_no_kelompok;
          this.currentData.pro_no = res.data.productionorder.pro_no;
          this.currentData.createdBy = res.data.productionorder.created_by_nama;
          this.currentData.status_value = res.data.productionorder.status_value;

          if (res.data.productionorder.created_at) {
            this.currentData.createdAt = moment(
              res.data.productionorder.created_at
            ).format("YYYY-MM-DD HH:mm:ss");
          }

          if (res.data.productionorder.tanggal) {
            this.currentData.tanggal = moment(
              res.data.productionorder.tanggal
            ).format("YYYY-MM-DD");
          }

          this.currentData.deskripsi = res.data.productionorder.deskripsi;

          if (res.data.productionorder.harus_selesai_sebelum) {
            this.currentData.harus_selesai_sebelum = moment(
              res.data.productionorder.harus_selesai_sebelum
            ).format("YYYY-MM-DD");
          }

          if (res.data.productionorder.perkiraan_akhir_produksi) {
            this.currentData.perkiraan_akhir_produksi = moment(
              res.data.productionorder.perkiraan_akhir_produksi
            ).format("YYYY-MM-DD");
          }

          this.getDataFromApi(
            res.data.productionorder.purchase_order_id,
            this.id
          );
          this.$nextTick(() => {
            this.items_prod_type.forEach((entry) => {
              if (entry.code == res.data.productionorder.prod_type) {
                this.currentData.selectedProdType = entry;
              }
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getGoodMovement() {
      MasterService.goodMovementList()
        .then((res) => {
          this.items_transaction_type = res.data.list_data;

          this.items_transaction_type.forEach((entry) => {
            if (entry.MV_ID == "GR01") {
              this.currentData.selectedTransactionType = entry;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMetodePembayaran() {
      MasterService.metodePembayaranList()
        .then((res) => {
          this.items_metode_pembayaran = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getProdType() {
      MasterService.prodTypeList()
        .then((res) => {
          this.items_prod_type = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getProdStatus() {
      MasterService.prodStatusList()
        .then((res) => {
          this.items_prod_status = res.data.list_data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    doBatal: function () {
      this.$router.go(-1);
    },

    doSubmit: async function () {
      if (!this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        return;
      }

      ProductionOrderService.update(
        this.id,
        this.currentData,
        this.list_data,
        this.materials_raw
      )
        .then((res) => {
          console.log(res);
          CommonService.showSuccessToast("Update Production Order berhasil");
        })
        .catch((err) => {
          console.log(err);
          CommonService.showErrorToast(
            "Update Production Order gagal. Coba lagi"
          );
        });
    },

    openDetailWF: async function () {
      this.$router.push(`/productionorder-wf/${this.id}`).catch((err) => {
        console.log(err);
      });
    },

    openGoodIssue: async function () {
      this.$router.push(`/goodissue-prod/${this.id}`).catch((err) => {
        console.log(err);
      });
    },

    openPOModal: async function () {
      this.$refs.modalPO.getDataFromApi();
      this.dialogPO = true;
    },

    openMaterialModal: async function (index) {
      this.selectedMaterialIndex = index;
      this.$refs.modalMaterial.getDataFromApi();
      this.dialogMaterial = true;
    },

    pilihAnggota: async function (item) {
      console.log(item);
      this.currentData.user_id = item.id;
      this.currentData.user_nama = item.nama;
    },

    pilihMaterial: async function (item) {
      console.log(item);
      this.materials_raw[this.selectedMaterialIndex].komoditi_id = item.id;
      this.materials_raw[this.selectedMaterialIndex].komoditi_nama = item.nama;
      this.materials_raw[this.selectedMaterialIndex].uom = item.uom;
      this.materials_raw[this.selectedMaterialIndex].price =
        item.production_price;
    },

    addMaterialRaw: async function () {
      this.materials_raw.push({
        komoditi_id: "",
        komoditi_nama: "",
        qty: "",
        selling_price: "",
        remarks: "",
        uom: "",
      });
    },

    deleteMaterial: async function (index) {
      this.materials_raw.splice(index, 1);
    },

    openWFModal: async function (item) {
      console.log(item);
      this.dialogWF = true;
      this.$nextTick(() => {
        this.$refs.modalWF.getDataFromApi(item.komoditi_id);
      });
    },

    pilihWF: async function (item) {
      console.log(item);
      console.log(item.nama_wf);

      this.list_data.forEach((entry) => {
        console.log(entry.id + " - " + item.id);
        if (entry.id == this.selectedRows[0].id) {
          console.log("masuk ke item sama");
          entry.wf_prod_id = item.id;
          entry.wf_prod_nama = item.nama_wf;
        } else {
          console.log("tidak sama");
        }
      });
    },

    pilihPO: async function (item) {
      console.log(item);
      this.currentData.purchase_order_id = item.id;
      this.currentData.so_no_kelompok = item.so_no_kelompok;

      //search data production order item
      this.getDataFromApi(item.id, this.id);
    },

    getDataFromApi(purchaseOrderId, proId) {
      this.loading = true;
      ProductionOrderService.productionOrderItemSavedList(proId)
        .then((res) => {
          console.log("finish good");
          console.log(res.data.list_data);
          this.list_data = res.data.list_data;
          this.totalData = res.data.total;
          this.loading = false;
          this.isFirst = true;

          this.list_data.forEach((entry) => {
            entry.is_checked = false;
            entry.qty_ok = "";
            entry.qty_ng = "";
          });

          if (this.list_data.length > 0) {
            this.getDataRawFromApi(this.list_data[0].id);

            this.getDataProsesFromApi(
              this.list_data[0].id,
              this.list_data[0].qty_order,
              this.list_data[0].wf_prod_id
            );
          }
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    getDataRawFromApi(itemId) {
      this.loading = true;
      ProductionOrderService.productionOrderItemRawList(itemId)
        .then((res) => {
          this.loading = false;
          this.isFirst = true;

          res.data.list_data.forEach((entry) => {
            console.log(entry.consumption);
            this.materials_raw.push({
              komoditi_id: entry.komoditi_id,
              komoditi_nama: entry.komoditi_nama,
              qty_raw: entry.qty_raw,
              consumption: entry.consumption,
              status: entry.status_value,
              uom: entry.uom,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    openSelectedRow() {
      this.list_data.forEach((entry) => {
        entry.is_checked = false;
      });

      this.selectedRows.forEach((entry) => {
        console.log(entry);
        var result = this.list_data.filter((material) => {
          return material.id == entry.id;
        });

        if (result) result[0].is_checked = true;

        console.log(result);
      });
    },

    getDataProsesFromApi(itemId, qtyOrder, wfProdId) {
      this.loading = true;
      ProductionOrderService.productionOrderItemProsesList(itemId, wfProdId)
        .then((res) => {
          this.loading = false;
          this.isFirst = true;

          this.materials_proses = [];
          res.data.list_data.forEach((entry) => {
            this.materials_proses.push({
              seq: entry.seq,
              nama_proses: entry.nama_proses,
              proses_date: entry.proses_date,
              qty_order: qtyOrder,
              qty_ok: entry.qty_ok,
              qty_ng: entry.qty_ng,
              status: entry.status,
              status_value: entry.status_value,
              production_order_item_id: itemId,
              id: entry.id,
              prod_wf_id: entry.prod_wf_id,
            });
          });
        })
        .catch((err) => {
          this.loading = false;
          this.isFirst = true;
          console.log(err);
        });
    },

    calculateConsumptionTotal: async function () {
      for (var i = 0; i < this.materials_raw.length; i++) {
        if (
          this.materials_raw[i].qty_raw &&
          this.materials_raw[i].consumption
        ) {
          this.materials_raw[i].qty_consumption =
            this.materials_raw[i].qty_raw * this.materials_raw[i].consumption;
        }
      }
    },
  },
};
</script>

<style scoped>
.small-font {
  font-size: 12px;
}
</style>
